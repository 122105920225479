import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { bulkDeleteItems, bulkUpdateItems, fetchInspectionItems} from "services/inspection";
import { closeEditRoomInspectionItemsView, resetBlock } from "features/inspection/inspectionSlice";
import { toast } from "react-toastify";
import RoomItemsForm from "components/Inspections/RoomItemsForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  INSPECTION_ITEM_PASS_OR_FAIL_TYPE,
  INSPECTION_ITEM_PASS_OR_FAIL_TYPE_NAME,
  INSPECTION_ITEM_NUMERICAL_TYPE_NAME
} from "utils/constants";

const makeInspectionItem = (type) => ({
  id: null,
  item: '',
  itemType: type,
  itemTypeName: type == INSPECTION_ITEM_PASS_OR_FAIL_TYPE? INSPECTION_ITEM_PASS_OR_FAIL_TYPE_NAME: INSPECTION_ITEM_NUMERICAL_TYPE_NAME,
  roomType: null,
  edited: false,
})
const updateToastId = 'inspection-items-update-toast-id';
export function RoomInspectionItemsForm({setSelectedTab}) {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    bulkUpdateItems: {
      success: bulkUpdateSuccess,
      loading: bulkUpdateLoading,
      error: bulkUpdateError,
      data: updatedItems,

    },
    inEditRoomInspectionItemsView: {room: roomInspectionItemsInView},
    inspectionItems: { success, loading, error, data: inspectionItems } } = useSelector(({ inspection }) => inspection);

  const [questions, setQuestions] = useState([makeInspectionItem()]);
  const [removedQuestions, setRemovedQuestions] = useState([]);
  const [room, setRoom] = useState({});
  const [roomId, setRoomId] = useState();

  useEffect(() => {
    return () => {
      dispatch(resetBlock({ blockType: 'bulkUpdateItems' }))
      dispatch(resetBlock({ blockType: 'bulkDeleteItems' }))
    }
  }, [])

  useEffect(() => {
    setRoom(roomInspectionItemsInView)
    setRoomId(roomInspectionItemsInView.id)
  }, [roomInspectionItemsInView])

  useEffect(() => {
    if (roomId && (!success || !inspectionItems[roomId])) {
      dispatch(fetchInspectionItems({ roomId: room.id }));
    } else if (roomId && success && inspectionItems[roomId]) {
      let items = inspectionItems[roomId];
      if (items) {
        setQuestions(items.map((item) => {
          return {
            ...item,
            edited: false,
          }
        }))
      }
    }
  }, [roomId, success])

  useEffect(() => {
    if (bulkUpdateSuccess) {
      toast.success('Room succesfully updated', {
        toastId: updateToastId,
      });
    }
    
  }, [bulkUpdateSuccess])

  useEffect(() => {
    if (bulkUpdateError) {
      toast.error('Room could not be updated', {
        toastId: updateToastId,
      });
    }
  }, [bulkUpdateError])

  useEffect(() => {
    if (bulkUpdateSuccess) {
      let updates = updatedItems;
      if (removedQuestions.length > 0) {
        let removedIds = removedQuestions.map(({ id }) => id);
        updates = updates.filter(({ id }) => {
          return !removedIds.includes(id);
        });
        dispatch(bulkDeleteItems({ items: removedQuestions, roomId })).then(() => {
          setRemovedQuestions([]);
        });
      }
      setQuestions(updates.map((item) => ({ ...item, edited: false })));

    }
  }, [bulkUpdateSuccess])

  const handleAddQuestion = (type=INSPECTION_ITEM_PASS_OR_FAIL_TYPE) => {
    setQuestions([...questions, makeInspectionItem(type)]);
  }
  const handleRemoveQuestion = (idx) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const removed = questions[idx];
    setQuestions(questions.filter((_, i) => i !== idx));
    if (removed.id) {
      setRemovedQuestions([...removedQuestions, removed]);
    }
  }
  const onQuestionInputChange = (idx) => ({ target: { value } }) => {
    questions[idx].item = value;
    questions[idx].edited = true;
    setQuestions(questions.slice())
  }
  const onSaveAll = async () => {
    var roomQuestions = questions
      .filter(({ id, item, edited }) => item && (edited || !id))
      .map(({ id, item, itemType, room: itemRoomId }) => {
        return {
          room: itemRoomId ? itemRoomId : roomId,
          item,
          itemType,
          id: id
        }
      })
    await dispatch(bulkUpdateItems({ roomId, items: roomQuestions }));
  };
  const handleBackClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedTab('editor');
    dispatch(closeEditRoomInspectionItemsView())
  }
  return (
      <RoomItemsForm
        roomName={room ? room.name : ''}
        questions={questions}
        startEditingRoom={false}
        canEditRoom={false}
        isUpdatingForm={bulkUpdateLoading || loading}
        isLoadingQuestion={loading}
        handleBackClick={handleBackClick}
        handleSaveRoomClick={onSaveAll}
        handleAddQuestionClick={handleAddQuestion}
        handleRemoveQuestionClick={handleRemoveQuestion}
        handleQuestionInputChange={onQuestionInputChange}
      />
  )
}