import React from "react";
import "./ConfirmationModal.scss";
import BaseModal from "components/BaseModal";
const ConfirmationModal = ({
  show,
  message,
  onConfirm,
  onCancel,
  loading,
  title = "",
}) => {
  return (
    <BaseModal
      onConfirm={onConfirm}
      onClose={onCancel}
      show={show}
      title={title}
      formLoading={loading}
      content={
        (
          <p>
            {
              message
            }
          </p>
        )
      }
    />
  );
};

export default ConfirmationModal;
