import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import $ from "jquery";

import top from "../../assets/images/top.jpg";

import "./inviteMagic.scss";
import InputPassword from "../../components/InputPassword";
import { magicPasswordReset, verifyMagicLink } from "../../services/auth";
import { resetAuthStatus } from "../../features/auth/authSlice";
import CSRFToken from "../../utils/csrfToken";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const InviteMagicLink = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let query = useQuery();
  const [error, setError] = useState();
  const {
    magicSuccess: success,
    magicError,
    restPasswordSuccess,
    resetPasswordError,
    isAuthenticated,
    data,
  } = useSelector(({ auth }) => auth);

  useEffect(() => {
    dispatch(resetAuthStatus());
  }, []);

  useEffect(() => {
    const token = query.get("token");
    if (token) {
      dispatch(
        verifyMagicLink({
          token,
        })
      );
    } else {
      setError("Invalid or expired Login link..");
    }
  }, [query]);

  useEffect(() => {
    if (magicError) {
      setError(magicError);
    }
  }, [magicError]);

  useEffect(() => {
    if (isAuthenticated && data?.hasPassword) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const [resetDetails, setResetDetails] = useState({
    password: "",
    confirm_password: "",
    // old_password: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setResetDetails({ ...resetDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isAuthenticated && !data?.hasPassword) {
      dispatch(
        magicPasswordReset({
          ...resetDetails,
          token: query.get("token"),
          uid: data?.user.id,
        })
      );
      if (resetPasswordError) {
        setError(resetPasswordError);
      } else {
        navigate("/");
      }
    } else if (isAuthenticated) {
      navigate("/");
    }
  };

  return (
    <div className="reset-password ">
      <div className="reset-password-holder">
        <div className="banner">
          <img src={top} alt="" className="image-responsive" />
          <div className="caption-head">
            <h2>Reset Password</h2>
          </div>
        </div>
        <div className="form-holder">
          <h2>Set Password</h2>
          <div className="form-box">
            {error && <div className="alert alert-danger">{error}</div>}
            {isAuthenticated}
            <form onSubmit={handleSubmit}>
              <CSRFToken />
              <InputPassword
                title="New Password"
                onChange={handleChange}
                name="password"
                value={resetDetails.password}
              />
              <InputPassword
                title="Confirm Password"
                onChange={handleChange}
                name="confirm_password"
                value={resetDetails.confirm_password}
              />
               <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="termsAndConditions"
                required
              />
              <label className="form-check-label" htmlFor="termsAndConditions">
                I agree to <a className="tc-link" href="https://www.qcanalytics.com/terms-of-service" target="_blank">terms of service</a>  and <a className="tc-link" href="https://www.qcanalytics.com/privacy-policy" target="_blank"> privacy policy</a> 
              </label>
            </div>
              <button type="submit" className="btn btn-primary w-100">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteMagicLink;
