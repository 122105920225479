export const REPORT_OPTIONS = [
  {
    value: "districts",
    label: "Districts",
  },
  {
    value: "schools",
    label: "Buildings",
  },
  {
    value: "cleaners",
    label: "Cleaners",
  },
];

export const GRAPH_RANGE_OPTIONS = [
  { value: "Today", label: "Today" },
  { value: "Last 7 Days", label: "Last 7 Day" },
  { value: "Last 14 Days", label: "Last 14 Days" },
  { value: "Last 30 Days", label: "Last 30 Days" },
  { value: "Last 3 months", label: "Last 3 Months" },
  { value: "Last 12 months", label: "Last 12 Months" },
  { value: "Month to date", label: "Month To Date" },
  { value: "Quarter to date", label: "Quarter To Date" },
  { value: "Year to date", label: "Year To Date" },
  {value: "All time", label: "All Time",},
];


export const  NOTIFICATION={
  success:"success",
  error:"error"
}

export const ERRORS={
common:"Some thing went wrong"
}