import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";

const SidebarLayout = forwardRef(
  (
    {
      sidebar,
      mainContent,
      sidebarClassName = "",
      mainContentClassName = "",
      backButtonText = "Go Back",
      smShowDetail="",
    },
    ref
  ) => {
    const [showDetails, setShowDetails] = useState(false);
    const [inCompactView, setInCompactView] = useState(false);
    const containerRef = useRef(null);

    const handleResize = () => {
      const $items = document.querySelectorAll(
        '[data-sidebar-layout-height-item]'
      );
      let height = 0;
      if ($items) {
        for (let $item of $items) {
          var box = $item.getBoundingClientRect();
          height += box.height;
        }
        height = Math.round(height);
      }
      containerRef.current.style.height = `${Math.abs(
        window.innerHeight - height
      )}px`;
      const match = window.matchMedia("(max-width: 768px)").matches;
      setInCompactView(match);
      if (match) {
        if(smShowDetail){
          setShowDetails(true);
        }
       
      }
    };

    const handleTabChange = () => {
      if (inCompactView && showDetails) {
        setShowDetails(false);
      }
    };

    useEffect(() => {
      const debouncedResize = debounce(handleResize, 100);

      window.addEventListener("resize", debouncedResize, false);
      window.document.addEventListener("onTabChange", handleTabChange, false);

      handleResize();

      return () => {
        window.removeEventListener("resize", debouncedResize);
        window.document.removeEventListener("onTabChange", handleTabChange);
      };
    }, []);

    const toggleShowDetails = () => {
      if (inCompactView) {
        setShowDetails(!showDetails);
      }
    };

    useImperativeHandle(ref, () => ({
      toggleShowDetails: toggleShowDetails
    }));

    return (
      <div className="container-fluid">
        <div
          ref={containerRef}
          className="row position-relative sidebar-layout-container"
        >
          <div
            className={`col-md-5 col-xl-3 col-12 pt-md-4 sl-container sl-sidebar ${
              showDetails && inCompactView ? "d-none " : ""
            }${sidebarClassName}`}
          >
            {sidebar}
          </div>
          <div
            className={`col-md-7 col-xl-9 col-12 pt-md-4 sl-container sl-sidebar-content ${
              !showDetails && inCompactView ? "d-none " : ""
            }${mainContentClassName}`}
          >
            <a
              href="#"
              className="d-md-none d-block mb-3"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleShowDetails();
              }}
            >
              <i className="fa-solid fa-arrow-left me-2"></i>
              {backButtonText}
            </a>
            {mainContent}
          </div>
        </div>
      </div>
    );
  }
);

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

export default SidebarLayout;
