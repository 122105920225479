
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { forgotPassword, login, sendMagicLink } from "../../services/auth";
import logo from "assets/images/logo.png";
import styled from "styled-components";
import { resetAuthStatus } from "../../features/auth/authSlice.js";
import { getUserHomeURL } from "utils/user";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showMagicPage, setShowMagicPage] = useState(false);
  const [showForgotPasswordPage, setShowForgotPasswordPage] = useState(false);
  const [showLoginPage, setShowLoginPage] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isAuthenticated,
    data,
    loginError: error,
    loginSuccess: success,
  } = useSelector(({ auth }) => auth);

  const [redirectToURL, setRedirectToURL] = useState('');

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    setRedirectToURL(getUserHomeURL(data.user));
  }, [isAuthenticated])

  useEffect(() => {
      if (!(isAuthenticated && redirectToURL)) {
        return;
      }
      navigate(redirectToURL, {replace: true});
  }, [redirectToURL, isAuthenticated])

  useEffect(() => {
    if (error) {
      setAlertMessage(error);
      setShowAlert(true);
    } else {
      setAlertMessage("");
      setShowAlert(false);
      setSuccessMessage("");
      setShowSuccessMessage(false);
    }
  }, [error]);
  useEffect(() => {}, [showLoginPage, showMagicPage, showForgotPasswordPage]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      setAlertMessage("Please fill out all required fields");
      setShowAlert(true);
    } else {
      dispatch(login({ email, password }));
    }
  };
  const handleMagicSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setAlertMessage("Please fill out all required fields");
      setShowAlert(true);
    } else {
      dispatch(sendMagicLink({ email }));
      if (error) {
        setAlertMessage(error);
        setShowAlert(true);
      } else {
        setSuccessMessage(
          "If there is an account with the given email. A magic login link has been sent"
        );
        setShowSuccessMessage(true);
        setTimeout(() => {
          showLogin();
        }, 3000);
      }
    }
  };

  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setAlertMessage("Please fill out all required fields");
      setShowAlert(true);
    } else {
      dispatch(forgotPassword({ email }));
      if (error) {
        setAlertMessage(error);
        setShowAlert(true);
      } else {
        setSuccessMessage(
          "If there is an account with the given email. A password reset link has been sent"
        );
        setShowSuccessMessage(true);
        setTimeout(() => {
          showLogin();
        }, 3000);
      }
    }
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  const showLogin = () => {
    setShowLoginPage(true);
    setShowMagicPage(false);
    setShowForgotPasswordPage(false);
    setEmail("");
    setPassword("");
  };
  const showMagic = () => {
    setShowMagicPage(true);
    setShowLoginPage(false);
    setShowForgotPasswordPage(false);
    setEmail("");
    setPassword("");
  };
  const showReset = () => {
    setShowLoginPage(false);
    setShowMagicPage(false);
    setShowForgotPasswordPage(true);
    setEmail("");
    setPassword("");
  };

  return (
    <Container>
      <Logo src={logo} />
      {showMagicPage && (
        <Form onSubmit={handleMagicSubmit}>
          <Title>Login</Title>
          {showSuccessMessage && (
            <MessageBox>
              <p>{successMessage}</p>
            </MessageBox>
          )}
          {showAlert && (
            <AlertBox>
              <p>{alertMessage}</p>
            </AlertBox>
          )}
          <Text>
            {" "}
            Enter your email address to receive a login link direct to your
            inbox
          </Text>
          <InputContainer>
            <Label htmlFor="email">Email:</Label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </InputContainer>
          <Button type="submit">Send My Login Link</Button>
          <ForgotPasswordLink href="#" onClick={showLogin}>
            If you would prefer to use username and password, click here.
          </ForgotPasswordLink>
        </Form>
      )}
      {showLoginPage && (
        <Form onSubmit={handleLoginSubmit}>
          <Title>Login</Title>
          {showAlert && (
            <AlertBox>
              <p>{alertMessage}</p>
            </AlertBox>
          )}

          <InputContainer>
            <Label htmlFor="email">Email:</Label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </InputContainer>
          <InputContainer>
            <Label htmlFor="password">Password:</Label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <ForgotPasswordLink href="#" onClick={showReset}>
              Forgot Password?
            </ForgotPasswordLink>
          </InputContainer>
          <Button type="submit">Login</Button>
          <ForgotPasswordLink href="#" onClick={showMagic}>
            Log in using Magic Link
          </ForgotPasswordLink>
        </Form>
      )}

      {showForgotPasswordPage && (
        <>
          <Form onSubmit={handleForgotPasswordSubmit}>
            <Title>Reset Password</Title>
            {showSuccessMessage && (
              <MessageBox>
                <p>{successMessage}</p>
              </MessageBox>
            )}
            {showAlert && (
              <AlertBox>
                <p>{alertMessage}</p>
              </AlertBox>
            )}
            <Text> Enter your email address to reset your password</Text>
            <InputContainer>
              <Label htmlFor="email">Email:</Label>
              <Input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </InputContainer>
            <Button type="submit">Reset Password</Button>
            <ForgotPasswordLink href="#" onClick={showLogin}>
              Go back to login Page.
            </ForgotPasswordLink>
          </Form>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Logo = styled.img`
  width: 200px;
  margin-bottom: 40px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 400px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;
const Text = styled.p`
  margin-bottom: 10px;
  color: #1a6062;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;

const Label = styled.label`
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #f5f5f5;
`;

const Button = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #1a6062;
  color: #fff;
  cursor: pointer;
  width: 100%;
`;

const ForgotPasswordLink = styled.a`
  margin-top: 20px;
  color: #1a6062;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const AlertBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffe;
  color: red;
`;
const MessageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffe;
  color: green;
`;
export default Login;
