import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import $ from "jquery";

import top from "../../assets/images/top.jpg";

import "./verifyMagic.scss";
import InputPassword from "../../components/InputPassword";
import { verifyMagicLink } from "../../services/auth";
import { resetAuthStatus } from "../../features/auth/authSlice";
import Loader from "../../components/Loader";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const MagicLink = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let query = useQuery();

  const [error, setError] = useState();

  const { magicSuccess: success, magicError: resetPasswordError } = useSelector(
    ({ auth }) => auth
  );
  useEffect(() => {
    dispatch(resetAuthStatus());
  }, []);
  useEffect(() => {
    if (success) {
      navigate("/dashboard");
    } else {
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    }
  }, [success]);

  useEffect(() => {
    if (resetPasswordError) {
      setError(resetPasswordError);
    }
  }, [resetPasswordError]);

  useEffect(() => {
    const token = query.get("token");
    if (token) {
      dispatch(
        verifyMagicLink({
          token,
        })
      );
    } else {
      setError("Invalid or expired Login link..");
    }
  }, [query]);

  return (
    <div className="reset-password ">
      <div className="reset-password-holder">
        <div className="banner">
          <img src={top} alt="" className="image-responsive" />
          <div className="caption-head">
            <h2>Cleaning Services</h2>
          </div>
        </div>
        <div className="form-holder">
          <h2>Please Wait ...</h2>
          {error && <div className="alert alert-danger">{error}</div>}
          <Loader />
          <div className="form-box"></div>
        </div>
      </div>
    </div>
  );
};

export default MagicLink;
