import { createSlice } from "@reduxjs/toolkit";

const block = {
  loading: false,
  error: "",
  success: false,
};

const initialState = {
  districts: { ...block, data: [] },
  getDistrict: { ...block, data: null },
  updateDistrict: { ...block, data: null },
  deleteDistrict: { ...block },
  createDistrict: { ...block, data: null },
  generateCode: { ...block, data: null },
  districtPeople: { ...block, data: [] },
  // districEmployeesData : {...block, data: null}
};

const districtSlice = createSlice({
  name: "district",
  initialState,
  reducers: {
    resetBlockFlags: (state, action) => {
      const blockType = action.payload.blockType;
      state[blockType] = { ...state[blockType], ...block };
    },
    setBlockLoadingFlag: (state, action) => {
      const blockType = action.payload.blockType;
      state[blockType] = {
        ...state[blockType],
        loading: action.payload.loading,
      };
    },
    fetchDistrictSuccess: ({ districts }, action) => {
      districts.data = action.payload.sort((a, b) => a.id - b.id);
      districts.loading = false;
      districts.success = true;
    },
    fetchDistrictFailure: ({ districts }, action) => {
      districts.loading = false;
      districts.success = false;
      districts.error = action.payload;
    },
    getDistrictSuccess: ({ getDistrict }, action) => {
      getDistrict.data = action.payload;
      getDistrict.loading = false;
      getDistrict.success = true;
    },
    getDistrictFailure: ({ getDistrict }, action) => {
      getDistrict.error = action.payload;
      getDistrict.loading = false;
      getDistrict.success = false;
    },
    updateDistrictSuccess: ({ updateDistrict, districts }, action) => {
      updateDistrict.data = action.payload;
      updateDistrict.loading = false;
      updateDistrict.success = true;
      districts.data = districts.data.map((item) => {
        const updated =  action.payload;
        return item.id === updated.id ? updated : item
      });
    },
    updateDistrictFailure: ({ updateDistrict }, action) => {
      updateDistrict.error = action.payload;
      updateDistrict.loading = false;
      updateDistrict.success = false;
    },
    deleteDistrictSuccess: ({ deleteDistrict, districts }, action) => {
      deleteDistrict.loading = false;
      deleteDistrict.success = true;
      districts.loading = false;
      districts.success = true;
      districts.data = districts.data.filter((ob) => ob.id !== action.payload);
    },
    deleteDistrictFailure: ({ deleteDistrict }, action) => {
      deleteDistrict.loading = false;
      deleteDistrict.success = false;
      deleteDistrict.error = action.payload;
    },
    createDistrictSuccess: ({ createDistrict, districts }, action) => {
      createDistrict.loading = false;
      createDistrict.success = true;
      createDistrict.data = action.payload;
      districts.loading = false;
      districts.success = true;
      districts.data.push(action.payload);
    },
    createDistrictFailure: ({ createDistrict }, action) => {
      createDistrict.loading = false;
      createDistrict.success = false;
      createDistrict.error = action.payload;
    },
    generateCodeSuccess: ({ generateCode }, action) => {
      generateCode.loading = false;
      generateCode.success = true;
      generateCode.data = action.payload;
    },
    generateCodeFailure: ({ generateCode }, action) => {
      generateCode.loading = false;
      generateCode.success = false;
      generateCode.error = action.payload;
    },
    getDistrictPeopleSuccess: ({ districtPeople }, action) => {
      districtPeople.loading = false;
      districtPeople.success = true;
      districtPeople.data = action.payload;
    },
    getDistrictPeopleFailure: ({ districtPeople }, action) => {
      districtPeople.loading = false;
      districtPeople.success = false;
      districtPeople.error = action.payload;
    },
  },
});

export const {
  getDistrictSuccess,
  getDistrictFailure,
  updateDistrictSuccess,
  updateDistrictFailure,
  deleteDistrictSuccess,
  deleteDistrictFailure,
  resetBlockFlags,
  setBlockLoadingFlag,
  fetchDistrictSuccess,
  fetchDistrictFailure,
  createDistrictSuccess,
  createDistrictFailure,
  generateCodeSuccess,
  generateCodeFailure,
  getDistrictPeopleSuccess,
  getDistrictPeopleFailure,
} = districtSlice.actions;
export default districtSlice.reducer;
