import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "assets/images/logo.png";
import { logout } from "../services/auth";
import { Navbar, Nav, NavDropdown, Container, Row } from "react-bootstrap";
import { getUserHomeURL } from "utils/user";

const NavBar = () => {
  const [activeMenu, setActiveMenu] = useState("");

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
  };

  const user = useSelector(({ auth }) => auth.data?.user);

  const role = user?.role;

  const isSuperAdmin = role === "super_admin";
  const isDistrictAdmin = role === "district_admin";
  // const isInspector = role === "inspector";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [accountDropdownLabel, setAccountDropdownLabel] =
    useState("My Account");
  const [dashboardURL, setDashboardURL] = useState("/dashboard");

  useEffect(() => {
    if (!user) {
      return;
    }
    let username = user?.firstName + " " + user?.lastName;
    username = username.trim();
    if (username.length > 0) {
      setAccountDropdownLabel(username);
    }
    let url = getUserHomeURL(user);
    url = url === "/users" ? "/dashboard" : url;
    setDashboardURL(url);
  }, [user]);

  const handleProfile = () => {
    navigate("/profile");
  };
  const handleChangePassword = () => {
    navigate("/settings");
  };
  const handleLogout = async () => {
    await dispatch(logout());
    window.location="/login"
  };

  return (
    <Container fluid className="main-nav" data-sidebar-layout-height-item>
      <Row>
        <Navbar bg="light" expand="lg" className="navbar-container">
          <NavLink
            className="navbar-brand py-0"
            to={dashboardURL}
            onClick={() => handleMenuClick("dashboard")}
          >
            <img
              alt=""
              src={logo}
              height="40"
              className="d-inline-block align-top"
            />
          </NavLink>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto" style={{ width: "fit-content" }}>
              <NavLink
                to={dashboardURL}
                active={activeMenu === "dashboard" ? 1 : 0}
                onClick={() => handleMenuClick("dashboard")}
                className={`nav-link ${
                  activeMenu === "dashboard" ? "active" : ""
                }`}
              >
                Dashboard
              </NavLink>
              {(isSuperAdmin || isDistrictAdmin) && (
                <NavLink
                  to={"/users"}
                  active={activeMenu === "users" ? 1 : 0}
                  onClick={() => handleMenuClick("users")}
                  className={`nav-link ${
                    activeMenu === "users" ? "active" : ""
                  }`}
                >
                  Users
                </NavLink>
              )}
              {isSuperAdmin && (
                
                <NavLink
                  to={"/defaultRooms"}
                  active={activeMenu === "defaultRooms" ? 1 : 0}
                  onClick={() => handleMenuClick("defaultRooms")}
                  className={`nav-link ${
                    activeMenu === "defaultRooms" ? "active" : ""
                  }`}
                >
                 Room Types
                </NavLink>
              )}
              {(isSuperAdmin || isDistrictAdmin) && (
                <>
                  <NavLink
                    to={"/reports"}
                    active={activeMenu === "reports" ? 1 : 0}
                    onClick={() => handleMenuClick("reports")}
                    className={`nav-link ${
                      activeMenu === "reports" ? "active" : ""
                    }`}
                  >
                    Reports
                  </NavLink>
                  <NavLink
                    to={"/workload"}
                    active={activeMenu === "workload" ? 1 : 0}
                    onClick={() => handleMenuClick("workload")}
                    className={`nav-link ${
                      activeMenu === "workload" ? "active" : ""
                    }`}
                  >
                    Workload
                  </NavLink>
                </>
              )}

              <NavDropdown
                title={accountDropdownLabel}
                id="basic-nav-dropdown"
                active={activeMenu === "user" ? 1 : 0}
                onClick={() => handleMenuClick("user")}
                className={`nav-dropdown ${
                  activeMenu === "user" ? "active" : ""
                }`}
                style={{ marginLeft: "auto" }}
              >
                <NavDropdown.Item
                  onClick={handleProfile}
                  className="nav-dropdown-item"
                >
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={handleChangePassword}
                  className="nav-dropdown-item"
                >
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={handleLogout}
                  className="nav-dropdown-item"
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
              {isSuperAdmin && (
                <li className="last-nav-item">
                  <a
                    href="https://doc.clickup.com/8461271/p/h/826yq-1488/2542a8fabfd36d2"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-link"
                  >
                    <i className="fa-solid fa-circle-question icon"></i>
                  </a>
                </li>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Row>
    </Container>
  );
};

export default NavBar;
