import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createRoom ,fetchDefaultRoomFeatures } from "../../../services/section";
import { addCleaner } from "services/cleaner";
import BaseModal from "components/BaseModal";
import AddCleanerDropdown from "components/Dashboard/OverviewComponents/Cleaner/AddCleanerDropdown";
import { fetchDefaultRoomFeaturesSuccess, resetBlockFlags } from "features/section/sectionSlice";
import { fetchRoomTypes } from "services/inspection";
import {
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  generateUniqueId,
  sortByProperty,
  convertMinutesToSeconds,
  convertToHoursMinutesSeconds,
} from "utils/utility";
import RoomFeaturesTable from "components/RoomFeatures/RoomFeaturesTable";

const RoomForm = ({
  sectionDetails,
  school,
  district,
  onCloseModal,
  open,
}) => {
  const [actionType, setActionType] = useState("AddRoom");
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedFeature] = useState("Feature");
  const [confirmModalDetails, setConfirmModalDetails] = useState({
    title: "",
    message: "",
    id: "",
  });
  const [roomFeatureDetail, setRoomFeatureDetail] = useState({
    name: "",
    quantity: "",
    timeInMinutes: "",
  });
  const [roomFeatures, setRoomFeatures] = useState([]);
  const dispatch = useDispatch();
  const {
    createRoom: {
      success: createRoomSuccess,
      loading: createRoomLoading,
      error: createRoomError,
    },
    roomFeatureProcessing,
    roomDefaultFeatures: {
      data,
      loading: roomDefaultFeatureLoading,
    },
  } = useSelector(({ section }) => section);

  const {
    roomTypes: {
      success: roomTypesSuccess,
      loading: roomTypesLoading,
      data: roomTypes = [],
    },
  } = useSelector(({ inspection }) => inspection);

  useEffect(() => {
    if (createRoomSuccess) {
      onCloseModal();
    }
    if (createRoomSuccess || createRoomError) {
      dispatch(resetBlockFlags({ blockType: "createRoom" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createRoomSuccess, createRoomError]);

  useEffect(() => {
    if (!roomTypesSuccess) {
      dispatch(fetchRoomTypes());
    }
  }, []);

  const [roomDetail, setRoomDetail] = useState({
    name: "",
    //desks: "",
    //squareFeet: "",
    roomType: "",
    section: "",
    //fixtures: "",
    cleaner: "",
  });
  const submitHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const toggleEdit = () => setActionType("addRoom");
    if (actionType === "addFeature") {
      const id = generateUniqueId();
      const data = { id, ...roomFeatureDetail,timeInSeconds:convertMinutesToSeconds(roomFeatureDetail.timeInMinutes) };
      setRoomFeatures((prev) => [...prev, data]);
      toggleEdit();
    } else if (actionType === "editFeature") {
      const updatedRoomFeatures = roomFeatures.map((updatedFeature) => {
        if (updatedFeature.id === roomFeatureDetail.id) {
          return { ...updatedFeature, ...roomFeatureDetail };
        }
        return updatedFeature;
      });
      setRoomFeatures(updatedRoomFeatures);
      toggleEdit();
    } else {
      try {
        const obj = {
          roomDetail: {
            name: roomDetail.name,
            roomType: +roomDetail.roomType,
            section: sectionDetails.id,

            cleaner: roomDetail.cleaner,
          },
          sectionDetails: sectionDetails,
          school: school.id,
          district: district,
        };
        let cleaner = roomDetail.cleaner;
        await dispatch(createRoom({ ...obj, roomFeatures }));
        if (cleaner) {
          await dispatch(
            addCleaner({
              id: cleaner,
              school: school.id,
              district: school.district,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setRoomDetail({ ...roomDetail, [name]: value });
  };

  function handleCleanerSelect({ value }) {
    setRoomDetail({ ...roomDetail, cleaner: value });
  }
  const handleFeatureChange = ({ target: { name, value } }) => {
    setRoomFeatureDetail({ ...roomFeatureDetail, [name]: value });
  };
  const toggleConfirMModal = () => {
    setConfirmModal(!confirmModal);
  };

  const removeFeatureHandler = (feature) => {
    setConfirmModalDetails((prev) => ({
      ...prev,
      title: "Remove Room Feature",
      message: `Are You sure you want to remove this feature?`,
      id: feature.id,
    }));

    toggleConfirMModal();
  };
  const onConfirm = () => {
    try {
      const cb = () => {
        setConfirmModalDetails({ id: "", message: "", title: "" });
      };

      const filteredFeatures = roomFeatures.filter(
        (item) => item.id !== confirmModalDetails?.id
      );
      setRoomFeatures(filteredFeatures);
      cb();
      toggleConfirMModal();
    } catch (error) {
      console.error(error);
    }
  };
  const editFeatureHandler = (feature) => {
    setRoomFeatureDetail(feature);
    setActionType("editFeature");
  };
    useEffect(()=>{
    dispatch(fetchDefaultRoomFeaturesSuccess([]))
  },[])
  useEffect(() => {
    if (roomDetail?.roomType !== "") {
      dispatch(fetchDefaultRoomFeatures(roomDetail?.roomType));
    }
  }, [roomDetail?.roomType]);
  const updatedData = data.map(item => ({ ...item, isDefault: true }));
  const details=[...roomFeatures,...updatedData]
  const totalWorkload = useMemo(() => {
    const features= details || [];
    let totalSeconds = 0;
    features.forEach((feature) => {
      totalSeconds += (feature.timeInSeconds || 0) * (feature.quantity || 0);
    });
   
    return convertToHoursMinutesSeconds(totalSeconds);
  }, [details, selectedFeature]);



  return (
    <BaseModal
      onSubmit={submitHandler}
      onClose={
        actionType.includes("Feature")
          ? () => setActionType("addRoom")
          : onCloseModal
      }
      show={open}
      title={
        actionType === "addFeature"
          ? `Add Feature`
          : actionType === "editFeature"
          ? `Edit Feature`
          : "Add Room"
      }
      submitButtonText={actionType.includes("edit") ? "Update" : "Add"}
      formWrapper={true}
      formLoading={
        actionType.includes("Feature")
          ? roomFeatureProcessing
          : createRoomLoading
      }
      size={"lg"}
      content={
        <>
          <Container fluid>
            {actionType.includes("Feature") ? (
              <Row>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="name"
                      value={roomFeatureDetail?.name}
                      placeholder="Feature Name"
                      onChange={handleFeatureChange}
                    />
                    <label htmlFor="name">Feature Name</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      type="number"
                      className="form-control"
                      name="quantity"
                      value={roomFeatureDetail?.quantity}
                      placeholder="Quantity"
                      onChange={handleFeatureChange}
                      min={0}
                    />
                    <label htmlFor="quantity">Quantity</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      type="number"
                      className="form-control"
                      name="timeInMinutes"
                      value={roomFeatureDetail?.timeInMinutes}
                      placeholder="Quantity"
                      onChange={handleFeatureChange}
                    />
                    <label htmlFor="quantity">Time In Minutes</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="workloadInMinutes"
                      value={convertToHoursMinutesSeconds(
                        (roomFeatureDetail?.quantity || 0) *
                          (roomFeatureDetail?.timeInMinutes*60)
                      )}
                      placeholder="Workload"
                      disabled
                    />
                    <label htmlFor="workloadInMinutes">Workload</label>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="name"
                      value={roomDetail?.name}
                      placeholder="Room Name"
                      onChange={handleChange}
                    />
                    <label htmlFor="name">Room Name</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <select
                      required
                      disabled={roomTypesLoading}
                      className="form-select"
                      name="roomType"
                      value={roomDetail?.roomType}
                      onChange={handleChange}
                    >
                      <option value="">Select room type</option>
                      {sortByProperty(roomTypes, "name")?.map((item) => (
                        <option key={item.id} id={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="name">Room Type</label>
                  </div>
                </Col>
                <Col md={6}>
                  <AddCleanerDropdown
                    onChange={handleCleanerSelect}
                    school={school}
                  />
                </Col>
                <Col md={6}>
                
                    <div className="form-floating mb-4 go-bottom">
                      <input
                        required
                        type="text"
                        className="form-control"
                        name="totalWorkload"
                        value={totalWorkload}
                        placeholder="Workload"
                        disabled
                      />
                      <label htmlFor="totalWorkload">Total Workload</label>
                    </div>
                  
                </Col>
                <Col
                  md={12}
                  className="d-flex flex-wrap align-items-center justify-content-between flex-grow-1"
                >
                  <h5> {selectedFeature}s </h5>
                  <Button
                    variant="light"
                    size="sm"
                    className="btn-unset-height"
                    onClick={() => {
                      setActionType("addFeature");
                      setRoomFeatureDetail({
                        name: "",
                        quantity: "",
                        timeInMinutes: "",
                      });
                    }}
                  >
                    Add {selectedFeature}
                  </Button>
                </Col>
                <Col md={12} className="mt-2">
                  <RoomFeaturesTable
                  loading={roomDefaultFeatureLoading}
                    data={
                      
                      details
                    }
                    removeFeatureHandler={removeFeatureHandler}
                    editFeatureHandler={editFeatureHandler}
                  />
                </Col>
              </Row>
            )}
          </Container>
          <ConfirmationModal
            show={confirmModal}
            onCancel={toggleConfirMModal}
            title={confirmModalDetails?.title}
            message={confirmModalDetails?.message}
            onConfirm={onConfirm}
            loading={roomFeatureProcessing}
          />
        </>
      }
    />
  );
};
export default RoomForm;
