import "react-responsive-modal/styles.css";
import React, { useState } from "react";
import EmptyImage from "assets/images/logo.png";

export default function CardContainer(props) {
  const {
      imageSource,
      cardAttrs = {},
      onClick,
      children } = props;

  const [imageFailed, setImageFailed] = useState(false);
  return (
    <div
      {...cardAttrs}
      className={`cursor-pointer qck-card  d-flex align-items-center flex-column w-100`}
      role="button"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        onClick(e);
      }} 
      >
      <div className="qck-card-card-image-container d-flex align-items-center justify-content-center">
        <img
          className={`img-fluid mh-100 qck-card-image ${(!imageSource || imageFailed) ? 'greyout p-4' : ''}`}
          src={imageSource ? imageSource : EmptyImage}
          alt="Thumbnail"
          onError={({ currentTarget }) => {
            if (!imageFailed) {
              currentTarget.onerror = null;
              currentTarget.src = EmptyImage;
              setImageFailed(true);
            }
          }}
        />
      </div>
      {children}
    </div>
  );
}
