import React, { useEffect, useState } from "react";

import BaseModal from "components/BaseModal";

const MAPModal = ({
  open,
  onClose,
  defaultPreviewImage,
  onSubmit,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [fileImage, setFileImage] = useState();
  const [previewImage, setPreviewImage] = useState("");
  const isEdit=defaultPreviewImage?.id;

  useEffect(() => {
    setPreviewImage(defaultPreviewImage?.image||"")
    return () => {
      setPreviewImage("");
    }
  }, []);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (e) => {
    e.preventDefault();
    setFileImage(e.target.files[0]);
    let reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!previewImage) {
      return setErrorMessage("Please Select File.");
    }
    if (!fileImage) {
      return setErrorMessage("Please Update File.");
    }
    setErrorMessage("");

    const base64 = await toBase64(fileImage);
    onSubmit(base64,isEdit)
    onClose();
  };

  return (
    <BaseModal
      onSubmit={handleSubmit}
      onClose={() => onClose(null, "outter")}
      show={open}
      title={`${isEdit?'Update':"Upload"} a Map`}
      submitButtonText={`${isEdit?'Update':"Upload"}`}
      formWrapper={true}
      content={
        <>
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
          <div className="mb-4">
            {previewImage && (
              <img className="upload-image mx-auto d-table" src={previewImage} alt="" />
            )}
            <div className="form-floating mb-3 go-bottom"></div>
          </div>
          <div className="logo-uploader mb-4">
            <div className="logo-uploader-header d-flex align-items-center justify-content-between mb-3">
              <input
                type="file"
                onChange={handleFileChange}
                id="select-file"
                className="btn btn-outline-secondary"
                style={{ display: "none" }}
              />
              <span className="text-uppercase">image file</span>
              <div className="d-flex justify-contet-center align-items-center">
                {previewImage && <span className="mr-3">{previewImage.name}</span>}
                <label
                  htmlFor="select-file"
                  className="btn btn-outline-primary btn-small d-flex align-items-center text-uppercase shadow-none m-0"
                >
                  Select file
                </label>
              </div>
            </div>
          </div>
        </>
      }
    />
  );
};

export default MAPModal;
