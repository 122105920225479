import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import SchoolForm from "components/Dashboard/SchoolForm";
import BuildingCard from "components/Dashboard/OverviewComponents/BuildingCard";
import NavBar from "../../components/MainNavbar";

import { getUserDetails } from "services/auth";

import "pages/Overview/overview.scss";
import "pages/Dashboard/dashboard.scss";
import "pages/Dashboard/margin_and_padding_helpers.scss";
import { fetchSchools } from "services/school";
import InnerLoading from "components/InnerLoading";

function DistrictSchools(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();


  const { loginSuccess: userSuccess, data: userData } = useSelector(
    ({ auth }) => auth
  );

  const {
    schools: { data: schools, success: schoolsSuccess, loading: schoolsLoading },
    createSchool: {loading: createSchoolLoading, error:createSchoolError}
  } = useSelector(({ school }) => school);

  let role = userData?.user?.role;

  const isSuperAdmin = role === "super_admin";
  const isDistrictAdmin = role === "district_admin";
  const isInspector = role === "inspector";

  const districtId = params.districtId ? params.districtId : location?.state?.districtId;
  const [openAddSchoolModal, setOpenAddSchoolModal] = useState(false);

  useEffect(() => {
    if (!userSuccess) {
      dispatch(getUserDetails());
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userSuccess) {
      dispatch(fetchSchools(districtId));
    }
  }, [userSuccess,dispatch,districtId]);

  const handleClick = (school) => (e) => {
    navigate(`/schools/${school.id}`, {
      state: {
        schoolId: school.id,
      },
    });
  };

  const onCloseModal = () => setOpenAddSchoolModal(false);

  return (
    <div className='page-content'>
      <NavBar />
      <nav className="navbar navbar-light justify-content-between navbar-container">
        <div className="container-fluid">
          <div className="ms-auto">
            <button
              className="btn btn-outline-secondary d-flex align-items-center shadow-none"
              onClick={setOpenAddSchoolModal}
            >
              <i className="fa-solid fa-plus me-1"></i>
              Building
            </button>
          </div>
        </div>
      </nav>
      <div className="position-relative">
        <InnerLoading show={schoolsLoading} />
        {schools.length === 0 && !schoolsLoading && (
          <p className="d-flex align-items-center justify-content-center p4 mt-5">No buildings to show</p>
        )}
        {schoolsSuccess && schools.length > 0 && (
          <>

            <div className="dashboard overview">
              <div className="container-xxl container-fluid">
                <div className="row mb-4 mt--4">
                  {schools.map((school) => (
                    <div
                      key={`districtSchool${school.id}`}
                      className="col-lg-3 col-md-4 col-12 mt-4 d-flex justify-content-center justify-content-md-start">
                      <BuildingCard
                        id={school.id}
                        image={school.image}
                        name={school.name}
                        sectionno={school.totalSections}
                        roomno={school.totalRooms}
                        contact_email={school.contactEmail}
                        handleClick={handleClick(school)}
                        district={districtId}
                        isInspector={isInspector}
                        isDistrictAdmin={isDistrictAdmin}
                        isSuperAdmin={isSuperAdmin}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {openAddSchoolModal && (
        <SchoolForm
          district={districtId}
          open={openAddSchoolModal}
          onClose={onCloseModal}
          loading={createSchoolLoading}
          error={createSchoolError}
        />
      )}
   
    </div>
  );
}
export default DistrictSchools;
