import "react-responsive-modal/styles.css";

import React, {useState } from "react";
import { useDispatch,useSelector } from "react-redux";

import { deleteSchool } from "../../../services/school";
import DeleteModal from "./DeleteModal";
import SchoolEdit from "./SchoolEditForm";
import CardContainer from "components/CardContainer";
import ActionDropdown from "components/ActionDropdown";

export default function BuildingCard({
  id,
  district,
  image,
  handleClick,
  name,
  sectionno,
  roomno,
  contact_email,
  isDistrictAdmin,
  isSuperAdmin,
}) {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const {loading} =useSelector((state)=>state?.school?.deleteSchool);

  const dispatch = useDispatch();
  const onOpenEditModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenEditModal(true);
  };
  const onCloseEditModal = () => setOpenEditModal(false);

  const onOpenDeleteModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenDeleteModal(true);
  };

  const onCloseDeleteModal = () => setOpenDeleteModal(false);

  async function HandelDeleteSchool() {
    const obj = {
      id,
      district,
    };
    try {
       dispatch(deleteSchool(obj,setOpenDeleteModal));
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <CardContainer
        imageSource={image}
        onClick={handleClick}
        cardAttrs={{ title: "View School details" }}
      >
        <div className="align-items-center qck-card-footer d-flex flex-wrap w-100 py-2 px-2 position-relative">
          <b className="mb-0 w-100 text-truncate align-items-center d-inline-block pe-4">
            {name}
          </b>
          <div>
            <small>{sectionno} Sections</small>
            <small className="mx-2">|</small>
            <small>{roomno} Rooms</small>
          </div>
          {(isSuperAdmin || isDistrictAdmin) && (
            <div className="position-absolute top-0 end-0">
              <ActionDropdown
                handlers={{
                  "Edit Details": onOpenEditModal,
                  "Delete Building": onOpenDeleteModal,
                }}
              />
            </div>
          )}
        </div>
      </CardContainer>

      {openEditModal && (
        <SchoolEdit
          district={district}
          id={id}
          contact_email={contact_email}
          name={name}
          image={image}
          open={openEditModal}
          onOpenModal={onOpenEditModal}
          onCloseModal={onCloseEditModal}
          setOpenEditModal={setOpenEditModal}
        />
      )}
      {openDeleteModal && (
        <div>
          <DeleteModal
            open={openDeleteModal}
            onOpenModal={onOpenDeleteModal}
            onCloseModal={onCloseDeleteModal}
            onDelete={HandelDeleteSchool}
            message="Building"
            loading={loading}
          />
        </div>
      )}
      {/* {openCleaningModal && (
        <CleaningDetailsRoomTypeForm
          edit={true}
          open={openCleaningModal}
          onCloseModal={() => setOpenCleaningModal(false)}
          schoolId={id}
        />
      )} */}
    </>
  );
}
