import React, { Fragment, useEffect, useState } from "react";
import rightArrow from "../../../Dashboard/assets/arrow-right.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchSchoolCleaners } from "services/cleaner";
import { highlight } from "utils/utility";

const RoomsSelection = ({
  schoolId,
  search = '',
  rooms,
  selectedRoom,
  handleRoomClick,
  RightSideComponent = null,
}) => {

  const dispatch = useDispatch();
  const {
    schoolCleaners: { data: cleanersData, success: cleanerSuccess, loading: cleanerLoading },
  } = useSelector(({ cleaner }) => cleaner);

  const [schoolCleanerNames, setSchoolCleanerNames] = useState({});

  useEffect(() => {
    if (!cleanerSuccess) {
      dispatch(fetchSchoolCleaners({ schoolId }));
    }
  }, [])
  useEffect(() => {
    if (cleanerSuccess) {
      setSchoolCleanerNames(cleanersData.reduce((names, cleaner) => {
        names[cleaner.id] = cleaner.name;
        return names;
      }, {}))
    }
  }, [cleanerSuccess])

  let sectionRooms = rooms.reduce((sections, room) => {
    const section = room.sectionName;
    if (!sections[section]) {
      sections[section] = [];
    }
    sections[section].push(room);
    return sections;
  }, {});

  function RoomComponent({ room }) {
    return (
      <div
        className={`section-list-item ${selectedRoom?.id === room.id ? "active-item" : ""}`}
        onClick={handleRoomClick(room)}
      >
        <div className="section-header">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div>
              <span className="section-list-subtitle d-block mb-1">
                <span dangerouslySetInnerHTML={{ __html: highlight(room.name, search)}}></span>
              </span>
              {room.cleaner && schoolCleanerNames[room.cleaner] !== undefined && (
                <span className="pe-5 section-list-subtitle-description">
                  Cleaned by <span dangerouslySetInnerHTML={{ __html:highlight(schoolCleanerNames[room.cleaner], search)}}></span> 
                </span>
              )}
            </div>
            {RightSideComponent && (<RightSideComponent room={room} />)}
          </div>
        </div>
      </div>
    )
  };
  return (
    <div className="section-list-holder-without-overflow">
      <div className="accordion accordion-flush" id={`sectionsAccordion`}>
        {Object.entries(sectionRooms).map(([sectionName, rooms], i) => {
          const collapseId = `sectionCollapse${i}${sectionName}`;
          return (
            <div key={`${collapseId}Container`} className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${collapseId}`} aria-expanded="true" aria-controls={`${collapseId}`}>
                  {sectionName}
                </button>
              </h2>
              <div id={`${collapseId}`} className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#sectionsAccordion">
                <div className="accordion-body px-0">
                  {rooms.map((room) => <RoomComponent
                    key={`sectionRoom${room.id}`}
                    room={room} />)}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default RoomsSelection;
