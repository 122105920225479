import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { createSection, editSection } from "../../services/section";

import BaseModal from "components/BaseModal";

const SectionForm = ({ sectionDetails, school, district, onCloseModal, open }) => {
  const dispatch = useDispatch();
  const {
    createSection: {loading: createSectionLoading},
    editSection: {loading: editSectionLoading},
  } = useSelector(({section}) => section);

  useEffect(() => {
    if (sectionDetails) {
      setSectionDetail({
        name: sectionDetails?.name,
        contact_name: sectionDetails?.contactName,
        contact_email: sectionDetails?.contactEmail,
      });
    }
  }, [sectionDetails]);

  const [sectionDetail, setSectionDetail] = useState({
    name: "",
    contact_name: "",
    contact_email: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const resetHandler = () => {
    setSectionDetail({ name: "", contact_email: "", contact_name: "" });
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (createSectionLoading) {
      return;
    }

    if (!school) {
      return setErrorMessage(`Please select school first.`);
    }
    setErrorMessage("");
    try {
      await dispatch(
        createSection({
          name: sectionDetail?.name,
          contact_name: sectionDetail?.contact_name,
          contact_email: sectionDetail?.contact_email,
          school: school.id,
          district: district,
        })
      );
      resetHandler();
      onCloseModal();
      toast.success("Section successfully created");
    } catch {
      toast.error("Section could not be created.");
    }
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (editSectionLoading) {return;}
    try {
      await dispatch(
        editSection({
          id: sectionDetails.id,
          name: sectionDetail.name,
          contact_name: sectionDetail?.contact_name,
          contact_email: sectionDetail?.contact_email,
          school: school.id,
        })
      );
      resetHandler();
      onCloseModal();
      toast.success("Section successfully updated");
    } catch {
      toast.success("Section could not be updated");
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setSectionDetail({ ...sectionDetail, [name]: value });
  };

  return (
    <BaseModal
      onSubmit={sectionDetails ? updateHandler : submitHandler}
      onClose={onCloseModal}
      formLoading={editSectionLoading || createSectionLoading}
      show={open}
      title={sectionDetails ? "Update Section Details" : "Add Section"}
      submitButtonText={sectionDetails ? "Update" : "Create"}
      formWrapper={true}
      content={
        <>
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <div className="mb-4">
            <div className="form-floating mb-3 go-bottom">
              <input
                type="text"
                className="form-control"
                name="name"
                value={sectionDetail.name}
                onChange={handleChange}
                required
                id="floatingInput"
                placeholder="School Name"
              />
              <label htmlFor="name">Section Name</label>
            </div>
          </div>
          <div className="mb-4">
            <div className="form-floating mb-3 go-bottom">
              <input
                type="text"
                className="form-control"
                name="contact_name"
                value={sectionDetail.contact_name}
                onChange={handleChange}
                id="contact_name"
                placeholder="Contact Name"
              />
              <label htmlFor="contact_name">Contact Name</label>
            </div>
          </div>
          <div className="mb-4">
            <div className="form-floating mb-3 go-bottom">
              <input
                type="email"
                className="form-control"
                name="contact_email"
                value={sectionDetail.contact_email}
                onChange={handleChange}
                id="contact_email"
                placeholder="Contact Email"
              />
              <label htmlFor="contact_email">Contact Email</label>
            </div>
          </div>
        </>
      }
    />
  );
};

export default SectionForm;
