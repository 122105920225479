import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Image } from "react-bootstrap";
import { createSchool } from "../../services/school";
import styled from "styled-components";
import BaseModal from "components/BaseModal";
import { checkValidEmail, toastMsg } from "utils/utility";
import { NOTIFICATION } from "constants/constants";

const SchoolForm = ({
  error,
  district, //districtId
  open,
  onClose,
  loading = false,
}) => {
  const dispatch = useDispatch();

  const [buildingDetail, setBuildingDetail] = useState({
    name: "",
    logo: "",
    contactEmail: "",
  });
  const [base64Image, setBase64Image] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setBase64Image(base64);
    setBuildingDetail({ ...buildingDetail, logo: base64 });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowErrorMessage(false);

    try {
      if (!buildingDetail.name || buildingDetail.name === "") {
        toastMsg("Please enter a name for the building", NOTIFICATION.error);
      } else if (
        buildingDetail?.contactEmail !== "" &&
        !checkValidEmail(buildingDetail?.contactEmail)
      ) {
        toastMsg("Please enter a valid contact email", NOTIFICATION.error);
      } else {
        const successCB = () => {
          setBuildingDetail({ name: "", logo: "", contactEmail: "" });
          setBase64Image("");
          onClose();
        };
        dispatch(
          createSchool(
            {
              name: buildingDetail?.name,
              contactEmail:
                buildingDetail?.contactEmail !== ""
                  ? buildingDetail?.contactEmail
                  : null,
              image: buildingDetail?.logo,
              district: district,
            },
            successCB
          )
        );
        setShowErrorMessage(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = async ({ target: { name, value, files } }) => {
    if (name === "logo") {
      const file = files[0];
      const base64 = await convertToBase64(file);
      setBuildingDetail({ ...buildingDetail, [name]: base64 });
    } else {
      setBuildingDetail({ ...buildingDetail, [name]: value });
    }
  };

  return (
    <BaseModal
      onSubmit={submitHandler}
      onClose={onClose}
      show={open}
      title="Add Building"
      formWrapper={true}
      formLoading={loading}
      content={
        <>
          {showErrorMessage && error && (
            <MessageBox>
              <p>{error}</p>
            </MessageBox>
          )}
          <div className="mb-4">
            <div className="form-floating mb-3 go-bottom">
              <input
                type="text"
                className="form-control"
                name="name"
                value={buildingDetail.name}
                onChange={handleChange}
                autoFocus={false}
                id="floatingInput"
                placeholder="Building Name"
              />
              <label htmlFor="name">Building Name</label>
            </div>
            <div className="form-floating mb-3 go-bottom">
              <input
                type="email"
                className="form-control"
                name="contactEmail"
                autoFocus={false}
                value={buildingDetail.contactEmail}
                onChange={handleChange}
                id="floatingInput"
                placeholder="School Name"
              />
              <label htmlFor="name">Contact Email</label>
            </div>
          </div>
          <div className="logo-uploader mb-4">
            <div className="logo-uploader-header d-flex align-items-center justify-content-between mb-3">
              <div>Building Image</div>
              <input
                type="file"
                onChange={handleImageChange}
                id="select-image"
                className="btn btn-outline-secondary"
                style={{ display: "none" }}
              />
              <label
                htmlFor="select-image"
                className="btn btn-outline-secondary btn-lg d-flex align-items-center text-uppercase"
              >
                Select image
              </label>
            </div>
            <div className="logo-uploader-area">
              {base64Image === "" ? (
                <p> Building Image</p>
              ) : (
                <Image src={base64Image} style={{ maxHeight: "100px" }} fluid />
              )}
            </div>
          </div>
        </>
      }
    />
  );
};
const MessageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffe;
  color: red;
  margin: 10px;
`;
export default SchoolForm;
