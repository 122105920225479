import React, { useEffect, useState } from "react";
import { FileText } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate ,useSearchParams} from 'react-router-dom';
import { toast } from 'react-toastify';

import { 
  createInspection,
  updateInspection,
  deleteItemResultFiles,
  fetchInspectionItems,
  fetchItemResultFiles,
  fetchItemResults,
  saveInspection,
  fetchInspection
} from "services/inspection";

import InnerLoading from "components/InnerLoading";
import FailBlock from "./FailBlock";
import { resetBlockFlags  } from "features/inspection/inspectionSlice";
import { convertToBase64, handleDownloadFile, isImage} from "utils/utility";
import _ from "lodash";
import { INSPECTION_ITEM_PASS_OR_FAIL_TYPE, INSPECTION_ITEM_NUMERICAL_TYPE } from "utils/constants";

function InspectionForm({ 
  selectedRoom,
  roomInspection,  
  setInspectionScore, 
  canSaveInspection,
  setRoomListLoading,
  onOpenDeleteInspection,
 }) {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    currentInspection: {data: currentInspection, success: fetchInspectionSuccess, error: fetchInspectionError},
    updateItemResults: { data: updatedItemResults, success: updatedItemResultsSuccess, error: updatedItemResultsError, loading: updatedItemResultsLoading },
    updateItemResultFiles: { data: updatedItemResultFiles, success: updatedItemResultFilesSuccess, error: updatedItemResultFilesError, loading: updateItemResultFilesLoading },
    deleteItemResultFiles: {success: deleteItemResultFilesSuccess, loading: deleteItemResultFilesLoading},
    itemResultFiles: { data: itemResultFilesData, success: itemResultFilesSuccess, error: itemResultFilesError },
    itemResults: { data: itemResultsData, success: itemResultsSuccess, error: itemResultsError, loading: itemsResultsLoading },
    deleteInspection: { loading: deleteInspectionLoading, success: deleteInspectionSuccess},
    inspectionItems: { data: inspectionItemsData, loading: inspectionItemsLoading, success: inspectionsItemSuccess, error: inspectionsItemSuccessError },
  } = useSelector(({ inspection }) => inspection);
  const user = useSelector(({ auth }) => auth.data?.user);
  const isSuperAdmin = user?.role === "super_admin";
  const isDistrictAdmin = user?.role === "district_admin";


  const [questions, setQuestions] = useState([]);
  const [scoreText, setScoreText] = useState("");
  const [roomId, setRoomId] = useState();
  const [inspectionId, setInspectionId] = useState();
  const [inspectionNote, setInspectionNote] = useState("")
  const [inspectionFiles, setInspectionFiles] = useState([]);
  const [questionFilesTable, setQuestionFilesTable] = useState({});
  const [questionResultsTable, setQuestionResultsTable] = useState({});
  const [questionsLoading, setQuestionsLoading] = useState(false)
  const [removedFiles, setRemovedFiles] = useState([]);
  useEffect(() => {
    if (!itemResultFilesSuccess) {
      dispatch(fetchItemResultFiles());
    }
    return () => {
      dispatch(resetBlockFlags({ blockType: 'updateItemResults' }));
      dispatch(resetBlockFlags({ blockType: 'updateItemResultFiles'}));
      dispatch(resetBlockFlags({ blockType: 'updateInspection'}));
    }
  }, [])

  //Set room and inspection ids
  useEffect(() => {
    let roomId = selectedRoom ? selectedRoom.id : null;
    let inspectionId = roomInspection ? roomInspection.id : null;
    setRoomId(roomId)
    setInspectionId(inspectionId)
  }, [selectedRoom, roomInspection])

  //Fetch items results
  useEffect(() => {
    if (inspectionId && (!itemResultsSuccess || itemResultsData[inspectionId] === undefined)) {
      setQuestionsLoading(true);
      dispatch(fetchItemResults({ inspectionId }));
    }
    if (inspectionId) {
      dispatch(fetchInspection({inspectionId}))
    }
  }, [inspectionId])
  useEffect(() => {
    if (!_.isEmpty(currentInspection) && inspectionId) {
      setInspectionNote(currentInspection.note)
      setInspectionFiles(currentInspection.inspectionFiles)
    }
  }, [currentInspection, inspectionId])
  useEffect(() => {
    if (roomId && (!inspectionsItemSuccess || inspectionItemsData[roomId] === undefined)) {
      setQuestionsLoading(true);
      dispatch(fetchInspectionItems({ roomId }));
    }
  }, [roomId])

  useEffect(() => {
    if ((!inspectionId || itemResultsSuccess) && inspectionsItemSuccess) {
      setQuestionsLoading(false); 
    }
  }, [itemResultsSuccess, inspectionsItemSuccess])

  //Construct item results table
  useEffect(() => {
    if ((itemResultsSuccess || updatedItemResultsSuccess || deleteInspectionSuccess )
    && inspectionsItemSuccess 
    && inspectionId 
    && roomId 
    && inspectionItemsData[roomId]) {
      const itemResults = itemResultsData[inspectionId];
      if (!itemResults || itemResults.length === 0) {
        setQuestionResultsTable({})
        return;
      }
      let newTable = inspectionItemsData[roomId].reduce((table, item) => {
        var result = itemResults.find(({ inspectionItem,roomId}) => {
          return inspectionItem.trim().toLowerCase() === item.item.trim().toLowerCase()&&roomId===item?.room;
        });
        if (!result) {
          table[item.id] = {
            inspectionItem: item.item,
            inspectionItemType: item.itemType,
            numericValue: null,
            ...(item.itemType == INSPECTION_ITEM_NUMERICAL_TYPE && {result: 1})
          }
        } else {
          table[item.id] = { ...result, edited: false };
        }
        
        return table;
      }, {});
      setQuestionResultsTable(newTable);
    }
    if (!inspectionId && inspectionItemsData[roomId]) {
      let newTable = inspectionItemsData[roomId].reduce((table, item) => {
        table[item.id] = {
          inspectionItem: item.item,
          inspectionItemType: item.itemType,
          numericValue: null,
          ...(item.itemType == INSPECTION_ITEM_NUMERICAL_TYPE && {result: 1})
        }
        return table;
      }, {});
      setQuestionResultsTable(newTable);
    }
  }, [itemResultsSuccess,
    updatedItemResultsSuccess,
    inspectionsItemSuccess,
    deleteInspectionSuccess,
 
    inspectionId,
    roomId])

  //Construct item result files table
  useEffect(() => {
    if ((
    itemResultFilesSuccess  
    || updatedItemResultFilesSuccess
    || deleteItemResultFilesSuccess)
      && itemResultsSuccess
      && inspectionsItemSuccess
      && roomId && inspectionId
      && inspectionItemsData[roomId]
      && itemResultsData[inspectionId]) {

      const inspectionResults = itemResultsData[inspectionId];
      setQuestionFilesTable(itemResultFilesData.reduce((table, resultFile) => {
        let itemResultId = resultFile.itemResult;
        let itemResult = inspectionResults.find(({ id }) => (id === itemResultId))
        if (!itemResult) {
          return table;
        }
        let inspectionItem = inspectionItemsData[roomId].find(({ item }) => itemResult.inspectionItem === item)
        if (!inspectionItem) {
          return table;
        }
        let inspectionItemId = inspectionItem.id
        if (!table[inspectionItemId]) {
          table[inspectionItemId] = [];
        }
        table[inspectionItemId].push({ ...resultFile, edited: false });
        return table;
      }, {}))
    }
  }, [
    itemResultFilesSuccess,
    updatedItemResultFilesSuccess,
    itemResultsSuccess,
    inspectionsItemSuccess,
    deleteInspectionSuccess,
    roomId,
    inspectionId,
  ])


  //Construct questions list
  useEffect(() => {
    if (inspectionsItemSuccess && roomId) {
      let roomInspectionItems = inspectionItemsData[roomId];
      if (!roomInspectionItems) {
        setQuestions([])
      } else {
        setQuestions([...roomInspectionItems]);
      }
    }
  }, [inspectionsItemSuccess, roomId])

  useEffect(() => {
    if (updatedItemResultsSuccess) {
      toast.success('Inspection saved successfully');
    }
  }, [updatedItemResultsSuccess])

  useEffect(() => {
    if (updatedItemResultsError) {
      toast.error('Inspection could not be saved');
    }
  }, [updatedItemResultsError])

  /*
  useEffect(() => {
    const questionsOfType = inspectionQuestions.filter((question) => {
      return question.roomType === selectedRoom.roomType;
    });
    if (results.length) {
      const newQuestions = questionsOfType.map((q) => {
        const result = results.find((r) => r.parameter === q.id);
        return {
          ...q,
          status: result.status,
          note: result.note,
          filesOfParameterResult: result.filesOfParameterResult.map(
            (i) => i.file
          ),
        };
      });
      return setQuestions(newQuestions);
    }
    setQuestions(
      questionsOfType.map((que) => ({
        ...que,
        status: "",
        note: "",
        filesOfParameterResult: [],
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoom.id]);

    */
  useEffect(() => {
    // let totalItems = questions.length;
    let score = questions.filter(question => question.itemType == INSPECTION_ITEM_PASS_OR_FAIL_TYPE).reduce((score, question) => {
      let itemResult = getQuestionResult(question.id);
      const result = itemResult?.result=== 1? itemResult.result:0;
      return score + result;
    }, 0);
    let totalItems = questions.filter(question => question.itemType == INSPECTION_ITEM_PASS_OR_FAIL_TYPE).reduce((totalQuestions, question) => {
      let itemResult = getQuestionResult(question.id);
      let result;
      if(canSaveInspection){
        result=itemResult?.result!==3?1:0;

      }
      else {
        result= typeof itemResult?.result!=="undefined"&& itemResult?.result!==3?1:0
      }
      return totalQuestions + result;
    }, 0);
    setInspectionScore({score, totalItems})
    const scoreText = `${score}/${totalItems}`;
    setScoreText(scoreText)


    /*
    Promise.resolve(dispatch(startBlockFlags({ blockType: 'updateInspection' }))).then(() => {
      let score = questions.reduce((score, question) => {
        let itemResult = getQuestionResult(question.id);
        const result = !itemResult ? 0 : itemResult.result;
        return score + result;
      }, 0);
      let totalItems = questions.length;
      dispatch(updateInspectionSuccess({
        ...roomInspection,
        score,
        totalItems,
      }));
    })
    */
  }, [questions, questionResultsTable, roomInspection])
  const handleChangeNumberInput = (id) => ({ target: { name, value } }) => {
      if (name.startsWith('passFail-')) {
        let itemResult = questionResultsTable[id];
        if (itemResult) {
          itemResult.numericValue = value;
        } 
        let newTable = {
          ...questionResultsTable
        };
        itemResult.edited = true;
        newTable[id] = itemResult;
        setQuestionResultsTable(newTable)
    }
  }
  const handleChange = (id) => ({ target: { name, value, type } }) => {
    if (name.startsWith('passFail-')) {
      let resultValue = parseInt(value);
      let itemResult = questionResultsTable[id];
      if (itemResult) {
        itemResult.result = resultValue;
      } else {
        itemResult = {
          result: resultValue,
        }
      }
      if (type == "checkbox") {
        if (resultValue == 3) {
          itemResult.result = 1
        } else {
          itemResult.result = 3
        }
      }
      let newTable = {
        ...questionResultsTable
      };
      itemResult.edited = true;
      newTable[id] = itemResult;
      setQuestionResultsTable(newTable)
    } else {
      let itemResult = questionResultsTable ? questionResultsTable[id] : undefined;
      if (!itemResult) {
        itemResult = { [name]: value };
      } else {
        itemResult[name] = value;
      }
      itemResult.edited = true;
      setQuestionResultsTable({
        ...questionResultsTable,
        [id]: itemResult,
      });
    }
  };

  const handleRemoveFile = (questionId, index) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    let files = getQuestionFiles(questionId);
    let removed = files[index];
    if (removed.id) {
      setRemovedFiles([
        ...removedFiles,
        removed,
      ]);
    }
    let newFiles = [...files];
    newFiles.splice(index, 1)
    setQuestionFilesTable({
      ...questionFilesTable,
      [questionId]: newFiles,
    })
  }
  useEffect(() => {
    if (deleteItemResultFilesSuccess) {
      setRemovedFiles([]);
    }
  }, [deleteItemResultFilesSuccess])

  const handleFileChange = (id) => async ({ target }) => {
    const base64FilesWithNames = [];
    const {files} = target
    for (let file of files) {
      const base64 = await convertToBase64(file);
      base64FilesWithNames.push({
        "name": file.name,
        "file": base64,
      });
    }
    addQuestionFiles(id, base64FilesWithNames);
  };
  const handleRemoveInspectionFiles = (index) => {
    let newFiles = [...inspectionFiles]
    newFiles.splice(index, 1)
    setInspectionFiles([...newFiles])
  }

  const handleInspectionFileChange = async (e) => {
    const base64FilesWithNames = [];
    const {files} = e.target
    
    for (let file of files) {
      const base64 = await convertToBase64(file);
      base64FilesWithNames.push({
        "fileName": file.name,
        "file": base64,
      });
    }
    setInspectionFiles([...inspectionFiles, ...base64FilesWithNames]);
  }

  const handleSaveResultClick = async () => {
    let errorMsg = "";
    questions.forEach((que) => {
      let itemResult = getQuestionResult(que.id);
      if (![0, 1,3].includes(itemResult?.result)) {
        errorMsg = "Please provide all inspection results.";
      }
    });
    if (errorMsg) {
      return toast.error(errorMsg);
    }
    setRoomListLoading(true);
    let inspectionId = roomInspection ? roomInspection.id : undefined;
    if (!inspectionId) {
      const inspection = await dispatch(createInspection({roomId}));
      inspectionId = inspection.id;
    } else {
     const payload = {
        id: inspectionId,
        note: inspectionNote,
        inspection_files: inspectionFiles
      }
      dispatch(updateInspection({inspection: payload}));
    }
    
    let roomTypeId = selectedRoom.roomType;
    let items = [];

    questions.forEach((question) => {
      let itemResult = getQuestionResult(question.id);
      let files = getQuestionFiles(question.id)

      items.push({
        id: itemResult && itemResult.id ? itemResult.id : null,
        inspectionItem: question.item,
        roomType: roomTypeId,
        result: itemResult.result,
        inspectionItemType: question.itemType,
        inspection: inspectionId,
        ...((itemResult.numericValue && itemResult.result == 1) && {numericValue: itemResult.numericValue}),
        note: getQuestionNote(question.id),
        files: files ? files : [],
      })
    });
    await dispatch(deleteItemResultFiles({resultFiles: removedFiles}));
    await dispatch(saveInspection({ items, inspectionId }))
    setRoomListLoading(false)

  };
  function getQuestionFiles(id) {
    return !questionFilesTable ? [] : questionFilesTable[id] || [];
  }
  function getQuestionNote(id) {
    return !questionResultsTable ? '' : questionResultsTable[id]?.note || '';
  }
  function getQuestionResult(id) {
    return !questionResultsTable ? undefined : questionResultsTable[id];
  }
  function addQuestionFiles(id, files = []) {
    let resultFiles = getQuestionFiles(id);
    resultFiles = [...resultFiles, ...files.map((file) => ({ ...file, edited: true }))]

    let newTable = { ...questionFilesTable };
    newTable[id] = resultFiles;
    setQuestionFilesTable(newTable)
  }

 let isOwner=true;
 if(roomInspection?.inspectedBy){
 isOwner= roomInspection?.inspectedBy=== user?.id

 }
 const navigateToInspection = () => {
  const roomInspectionString = JSON.stringify(roomInspection);
  localStorage.setItem("inspectionReportData", roomInspectionString);
  navigate(`/printInspection`);
};
  return (
    <div className="section-details position-relative">
      <InnerLoading show={questionsLoading}></InnerLoading>
      {!(questionsLoading) && (
        <>
          <div className="d-flex flex-column flex-md-row pl15 pr15 section-details-header align-items-md-start align-items-end justify-content-between">
            <header className="order-3 order-md-1">
              <h5 className="section-title">{selectedRoom.name} Inspection</h5>
              <p className="list-label section-list-label mb-0">
                Select pass or fail for the below parameters and add notes for
                failed parameters
              </p>
            </header>
            {roomInspection?.id && roomInspection.inspectedBy === user.id && (
            <button 
              className="order-1 mb-4 mb-md-0 order-md-2 ms-auto me-4 btn btn-outline-secondary d-flex align-items-center text-uppercase shadow-none"
              onClick={onOpenDeleteInspection(roomInspection)}
              title="Delete this inspection"
            >
              <i className="me-2 fa-sharp fa-solid fa-trash"></i>
              Delete Inspection
            </button>
            )}
           
            <button 
              className="order-2 mb-4 mb-md-0 order-md-2 ms-auto me-4 btn btn-outline-secondary d-flex align-items-center text-uppercase shadow-none"
              onClick={navigateToInspection}
            >
            <i className="me-2 fas fa-file"></i>
               Inspection Report
            </button>
          
            <div className="order-2 mb-4 mb-md-0 order-md-3 d-flex flex-column section-right-title justify-content-end">
              Points<br/>
              <div className="list-content text-primary mt-2 text-center list-content-title">
                {scoreText}
              </div>
            </div>
          </div>
          <div className="section-details-content">
            <div className="inner-tab-section-list-holder pt-4">
              <ul className="section-list-block p-0 m-0">
                {questions.length > 0 &&
                  questions.map((question) => (

                    <li
                      className="inner-tab-section-list-item section-without-hover"
                      key={`inspectionItem${question.id}`}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="list-label me-2">
                          {question.item}
                        </div>
                        <div className="d-flex justify-content-end align-items-center" style={{ flex: '0 0 auto' }}>
                          {question.itemType == INSPECTION_ITEM_PASS_OR_FAIL_TYPE? 
                          <>
                            {canSaveInspection && (
                              <div className="form-check me-4">
                                <input
                                  className="form-check-input form-radio-input"
                                  type="radio"
                                  name={`passFail-${question?.id}`}
                                  value={1}
                                  checked={getQuestionResult(question?.id)?.result === 1}
                                  onChange={handleChange(question?.id)}
                                />
                                <label className="form-check-label mr-5">Pass</label>
                              </div>
                            )}
                            {canSaveInspection && (
                              <div className="form-check  me-4 ">
                                <input
                                  className="form-check-input form-radio-input"
                                  type="radio"
                                  name={`passFail-${question?.id}`}
                                  value={0}
                                  checked={getQuestionResult(question?.id)?.result === 0}
                                  onChange={handleChange(question?.id)}
                                />
                                <label className="form-check-label mr-5">Fail</label>
                              </div>
                            )}
                            {canSaveInspection && (
                              <div className="form-check">
                                <input
                                  className="form-check-input form-radio-input"
                                  type="radio"
                                  name={`passFail-${question?.id}`}
                                  value={3}
                                  checked={getQuestionResult(question?.id)?.result === 3}
                                  onChange={handleChange(question?.id)}
                                />
                                <label className="form-check-label mr-3">N/A</label>
                              </div>
                            )}
                          </>:<>
                          {getQuestionResult(question?.id)?.result !== 3 &&
                              <Form.Control
                                className="mb-0 w-75 me-2"
                                type="number"
                                name={`passFail-${question?.id}`}
                                onChange={handleChangeNumberInput(question?.id)}
                                value={getQuestionResult(question?.id)?.numericValue}
                              />
                            }
                            <Form.Check
                              type="checkbox"
                              name={`passFail-${question?.id}`}
                              value={getQuestionResult(question?.id)?.result}
                              label={`N/A`}
                              checked={getQuestionResult(question?.id)?.result === 3}
                              onChange={handleChange(question?.id)}
                            />
                          </>}
                          {question.itemType == INSPECTION_ITEM_PASS_OR_FAIL_TYPE? <>
                          {!canSaveInspection && getQuestionResult(question.id)?.result === 1 && (
                            <b><i className="fa-solid fa-check me-2"></i>Pass</b>
                          )}
                          {!canSaveInspection && getQuestionResult(question.id)?.result === 0 && (
                            <b><i className="fa-solid fa-x me-2  text-danger"></i>Fail</b>
                          )}
                           {!canSaveInspection && getQuestionResult(question.id)?.result === 3 && (
                            <b><i className="fa-solid fa-x me-2  text-secondary"></i>N/A</b>
                          )}
                          {!canSaveInspection && getQuestionResult(question.id) === undefined && (
                            <b>Pending</b>
                          )}
                          </>:<>
                            {!canSaveInspection && getQuestionResult(question.id)?.result === 3 && (
                              <b><i className="fa-solid fa-x me-2  text-secondary"></i>N/A</b>
                            )}
                            {!canSaveInspection && getQuestionResult(question.id)?.result === 1 && (
                              <b>
                                <i className="fa-solid fa-x me-2  text-secondary"></i>{getQuestionResult(question.id)?.numericValue || "-"}
                              </b>
                            )}
                          </>}

                        </div>
                      </div>
                      {getQuestionResult(question.id)?.result === 0 && (
                        <FailBlock
                          canSaveInspection={canSaveInspection}
                          question={question}
                          note={getQuestionNote(question.id)}
                          files={getQuestionFiles(question.id)}
                          handleRemoveFile={handleRemoveFile}
                          handleChange={handleChange}
                          handleFileChange={handleFileChange}
                          isOwner={isOwner}
                        />
                      )}
                    </li>
                  ))}
              </ul>
            </div>
            {canSaveInspection && (
              <div>
                <div className="form-floating mt-5 go-bottom">
                  <textarea
                    required
                    className="form-control inspection-note-textarea"
                    name="note"
                    id="inspection_note"
                    rows={3}
                    value={inspectionNote}
                    onChange={(e) => setInspectionNote(e.target.value)}
                    placeholder="Inspection notes"
                  ></textarea>
                  <label htmlFor="inspection_note">Inspection Notes</label>
                </div>
                <div className="d-flex justify-content-between pb-4 align-items-center">
                  <div className="section-header">
                    <h6 className="section-file-title">Inspection Files</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <input
                      multiple
                      type="file"
                      name="files"
                      onChange={(e) => handleInspectionFileChange(e)}
                      id={`inspection-file`}
                      className="btn btn-outline-secondary"
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor={`inspection-file`}
                      className="btn btn-outline-secondary px-4 py-2 d-flex align-items-center text-uppercase"
                    >
                      Select files
                    </label>
                  </div>
                </div>
                {inspectionFiles.length > 0 && (
                  <div className="section-file-holder mb-4">
                    <div
                      style={{ marginTop: "-8px", marginLeft: "-8px" }}
                      className="d-flex flex-wrap justify-content-lg-start justify-content-center"
                    >
                      {inspectionFiles.map((file, i) => (
                        
                        <div
                          key={i}
                          className="item-result-file position-relative mt-2 ms-2"
                        >
                          {isImage(file.file)?
                          <div>
                            <img
                              src={file.file}
                              // onClick={() => {setFile(file); setOpenPreviewModal(true)}}
                              alt="Question Result File"
                              className="file-responsive section-file ms-0 me-0"
                            />
                            <div className="file-title" >{file.fileName || ""}</div>
                          </div>: 
                          <a onClick={() => handleDownloadFile(file.file, file.fileName)}>
                            <FileText size={150} />
                            <div className="file-title" >
                              {file.fileName || ""}
                            </div>
                          </a>}
                          {isOwner && (
                            <a
                              href="#"
                              onClick={() => handleRemoveInspectionFiles(i)}
                              className="position-absolute top-0 end-0 me-2 mt-1 shadow-sm rounded-circle overflow-hidden"
                            >
                              <i
                                className="fa-sharp fa-solid fa-circle-xmark rounded-circle"
                                style={{ background: "#fff" }}
                              ></i>
                            </a>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="inspection-save-question d-flex justify-content-end">
                  <button
                    className="btn btn-primary m-4 me-3 px-5"
                    disabled={updatedItemResultsLoading}
                    onClick={handleSaveResultClick}
                  >
                    {updatedItemResultsLoading ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default InspectionForm;
