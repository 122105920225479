import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { resetUserStatus } from "../features/admin/adminSlice";
import { fetchSchoolSuccess, resetBlockSchoolSuccess } from "../features/school/schoolSlice";
import { fetchRoles, updateUser } from "../services/admin";
import { sendUserInvite } from "../services/auth";
import { fetchDistricts } from "../services/District";
import { fetchSchools } from "../services/school";
import "./InviteUserModal.scss";

import BaseModal from "components/BaseModal";
import _ from "lodash";

const AssignUserModal = ({ showModal, handleClose, rowData }) => {
  const dispatch = useDispatch();
  const { data: districts, success } = useSelector(
    ({ district }) => district.districts
  );
  const { data: updateData, success: updateSuccess, loading: updateLoading } = useSelector(
    ({ admin }) => admin.updateUser
  );

  const {
    data: rolesData,
    success: rolesSuccess,
    error: rolesError,
  } = useSelector(({ admin }) => admin.roles);

  const { data: schools, success: schoolsSuccess } = useSelector(
    ({ school }) => school.schools
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    role: "",
    district_id: "",
    school_ids: [],
  });
  // reset all states
  useEffect(() => {
    setShowSuccessMessage(false);
    setSuccessMessage("");
    setShowErrorMessage(false);
    setErrorMessage("");
    dispatch(resetUserStatus());
    if (!rolesSuccess) {
      dispatch(fetchRoles());
    }
    dispatch(fetchDistricts());
  }, [showModal]);

  useEffect(() => {
    dispatch(fetchSchoolSuccess([]));
    if (formData.district_id) {
      dispatch(
        fetchSchools(formData.district_id)
      );
    }
  }, [formData.district_id])

  // set Form data
  useEffect(() => {
    if (rowData) {
      setFormData({
        role: rowData.role.value,
        district_id: rowData.districts.length != 0 ? rowData.districts[0].id : "",
        school_ids: rowData.schools ? rowData.schools.map((sc) => sc.id) : [],
      });
    }
  }, [rowData, showModal]);

  const [formErrors, setFormErrors] = useState({
    role: false,
    district_id: false,
    school_ids: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "district_id") {
      dispatch(resetBlockSchoolSuccess({ blockType: "schools" }));
      dispatch(fetchSchools(value));
    }
    if (name === "school_ids") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: Array.from(event.target.selectedOptions, (option) => option.value),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let errors = false;
    const newFormErrors = {};
    Object.keys(formData).forEach((key) => {
      if (_.isEmpty(formData[key])) {
        if (key == "school_ids") {
          if (formData.role === "inspector") {
            errors = true;
            newFormErrors[key] = true;
          }
        } else {
          if (key !== "district_id") {
            errors = true;
            newFormErrors[key] = true;
          }
        }
        
      } else {
        newFormErrors[key] = false;
      }
    });
    setFormErrors(newFormErrors);
    if (!errors) {
      dispatch(updateUser(rowData.id, formData));
    }
  };

  // close or show error on submit
  useEffect(() => {
    if (updateSuccess) {
      setSuccessMessage("User updated successfully!");
      setShowSuccessMessage(true);
      handleClose();
    } else if (updateData) {
      setErrorMessage(updateData);
      setShowErrorMessage(true);
    }
  }, [updateSuccess, updateData]);

  const roleOptions = rolesData?.roles || [];
  const districtOptions = districts.map((district) => {
    return { value: district.id, label: district.name };
  });
  const schoolOptions = schools.map((school) => {
    return { value: school.id, label: school.name };
  });

  return (
    <BaseModal
      onSubmit={handleSubmit}
      onClose={handleClose}
      show={showModal}
      title="Manage User"
      formLoading={updateLoading}
      formWrapper={true}
      content={
        showSuccessMessage ? (
          <MessageBox>
            <p>{successMessage}</p>
          </MessageBox>
        ) : showErrorMessage ? (
          <MessageBox color="red">
            <p>{errorMessage}</p>
          </MessageBox>
        ) : (
          <>
            <Form.Group>
              <Form.Label>Select Role</Form.Label>
              <Form.Control
                as="select"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
                className="mb-0"
                required
              >
                <option value={null}>select role</option>
                {roleOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
              {formErrors.role && (
                <Form.Text className="text-danger">Field Role is required.</Form.Text>
              )}
            </Form.Group>
            {formData.role !== "simple_user" && (
              <Form.Group className="mt-2">
                <Form.Label>Select District</Form.Label>
                <Form.Control
                  as="select"
                  name="district_id"
                  value={formData.district_id}
                  onChange={handleInputChange}
                  className="mb-0"
                  required
                >
                  <option value={null}>select district</option>
                  {districtOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
                {formErrors.district_id && (
                  <Form.Text className="text-danger">This field is required.</Form.Text>
                )}
              </Form.Group>
            )}
            {formData.role !== "simple_user" && formData.role !== "district_admin" && (
              <Form.Group className="mt-2">
                <Form.Label>Select Schools</Form.Label>
                <Form.Control
                  as="select"
                  name="school_ids"
                  multiple={true}
                  value={formData.school_ids}
                  onChange={handleInputChange}
                  className="mb-0"
                >
                  {schoolOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
                {formErrors.school_ids && (
                  <Form.Text className="text-danger">This field is required.</Form.Text>
                )}
              </Form.Group>
            )}
          </>
        )
      }
    />
  );
};
const MessageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffe;
  color: green;
  margin: 10px;
`;

export default AssignUserModal;
