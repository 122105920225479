import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { bulkDeleteDefaultItems, bulkUpdateDefaultItems, fetchDefaultInspectionItems,} from "services/inspection";
import { resetBlock } from "features/inspection/inspectionSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  INSPECTION_ITEM_PASS_OR_FAIL_TYPE,
  INSPECTION_ITEM_PASS_OR_FAIL_TYPE_NAME,
  INSPECTION_ITEM_NUMERICAL_TYPE_NAME
} from "utils/constants";
import RoomItemsForm from "../../components/Inspections/RoomItemsForm";

const makeDefaultInspectionItem = (type) => ({
  id: null,
  item: '',
  itemType: type,
  itemTypeName: type == INSPECTION_ITEM_PASS_OR_FAIL_TYPE? INSPECTION_ITEM_PASS_OR_FAIL_TYPE_NAME: INSPECTION_ITEM_NUMERICAL_TYPE_NAME,
  roomType: null,
  edited: false,
})
export function RoomForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const {
    bulkUpdateDefaultItems: {
      success: bulkUpdateSuccess,
      loading: bulkUpdateLoading,
      data: updatedItems,

    },
    defaultInspectionItems: { success, loading, data: inspectionItems } } = useSelector(({ inspection }) => inspection);
  const [questions, setQuestions] = useState([makeDefaultInspectionItem()]);
  const [roomType, setRoomType] = useState({ name: 'New Room' });
  const [removedQuestions, setRemovedQuestions] = useState([]);
  

  useEffect(() => {
    let roomTypeId = params.roomTypeId;
    if (roomTypeId && (!success || !inspectionItems[roomTypeId])) {
      dispatch(fetchDefaultInspectionItems({ roomTypeId: params.roomTypeId }));
    }
    let rt = location?.state?.roomType;
    if (rt) {
      setRoomType(rt)
    }
    return () => {
      dispatch(resetBlock({ blockType: 'bulkUpdateDefaultItems' }))
      dispatch(resetBlock({ blockType: 'bulkDeleteDefaultItems' }))
    }
  }, []);
  useEffect(() => {
    if (success) {
      let items = inspectionItems[params.roomTypeId];
      if (items) {
        setQuestions(items.map((item) => {
          return {
            ...item,
            edited: false,
          }
        }))
      }
    }
  }, [success]);

 
  useEffect(() => {
    if (bulkUpdateSuccess) {
      let updates = updatedItems;
      if (removedQuestions.length > 0) {
        let removedIds = removedQuestions.map(({ id }) => id);
        updates = updates.filter(({ id }) => {
          return !removedIds.includes(id);
        });
        dispatch(bulkDeleteDefaultItems({ items: removedQuestions, roomTypeId: roomType.id})).then(() => {
          setRemovedQuestions([]);
        });
      }
      setQuestions(updates.map((item) => ({ ...item, edited: false })));

    }
  }, [bulkUpdateSuccess])

  const handleAddQuestion = (type=INSPECTION_ITEM_PASS_OR_FAIL_TYPE) => {
    setQuestions([...questions, makeDefaultInspectionItem(type)]);
  }
  const handleRemoveQuestion = (idx) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const removed = questions[idx];
    setQuestions(questions.filter((_, i) => i !== idx));
    if (removed.id) {
      setRemovedQuestions([...removedQuestions, removed]);
    }
  }
  const onQuestionInputChange = (idx) => ({ target: { value } }) => {
    questions[idx].item = value;
    questions[idx].edited = true;
    setQuestions(questions.slice())
  }
  const onSaveAll = async () => {
    var roomQuestions = questions
      .filter(({ id, item, edited }) => item && (edited || !id))
      .map(({ id, item, itemType, roomType }) => {
        return {
          roomType: roomType ? roomType:params.roomTypeId,
          item,
          itemType,
          id: id
        }
      })
    dispatch(bulkUpdateDefaultItems({ roomTypeId:params.roomTypeId, items: roomQuestions }));
  };
  const handleBackClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate('/defaultRooms')
  }

  return (
    <>
    <RoomItemsForm
      roomName={roomType ? roomType.name : ''}
      questions={questions}
      isUpdatingForm={ bulkUpdateLoading || loading}
      isLoadingQuestion={loading}
      handleBackClick={handleBackClick}
      handleSaveRoomClick={onSaveAll}
      handleAddQuestionClick={handleAddQuestion}
      handleRemoveQuestionClick={handleRemoveQuestion}
      handleQuestionInputChange={onQuestionInputChange}
    />
    </>
  )
}