import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMapApi as addMap,
  getMap,
  removeMap,
} from "../../../services/school";
import MAPModal from "./MAPModal";
import { resetBlockFlags } from "features/school/schoolSlice";
import { Container, Row, Col } from "react-bootstrap";
import InnerLoading from "components/InnerLoading";
import DeleteModal from "./DeleteModal";
import BaseModal from "components/BaseModal";

export default function Map({ schoolId }) {
  const dispatch = useDispatch();

  const [openMapModal, setOpenMapModal] = useState(false);
  const [openRemoveMapModal, setOpenRemoveMapModal] = useState(false);
  const [opendPreviewModal, setOpenPreviewModal] = useState(false);
  const [mapImage, setMapImage] = useState("");
  const [previewImage, setPreviewImage] = useState({ id: "", image: "" });
  const resetPreview = () => {
    setPreviewImage({ id: "", image: "" });
  };
  const {
    map: { data, success, error, loading },
    addMap: { loading: addMapLoading },
    removeMap: {
      success: removeMapSuccess,
      error: removeMapError,
      loading: removeMapLoading,
    },
  } = useSelector(({ school }) => school);

  useEffect(() => {
    if (!success) {
      dispatch(getMap(schoolId));
    }
    return () => {
      dispatch(resetBlockFlags({ blockType: "addMap" }));
      dispatch(resetBlockFlags({ blockType: "removeMap" }));
    };
  }, []);

  const handleSubmit = (base64, id = "") => {
    const object = {
      image: base64,
      schoolBuilding: schoolId,
    };

    dispatch(addMap(object, id));
  };
  const handleRemoveMap = async () => {
    const cb = () => {
      onCloseDeleteModal();
      resetPreview();
      onClosePreviewModal();
    };
    await dispatch(removeMap(previewImage?.id, cb));
  };

  const toggleMapModal = async (base64) => {
    setOpenMapModal((prev) => !prev);
  };
  const onOpenDeleteModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenRemoveMapModal(true);
  };
  const onCloseDeleteModal = () => setOpenRemoveMapModal(false);
  const onClosePreviewModal = () => setOpenPreviewModal(false);
  const onOpenPreviewModal = (id, image) => {
    setPreviewImage({ id, image });
    setOpenPreviewModal(true);
  };
  return (
    <Container fluid>
      {openMapModal && (
        <MAPModal
          open={openMapModal}
          onSubmit={handleSubmit}
          onClose={toggleMapModal}
          schoolId={schoolId}
          defaultPreviewImage={previewImage}
        />
      )}
      {openRemoveMapModal && (
        <DeleteModal
          loading={removeMapLoading}
          open={openRemoveMapModal}
          onOpenModal={onOpenDeleteModal}
          onCloseModal={onCloseDeleteModal}
          onDelete={handleRemoveMap}
          title="Map"
          message=" this map from the building"
        />
      )}
      {opendPreviewModal && (
        <BaseModal
          onClose={onClosePreviewModal}
          show={opendPreviewModal}
          className="text-center"
          size="lg"
          formWrapper={false}
          showFooter={false}
          headerProps={{
            closeButton: true,
            className:
              "pt-0 position-absolute mb-0 top-0 end-0 mt-3 me-0 cursor-pointer",
            style: { zIndex: 10 },
          }}
          content={
            <div>
              <img
                className="img-fluid mt-2 mx-auto d-block"
                src={previewImage?.image}
                alt="Map Image Preview"
                style={{
                  width: "auto",
                  height: "600px",
                  maxHeight: "80vh",
                  display: "block",
                  marginBottom: "10px",
                }}
              />
            </div>
          }
          actionButtons={
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                type="submit"
                className={`btn btn-primary btn-small py-2`}
                onClick={() => {
                  onClosePreviewModal();
                  setOpenMapModal(true);
                }}
              >
                <i
                  className="fa-sharp fa-solid fa-pen-to-square"
                  style={{ color: "currentColor" }}
                ></i>
                Update map
              </button>

              <button
                onClick={onOpenDeleteModal}
                className={`btn btn-danger btn-small ms-2`}
              >
                <i
                  className="fa-sharp fa-solid fa-trash"
                  style={{ color: "currentColor" }}
                ></i>
                Delete Map
              </button>
            </div>
          }
        />
      )}
      <>
        <div className="d-flex flex-column position-relative">
          <InnerLoading show={loading || addMapLoading} />
          <div className="d-flex aling-items-center m-3">
            <button
              type="submit"
              className={`btn btn-primary btn-small ${
                !mapImage ? "ms-auto" : ""
              }`}
              onClick={() => {
                resetPreview();
                toggleMapModal();
              }}
            >
              <i
                className="fa-sharp fa-solid fa-cloud-arrow-up me-2"
                style={{ color: "currentColor" }}
              ></i>
              Upload map
            </button>
          </div>
          <Row>
            {data?.length > 0 ? (
              data?.map((item) => (
                <Col sm={12} lg={3} md={4}>
                  <div
                    className="upload-image"
                    onClick={() => onOpenPreviewModal(item?.id, item?.image)}
                    key={item?.id}
                  >
                    <img src={item?.image} alt="upload-img" />
                  </div>
                </Col>
              ))
            ) : (
              <p className="d-flex justify-content-center">No map to display</p>
            )}
          </Row>
        </div>
      </>
    </Container>
  );
}
