import { createSlice } from "@reduxjs/toolkit";

const block = {
  loading: false,
  error: "",
  success: false,
};

const initialState = {
  reports: { ...block, data: [] },
  reportGraph:{...block,data:[]},
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setBlockLoading(state, { payload: { blockType, loading = true } }) {
      state[blockType] = { ...state[blockType], loading };
    },
    fetchReportsSuccess: ({ reports }, action) => {
      reports.data = action.payload;
      reports.loading = false;
      reports.success = true;
      reports.error='';
    },
    fetchReportsFailure: ({ reports }, action) => {
      reports.error = action.payload;
      reports.loading = false;
      reports.success = false;
    },
    fetchGraphReportSuccess: ({ reportGraph }, action) => {
      reportGraph.data = action.payload;
      reportGraph.loading = false;
      reportGraph.success = true;
      reportGraph.error='';
    },
    fetchGraphReportFailure: ({ reportGraph }, action) => {
      reportGraph.error = action.payload;
      reportGraph.loading = false;
      reportGraph.success = false;
    },
  },
});

export const {
fetchReportsFailure,
fetchReportsSuccess,
fetchGraphReportFailure,
fetchGraphReportSuccess,
  setBlockLoading,
} = reportSlice.actions;
export default reportSlice.reducer;
