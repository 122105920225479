import React, { useEffect, useMemo } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { Spinner, Table } from "react-bootstrap";
import sortArrow from "../Dashboard/assets/sort-arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchInspectReport } from "services/inspectReport/inspectReportService";
import { useNavigate } from "react-router-dom";
import "./inspectReportTable.scss"

function InspectReportTable({
  filterTable,
  searchTable,
  roomType,
  reportType,
}) {
  const dispatch = useDispatch();
  const { reports } = useSelector(({ report }) => report);
  const { data, loading, success } = reports;
  const navigate = useNavigate();
  useEffect(() => {
    if (searchTable !== "") {
      setGlobalFilter(searchTable.toLowerCase());
    } else if (filterTable !== "") {
      setGlobalFilter(filterTable.toLowerCase());
    } else {
      setGlobalFilter("");
    }
  }, [filterTable, searchTable]);
  useEffect(() => {
    dispatch(fetchInspectReport(reportType, roomType));
  }, [roomType, reportType]);

  const columns = [
    {
      Header: "Name",
      accessor: (row) => `${row?.name}`,
      Cell: ({ value }) => value=='null'? "":value||"",
      filter: "text",
    },
    {
      Header: "Average Score",
      accessor: (row) => `${row?.avgScore}`,
      Cell: ({ value }) => value=='null'? "":value||"",
      filter: "text",
    },
    {
      Header: "Inspections",
      accessor: (row) => `${row?.inspectionsCount}`,
      Cell: ({ value }) => value=='null'? "":value||"",
      filter: "text",
    },
  ];

  if (reportType === "schools" || reportType === "cleaners") {
    columns.push({
      Header: "District Name",
      accessor: (row) => `${row?.districtName}`,
      Cell: ({ value }) => value=='null'? "":value||"",
      filter: "text",
    });
  }

  if (reportType === "cleaners") {
    columns.push({
      Header: "Building Name",
      accessor: (row) => `${row?.schoolName}`,
      Cell: ({ value }) => value=='null'? "":value||"",
      filter: "text",
    });
  }
  const memoizedColumns = useMemo(() => [...columns], [reportType]);

  const memoizedData = useMemo(
    () => data,
    [reportType, roomType, success, loading,data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    prepareRow,
    state: { pageIndex},
    setGlobalFilter,
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: {
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const handleRowClick = (rowData) => {
    navigate(`/reports/${rowData?.pk}/${reportType}`);
  };

  return (
    <div className="table-container">
      <Table striped bordered hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="text-center">
              {headerGroup?.headers.map((column) => (
                <th {...column?.getHeaderProps(column?.getSortByToggleProps())}>
                  {column?.render("Header")}
                  <span>
                    <img src={sortArrow} alt="Sort icon"/>
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading ? (
            <tr aria-rowspan={5}>
              <td colSpan={columns?.length} className="text-center">
                <Spinner variant="primary" />
              </td>
            </tr>
          ) : data.length === 0 ? (
            <tr aria-rowspan={5}>
              <td colSpan={columns?.length} className="text-center">
                No Record found
              </td>
            </tr>
          ) : (
            page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => handleRowClick(row.original)}
                  className="text-center"
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      {!loading &&  (
        <div className="pagination">
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>
          <span className="ms-2">
            <span className="me-1"> Page </span>
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
        </div>
      )}
    </div>
  );
}

export default React.memo(InspectReportTable);
