import axios from "axios";
import Cookies from "js-cookie";
import storage from "redux-persist/lib/storage";

// const baseURL = "http://127.0.0.1:8000";
// const baseURL = "https://dev.qck12.com";
const baseURL = process.env.REACT_APP_API_BASE_URL;

const getToken = (name = "token") => {
  return Cookies.get(name);
};
const getRefresh = (name = "refresh") => {
  return Cookies.get(name);
};
const refreshToken = () => {
  // gets new access token
  Axios.post("api/v1/auth/token/refresh/", {
    refresh: getRefresh(),
  })
    .then((response) => {
      const { access } = response;
      const options = { path: "/" };
      Cookies.set("token", access, options);
      window.location.reload();
    })
    .catch((err) => {
      // logout user refresh token has also expired
      storage.removeItem("persist:root");
      Cookies.remove("token", { path: "/" });
      Cookies.remove("refresh", { path: "/" });
      window.location.reload();
    });
};

const getCsrftoken = (name = "csrftoken") => {
  return Cookies.get(name);
};

const Axios = axios.create({
  baseURL,
  timeout: 80000,
});

Axios.interceptors.request.use(
  async (config) => {
    const token = getToken("token");
    const csrftoken = getCsrftoken("csrftoken");
    if (token) config.headers.Authorization = `JWT ${token}`;
    // config.withCredentials = true;
    config.headers["X-CSRFToken"] = csrftoken;
    return config;
  },
  (error) => Promise.reject(error)
);

Axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status === 401 && getRefresh() !== null) {
      // refresh token
      refreshToken();
    }
    return Promise.reject(error);
  }
);

export { Axios };
