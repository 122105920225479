import { Axios } from "api/axios"
import { convertSecondsToMinutes, getHeader, toastMsg } from "utils/utility";
import { getSimplifiedError } from "utils/error";

import {
  fetchDefaultInspectionItemsSuccess,
  fetchDefaultInspectionItemsFailure,
  startBlockFlags,
  setBlockSuccessFlags,
  setBlockFailure,
  fetchRoomTypesSuccess,
  createRoomTypeSuccess,
  updateRoomTypeSuccess,
  deleteRoomTypeSuccess,
  bulkUpdateDefaultItemsSuccess,
  fetchInspectionItemsSuccess,
  bulkUpdateItemsSuccess,
  fetchInspectionsSuccess,
  fetchInspectionSuccess,
  createInspectionSuccess,
  fetchItemResultsSuccess,
  updateInspectionSuccess,
  fetchItemResultFilesSuccess,
  updateItemResultFilesSuccess,
  updateItemResultsSuccess,
  deleteItemResultFilesSucces,
  deleteInspectionSuccess
} from "features/inspection/inspectionSlice";
import { NOTIFICATION } from "constants/constants";

export const fetchInspections = ({schoolId, cleanerId}) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'inspections' }))
  try {
    let paramsObject = {};
    if (schoolId) {
      paramsObject.school =  schoolId;
    }
    if (cleanerId) {
      paramsObject.cleaner = cleanerId; 
    }
    const params = new URLSearchParams(paramsObject).toString();
    let url = `/api/v1/inspection/?${params}`;
    let response = await Axios.get(url, getHeader());
    dispatch(fetchInspectionsSuccess(response));
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'inspections',
      error: getSimplifiedError(error)
    }));
  }
};

export const fetchInspection = ({inspectionId}) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'inspection' }))
  try {
    let url = `/api/v1/inspection/${inspectionId}/`;
    let response = await Axios.get(url, getHeader());
    dispatch(fetchInspectionSuccess(response));
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'inspection',
      error: getSimplifiedError(error)
    }));
  }
};

export const createInspection = ({ roomId: room }) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'createInspection' }))
  try {
    const response = await Axios.post(`/api/v1/inspection/`, { room }, getHeader());
    dispatch(createInspectionSuccess(response));
    return response;
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'createInspection',
      error: getSimplifiedError(error)
    }));
  }
};

export const updateInspection = ({ inspection }) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'updateInspection' }))
  try {
    const response = await Axios.patch(`/api/v1/inspection/${inspection.id}/`,
    inspection, getHeader());
    dispatch(updateInspectionSuccess(response));
    return response;
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'updateInspection',
      error: getSimplifiedError(error)
    }));
  }
};
export const fetchDefaultInspectionItems = ({roomTypeId}) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'defaultInspectionItems' }))
  try {
    let url = `/api/v1/default-inspection-item/`;
    url = roomTypeId ? `${url}?room_type=${roomTypeId}` : url;
    let response = await Axios.get(url, getHeader());
    dispatch(fetchDefaultInspectionItemsSuccess({items: response, roomTypeId}));
  } catch (error) {
    dispatch(fetchDefaultInspectionItemsFailure(getSimplifiedError(error)));
  }
};

export const fetchInspectionItems = ({roomId}) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'inspectionItems' }))
  try {
    let url = `/api/v1/inspection-item/`;
    url = roomId ? `${url}?room=${roomId}` : url;
    let response = await Axios.get(url, getHeader());
    dispatch(fetchInspectionItemsSuccess({items: response, roomId}));
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'inspectionItems',
      error: getSimplifiedError(error)
    }));
  }
};
export const fetchRoomTypes = () => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'roomTypes' }));
  try {
    let response = await Axios.get(`/api/v1/room-type/`, getHeader());
    const data = response.map(item => ({
      ...item,
      workloadInMinutes: convertSecondsToMinutes(item?.workloadInSeconds)
    }));
    
    dispatch(fetchRoomTypesSuccess(data));

  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'roomTypes',
      error: getSimplifiedError(error)
    }));
  }

}

export const createRoomType = ({ name },cb,roomFeatures=[]) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'createRoomType' }))
  try {
    const response = await Axios.post(`/api/v1/room-type/`, { name }, getHeader());
    const promises = roomFeatures.map(async (item) => {
      const {timeInMinutes,...rest}=item;
      const roomFeatureResponse = await Axios.post(
        `/api/v1/default-room-feature/`,
        { ...rest, roomType
          : response?.id },
        getHeader()
      );
      return roomFeatureResponse;
    });
    const features=await Promise.all(promises);
    const totalTimeInSeconds = features.reduce((total, feature) => {
       total +=feature.timeInSeconds * feature.quantity
       return total;
  }, 0);
    
    dispatch(createRoomTypeSuccess({...response,workloadInSeconds:totalTimeInSeconds}));
    if(cb){
      cb(response?.id)
    }
    // toastMsg("Room Type Created Successfully",NOTIFICATION.success)
    return response;
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'createRoomType',
      error: getSimplifiedError(error)
    }));
    toastMsg("Room Type cannot be created",NOTIFICATION.error)
  }
};

export const deleteRoomType = ({roomType}) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'deleteRoomType' }))
  try {
    await Axios.delete(`/api/v1/room-type/${roomType.id}/`, getHeader());
    dispatch(deleteRoomTypeSuccess(roomType));
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'deleteRoomType',
      error: getSimplifiedError(error)
    }));
  }
};

export const updateRoomType = ({ roomType },cb) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'updateRoomType' }))
  try {
    const response = await Axios.patch(`/api/v1/room-type/${roomType.id}/`, roomType, getHeader());
    dispatch(updateRoomTypeSuccess(response));
    toastMsg("Room Type Updated Successfully",NOTIFICATION.success)
    if(cb){
      cb();
    }
    return response;
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'updateRoomType',
      error: getSimplifiedError(error)
    }));
    const msg="Room Type Cannot be Updated";
    toastMsg(msg,NOTIFICATION.error)
  }
};

export const bulkUpdateDefaultItems = ({ roomTypeId, items = [] }) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'bulkUpdateDefaultItems' }))
  try {
    await Promise.all(items.map((item) => {
      let method = item.id ? 'patch' : 'post';
      let url = `/api/v1/default-inspection-item/`;
      url = item.id ? `${url}${item.id}/` : url;
      let request = Axios[method](url, item, getHeader());
      return request;
    }))
    let response = await Axios.get(
      `/api/v1/default-inspection-item/?room_type=${roomTypeId}`,
      getHeader());
    dispatch( bulkUpdateDefaultItemsSuccess({items: response, roomTypeId}))
    toastMsg("Questions Updated Successfully",NOTIFICATION.success)
    return response;
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'bulkUpdateDefaultItems',
      error: getSimplifiedError(error)
    }));
    toastMsg("Cannot  Update Question",NOTIFICATION.success)
  }
};

export const bulkUpdateItems = ({ roomId, items = [] ,closeModal}) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'bulkUpdateItems' }))
  try {
    await Promise.all(items.map((item) => {
      let method = item.id ? 'patch' : 'post';
      let url = `/api/v1/inspection-item/`;
      url = item.id ? `${url}${item.id}/` : url;
      let request = Axios[method](url, item, getHeader());
      return request;
    }))
    let response = await Axios.get(
      `/api/v1/inspection-item/?room=${roomId}`,
      getHeader());
    dispatch(bulkUpdateItemsSuccess({items: response, roomId}))
    if(closeModal){
     closeModal();
    }
    toastMsg("Room Type Created Successfully",NOTIFICATION.success)
    return response;
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'bulkUpdateDefaultItems',
      error: getSimplifiedError(error)
    }));
  }
};


export const bulkDeleteDefaultItems = ({ items = [], roomTypeId }) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'bulkDeleteDefaultItems' }))
  try {
    await Promise.all(items.map((item) => {
      let url = `/api/v1/default-inspection-item/${item.id}/`;
      return Axios.delete(url, getHeader());
    }))
    await dispatch(setBlockSuccessFlags({ blockType: 'bulkDeleteDefaultItems' }));
    let response = await Axios.get(
      `/api/v1/default-inspection-item/?room_type=${roomTypeId}`,
      getHeader());
    dispatch(bulkUpdateDefaultItemsSuccess({items: response, roomTypeId}))
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'bulkDeleteDefaultItems',
      error: getSimplifiedError(error)
    }));
  }
};

export const bulkDeleteItems = ({ items = [], roomId }) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'bulkDeleteItems' }))
  try {
    await Promise.all(items.map((item) => {
      let url = `/api/v1/inspection-item/${item.id}/`;
      return Axios.delete(url, getHeader());
    }))
    await dispatch(setBlockSuccessFlags({ blockType: 'bulkDeleteItems' }));
    let response = await Axios.get(
      `/api/v1/inspection-item/?room=${roomId}`,
      getHeader());
    dispatch(bulkUpdateItemsSuccess({items: response, roomId}))
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'bulkDeleteItems',
      error: getSimplifiedError(error)
    }));
  }
};
export const saveInspection = ({items = [], inspectionId }) => async (dispatch) => {
  dispatch(startBlockFlags(({blockType: 'updateItemResults'})))
  try {
    let imagesTables = {};
    let response = await Promise.all(items.map((item, i) => {
      item = {...item};
      imagesTables[i] = item.files;
      delete item.files;
      let method = item.id ? 'patch' : 'post';
      let url = `/api/v1/item-result/`;
      url = item.id ? `${url}${item.id}/` : url;
      let request = Axios[method](url, item, getHeader());
      return request;
    }))

    //Save images after items results update

    let resultFiles = [];
    response.forEach(({id }, i) => {
      const images = imagesTables[i];
      if (!images || images.length === 0) {
        return;
      }
      images.forEach((image) => {
        if (!image.edited) {
          return;
        }
        resultFiles.push({
          ...image,
          itemResult: id,
        });
      })
    })
    await dispatch(updateItemResultsSuccess({items: response, inspectionId}))
    if (resultFiles.length > 0) {
      await dispatch(updateItemResultFiles({ resultFiles }));
    }
    dispatch(startBlockFlags(({blockType: 'updateInspection'})))
    let updatedInspection = await Axios.get(`/api/v1/inspection/${inspectionId}/`, getHeader());
    dispatch(updateInspectionSuccess(updatedInspection));
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'updateItemResults',
      error: getSimplifiedError(error)
    }));
  }
};
export const fetchItemResults = ({inspectionId}) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'itemResults' }))
  try {
    let response = await Axios.get(`/api/v1/item-result/?inspection=${inspectionId}`, getHeader());
 
    dispatch(fetchItemResultsSuccess({items: response, inspectionId}));
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'itemResults',
      error: getSimplifiedError(error)
    }));
  }
}

export const fetchItemResultFiles = () => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'itemResultFiles' }))
  try {
    let response = await Axios.get(`/api/v1/item-result-file/`, getHeader());
    dispatch(fetchItemResultFilesSuccess(response));
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'itemResultFiles',
      error: getSimplifiedError(error)
    }));
  }
}

export const updateItemResultFiles = ({resultFiles = []}) => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'updateItemResultFiles' }))
  try {
    let response = await Promise.all(resultFiles.map((resultImage) => {
      let url = `/api/v1/item-result-file/`;
      url = resultImage.id ? `${url}/${resultImage.id}/` : url;
      let method = resultImage.id ? 'patch' : 'post';
      return Axios[method](url, resultImage,  getHeader());
    }))
    dispatch(updateItemResultFilesSuccess(response));
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'updateItemResultFiles',
      error: getSimplifiedError(error)
    }));
  }
}

export const deleteItemResultFiles = ({resultFiles = []})  => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'deleteItemResultFiles' }))
  try {
    await Promise.all(resultFiles.map((resultImage) => {
      let url = `/api/v1/item-result-file/${resultImage.id}/`;
      return Axios.delete(url, getHeader());
    }))
    dispatch(deleteItemResultFilesSucces(resultFiles));
  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'deleteItemResultFiles',
      error: getSimplifiedError(error)
    }));
  }
}

export const deleteInspection = ({inspectionId})  => async (dispatch) => {
  dispatch(startBlockFlags({ blockType: 'deleteInspection' }))
  try {
    await Axios.delete(`/api/v1/inspection/${inspectionId}/`, getHeader());
    await dispatch(fetchItemResults({inspectionId}));
    await dispatch(fetchItemResultFiles());
    dispatch(deleteInspectionSuccess(inspectionId));

  } catch (error) {
    dispatch(setBlockFailure({
      blockType: 'deleteInspection',
      error: getSimplifiedError(error)
    }));
  }
}

export const fetchInspectionMachineHours = async (id) => {
  try{
    const response = await Axios.get(`/api/v1/inspection/${id}/machine-hours/`, getHeader());
    return response 
  } catch (error) {
    return null;
  }
}