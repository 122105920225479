import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Image } from "react-bootstrap";
import { updateDistrict } from "../../services/District";

import BaseModal from "components/BaseModal";
import { toastMsg } from "utils/utility";
import { NOTIFICATION } from "constants/constants";

const EditDistrictForm = ({ district, openEditDistrictModal, CloseEditDistrictModal, loading = false }) => {
  const dispatch = useDispatch();

  const {id,...rest} = district;
    const [districtDetails, setDistrictDetails] = useState({
    name: "",
    logo: "",
    admins: [],
    maxBuildingsAllowed:"",
    subscriptionStartDate:null,
    subscriptionEndDate:null
  });
  const [base64Image, setBase64Image] = useState("");

  useEffect(() => {
    setDistrictDetails({ ...districtDetails, ...rest });
    setBase64Image(rest?.logo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [district]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setBase64Image(base64);
    setDistrictDetails({ ...districtDetails, logo: base64 });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (districtDetails.subscriptionStartDate!==null&& districtDetails.subscriptionEndDate!==null&&new Date(districtDetails.subscriptionStartDate) > new Date(districtDetails.subscriptionEndDate)) {

        toastMsg("Subscription Start date must be before the end date.",NOTIFICATION.error)
      }
      else{
        const cbSuccess=()=>{
          CloseEditDistrictModal();
          setDistrictDetails((prev) => ({ ...prev, name: "", logo: ""}));
          setBase64Image("");
        }
         dispatch(
          updateDistrict({
            id: district.id,
            name: districtDetails.name,
            logo: base64Image,
            admins: [],
            maxBuildingsAllowed:districtDetails.maxBuildingsAllowed,
            subscriptionStartDate:districtDetails.subscriptionStartDate,
            subscriptionEndDate:districtDetails.subscriptionEndDate,
          },cbSuccess)
        );
      }
     
    } catch (error) {
      console.error(error)
    }
  };

  const hideUpdateModal = () => {
    CloseEditDistrictModal();
    setDistrictDetails((prev) => ({ ...prev, name: "", logo: ""}));
    setBase64Image("");
  };

  const handleChange = ({ target: { name, value } }) => {
    setDistrictDetails({ ...districtDetails, [name]: value });
  };

  return (
    <BaseModal
      onSubmit={submitHandler}
      onClose={hideUpdateModal}
      show={openEditDistrictModal}
      title="Edit District Details"
      formWrapper={true} 
      formLoading={loading}
      submitButtonText="Update"
      content={
        <div>
          <div className="mb-4">
            <div className="form-floating mb-3 go-bottom">
              <input
                type="text"
                className="form-control"
                name="name"
                value={districtDetails.name}
                onChange={handleChange}
                id="floatingInput"
                placeholder="District Name"
                required
              />
              <label htmlFor="name">District Name</label>
            </div>
          </div>
          <div className="mb-4">
            <div className="form-floating mb-3 go-bottom">
              <input
                type="number"
                className="form-control"
                name="maxBuildingsAllowed"
                value={districtDetails?.maxBuildingsAllowed}
                onChange={handleChange}
                required
                id="maxBuildingsAllowed"
                placeholder="Number Of Buildings "
                max={2147483647}
                min={0}
              />
              <label htmlFor="maxBuildingsAllowed">Number Of Buildings </label>
            </div>
          </div>
          <div className="mb-4">
            <div className="form-floating mb-3 go-bottom">
              <input
                type="date"
                className="form-control"
                name="subscriptionStartDate"
                value={districtDetails?.subscriptionStartDate}
                onChange={handleChange}
                id="subscriptionStartDate"
                placeholder="Subscription Start Date"
              />
              <label htmlFor="subscriptionStartDate">Subscription Start Date</label>
            </div>
          </div>
          <div className="mb-4">
            <div className="form-floating mb-3 go-bottom">
              <input
                type="date"
                className="form-control"
                name="subscriptionEndDate"
                value={districtDetails?.subscriptionEndDate}
                onChange={handleChange}
                id="subscriptionEndDate"
                placeholder="Subscription End Date"
      
              />
              <label htmlFor="subscriptionEndDate">Subscription End Date</label>
            </div>
          </div>
          <div className="logo-uploader mb-4">
            <div className="logo-uploader-header d-flex align-items-center justify-content-between mb-3">
              <div>District logo</div>
              <input
                type="file"
                onChange={handleImageChange}
                id="select-image-new"
                className="btn btn-outline-secondary"
                style={{ display: "none" }}
              />
              <label
                htmlFor="select-image-new"
                className="btn btn-outline-secondary btn-lg d-flex align-items-center text-uppercase"
              >
                Select image
              </label>
            </div>
            <div className="logo-uploader-area">
              {base64Image === "" ? (
                <p>Drag & drop the image in this section</p>
              ) : (
                <Image src={base64Image} style={{ maxHeight: "100px" }} fluid />
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default EditDistrictForm;
