import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import DeleteModal from "../../components/Dashboard/OverviewComponents/DeleteModal";
import { resetFlagsSectionSuccess } from "../../features/section/sectionSlice";
import { deleteSection, fetchRoom } from "../../services/section";
import useWindowSize from "../../utils/useWindowSize";
import SectionForm from "../Dashboard/SectionForm";
import SectionsDetails from "./OverviewComponents/SectionDetails";
import SectionListing from "./OverviewComponents/SectionListing";
import UnselectSection from "./OverviewComponents/UnselectSection";
import InnerLoading from "components/InnerLoading";
import {toast} from 'react-toastify';
import {fetchRoomSuccess} from "features/section/sectionSlice";
import SidebarLayout from "components/SidebarLayout";

export default function Section({
  isSuperAdmin,
  isDistrictAdmin,
  isInspector,
  district,
  school,
  sections,
  openSelectedSection,
  setOpenSelectedSection,
  selectedSection,
  setSelectedSection,
}) {
  const dispatch = useDispatch();
  const sidebarLayoutRef = useRef();

  const [openSectionModal, setOpenSectionModal] = useState(false);
  const [editSectionModal, setEditSectionModal] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [hoveredSectionId, setHoveredSectionId] = useState("");

  const onOpenModal = () => setOpenSectionModal(true);
  const onCloseModal = () => setOpenSectionModal(false);
  const openEditModal = () => setEditSectionModal(true);
  const closeEditModal = () => setEditSectionModal(false);

  const {
    room: { data: rooms },
    sections: { success: sectionSuccess, loading: sectionsLoading },
    editRoom: { success: editRoomSuccess },
    createSection: {loading: createSectionLoading},
    editSection: {loading: editSectionLoading},
    deleteSection: {loading: deleteSectionLoading}
  } = useSelector(({ section }) => section);

  const [sectionRooms, setSectionRooms] = useState([]);

  useEffect(() => {
    if (selectedSection?.id) {
      setShowIcon(true);
      dispatch(fetchRoom(selectedSection.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection]);

  useEffect(() => {
    if (sectionSuccess && sections.length == 0 ) {
        dispatch(fetchRoomSuccess([]));
    }
  }, [sectionSuccess]);

  useEffect(() => {
    let rs = rooms;
    if (isInspector) {
      rs = rooms.filter((room) => !(room.inspectionDetail && room.inspectionDetail.length > 0))
    }
    setSectionRooms(rs);
  }, [rooms]);

  useEffect(() => {
    if (sectionSuccess && editRoomSuccess) {
      const updatedSection = sections.find(
        (section) => section.id === selectedSection?.id
      );
      setSelectedSection(updatedSection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRoomSuccess, sectionSuccess]);

  const handleClick = async (section) => {
    setSelectedSection(section);
    sidebarLayoutRef.current.toggleShowDetails()
  };
  const handleBackClick = async (section) => {
    setSelectedSection({});
    sidebarLayoutRef.current.toggleShowDetails()
  };

  const onOpenDeleteModal = () => setOpenDeleteModal(true);
  const onCloseDeleteModal = () => setOpenDeleteModal(false);

  async function handleDeleteSection() {
    const obj = {
      school: school.id,
      sectionId: selectedSection.id,
      district: district,
    };
    try {
      await dispatch(deleteSection(obj));
      toast.success("Section successfully deleted");
    } catch  {
      toast.error("This section could not be deleted");
    }
    setOpenDeleteModal(false);
  }

  return (
    <>
    <SidebarLayout
      ref={sidebarLayoutRef}
      sidebar={
        <div className="position-relative overflow-auto">
        <InnerLoading show={sectionsLoading}/>
        <SectionListing
            showIcon={showIcon}
            setEditSectionModal={setEditSectionModal}
            onOpenDeleteModal={onOpenDeleteModal}
            onOpenModal={onOpenModal}
            sections={sections}
            handleClick={handleClick}
            district={district}
            school={school}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            hoveredSectionId={hoveredSectionId}
            setHoveredSectionId={setHoveredSectionId}
          />
        </div>
      }
      mainContent={
        <SectionsDetails
          sectionDetails={selectedSection}
          rooms={sectionRooms}
          district={district}
          handleBackClick={handleBackClick}
          school={school}
          isSuperAdmin={isSuperAdmin}
          isDistrictAdmin={isDistrictAdmin}
          isInspector={isInspector}
        />
      }
    />
      {openSectionModal && (
        <SectionForm
          loading={createSectionLoading}
          district={district}
          school={school}
          open={openSectionModal}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
        />
      )}

      {editSectionModal && (
        <SectionForm
          loading={editSectionLoading}
          sectionDetails={selectedSection}
          school={school}
          open={editSectionModal}
          onOpenModal={openEditModal}
          onCloseModal={closeEditModal}
        />
      )}

      {openDeleteModal && (
        <DeleteModal
          loading={deleteSectionLoading}
          open={openDeleteModal}
          onOpenModal={onOpenDeleteModal}
          onCloseModal={onCloseDeleteModal}
          onDelete={handleDeleteSection}
          message=" Section"
        />
      )}
    </>
  );
}
