import React, { useState } from "react";
import { useDrop } from "react-dnd";

import useWindowSize from "../../../utils/useWindowSize";
import AssignCleaner from "./AssignCleaner";
import RoomForm from "./RoomForm";
import plusIcon from "../../../assets/images/plus-icon.svg";
import arrowRight from "../../../assets/images/arrow-right.svg";

export default function SectionsCard({
  school = {},
  district,
  sectionName,
  noOfRooms,
  noOfPeople,
  handleClick,
  sectionDetails,
  selectedSection,
  setSelectedSection,
  id,
  hoveredSectionId,
  setHoveredSectionId,
}) {
  const { width: screenWidth } = useWindowSize();

  const [openRoomModal, setOpenRoomModal] = useState(false);
  const [assignCleanerModal, setAssignCleanerModal] = useState(false);
  const [cleanerId, setCleanerId] = useState("");

  const toggleAddRoomModal = () => setOpenRoomModal((prev) => !prev);

  const handleAssignCleanerOpen = () => setAssignCleanerModal(true);
  const handleAssignCleanerClose = () => setAssignCleanerModal(false);

  const handleAddRoomClick = (e) => {
    e.stopPropagation();
    toggleAddRoomModal();
  };

  const [, drop] = useDrop({
    accept: "CLEANER",
    drop: (item) => {
      setCleanerId(item.cleanerId);
      setSelectedSection(sectionDetails);
      handleAssignCleanerOpen();
    },
    hover: () => {
      setHoveredSectionId(id);
    },
  });
  return (
    <>
      {assignCleanerModal && (
        <AssignCleaner
          cleanerId={cleanerId}
          sectionId={id}
          open={assignCleanerModal}
          onClose={handleAssignCleanerClose}
          sectionName={sectionName}
        />
      )}
      {openRoomModal && (
        <RoomForm
          sectionDetails={sectionDetails}
          open={openRoomModal}
          onCloseModal={toggleAddRoomModal}
          school={school}
          district={district}
        />
      )}
      <div
        ref={drop}
        id={`section-list-item-${id}`}
        className={`section-list-item ${
          selectedSection?.id === id || hoveredSectionId === id
            ? "active-item"
            : ""
        }`}
        onClick={handleClick}
      >
        <div className="section-list-header d-flex justify-content-between mb20">
          <h5>{sectionName}</h5>
          <div className="d-flex icon-holder">
            <img src={arrowRight} alt="" width={20} height={20} />
          </div>
        </div>
        <div className="section-header align-items-end">
          <div className="d-flex me-3">
            {!!noOfRooms && (
              <span className="me-3">{noOfRooms} Rooms</span>
            )}
            {!!noOfPeople && (
              <span className="">{noOfPeople} People</span>
            )}
          </div>
          <div className="header-button">
            <button
              className="btn btn-outline-secondary d-flex align-items-center text-uppercase shadow-none"
              onClick={handleAddRoomClick}
            >
              <img src={plusIcon} alt="" className="mr5 image-responsive" />
              Room
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
