import React, { useMemo } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { Table, FormCheck } from "react-bootstrap";
import sortArrow from "../Dashboard/assets/sort-arrow.svg";
import {convertToHoursMinutesSeconds } from "utils/utility";
import Loader from "components/Loader";
function WorkloadTable({
  data,
  loading,
  success,
  selectedRooms,
  setSelectedRooms,
  setActionType,
  toggleConfirmModal,
  setRoomID,
  setRoomDistrictId
}) {
  const handleCheckboxChange = (id) => {
    if (selectedRooms.includes(id)) {
      setSelectedRooms(selectedRooms.filter((room) => room !== id));
    } else {
      setSelectedRooms([...selectedRooms, id]);
    }
  };

  const columns = [
    {
      Header: "Room",
      accessor: "roomName",
      filter: "text",
      Cell: ({ value, row }) => {
        return (
          <div className="cursor" onClick={()=>{
            setRoomID(row.original.id);
            setRoomDistrictId(row.original.districtId)
            setActionType("editRoom")
            
            
          }}>
            {value}
           
          </div>
        );
      },
    },
    {
      Header: "Section",
      accessor: "sectionName",
      filter: "text",
    },
    {
      Header: "Building",
      accessor: "buildingName",
      filter: "text",
    },
    {
      Header: "Cleaner",
      accessor: "cleanerName",
      filter: "text",
      Cell: ({ value, row }) => {
        return (
          <span className="item">
            {value}
            {value && (
              <span
                className="remove-link text-info"
                onClick={() => {
                  if (selectedRooms.length == 0) {
                    setActionType("removeSingle");
                    setRoomID(row.original.id);
                  } else {
                    setActionType("removeMultiple");
                    setRoomID("");
                  }
                  toggleConfirmModal();
                }}
              >
                Remove
              </span>
            )}
          </span>
        );
      },
    },
    {
      Header: "Workload",
      accessor: "workloadInSeconds",
      Cell: ({ value }) =>{
        

        return convertToHoursMinutesSeconds(value)
         
      },
      filter: "text",
    },
  ];
  const memoizedColumns = useMemo(
    () => [
      {
        Header: "",
        id: "actions",
        Cell: ({ row }) => (
          <>
            <FormCheck
              type={"checkbox"}
              name="room"
              onChange={() => handleCheckboxChange(row?.original?.id)}
              checked={selectedRooms.includes(row?.original?.id)}
            />
          </>
        ),
      },
      ...columns,
    ],
    [selectedRooms]
  );

  const memoizedData = useMemo(() => data, [success, data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: {
        pageIndex: 0,
        sortBy: [{ id: "buildingName", desc: false }],
      },
    },
    useGlobalFilter,
    useSortBy,
  );

  return (
    <div className="workloadTableContainer">
      <Table
        striped
        bordered
        hover
        {...getTableProps()}
        className="workloadTable"
        responsive
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {" "}
                    <img src={sortArrow} alt="sort Image" />
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading ? (
            <tr>
              <td rowSpan={10} colSpan={6}>
                <Loader />
              </td>
            </tr>
          ) : memoizedData.length === 0 ? (
            <tr>
              <td colSpan={6} className="text-center">
                No Record Found
              </td>
            </tr>
          ) : (
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default React.memo(WorkloadTable);
