import React, { useState } from "react";
import { Row, Col, Button, Form, Navbar } from "react-bootstrap";
import "./home.scss";
import UserTable from "../../components/Home/UserTable";
import NavBar from "../../components/MainNavbar";
import InviteUserModal from "../../modals/InviteUserModal";
import PendingInviteModal from "../../modals/PendingInviteModal";
import { useSelector } from "react-redux";

function Home() {
  const {
    data: rolesData,
    success: rolesSuccess,
    error: rolesError,
  } = useSelector(({ admin }) => admin.roles);
  const roleOptions = rolesData?.roles || [];
  const [showModal, setShowModal] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [filterUser, setFilterUser] = useState("");

  const handleSearchUser = (event) => {
    const { name, value } = event.target;
    setSearchUser(value);
  };

  const handleFilterUser = (event) => {
    const { name, value } = event.target;
    setFilterUser(value);
  };

  const openInviteModal = () => {
    setShowModal(true);
  };
  const [showPendingModal, setShowPendingModal] = useState(false);
  const openPendingModal = () => {
    setShowPendingModal(true);
  };
  const handleClose = () => setShowModal(false);
  const handlePendingClose = () => setShowPendingModal(false);

  return (
    <div className="page-content">
      <NavBar />
      <Row className="search-row">
        <Col sm={8} className="d-flex">
          <Navbar.Brand>Users</Navbar.Brand>
          <Form.Control
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
            value={searchUser}
            onChange={handleSearchUser}
          />
          <Button variant="secondary" className="me-5 search-items">
            Search
          </Button>
          <Form.Select
            aria-label="Default select example"
            className="me-3 search-items"
            onChange={handleFilterUser}
          >
            <option value="">All Users</option>
            {roleOptions.map((option) => (
              <option key={option.value} value={option.label}>
                {option.label}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col sm={4} className="d-flex col-invite">
          <Button variant="link" onClick={openPendingModal} className="me-2">
            Pending Invites
          </Button>
          <Button
            variant="secondary"
            onClick={openInviteModal}
            className="me-2"
          >
            Invite User
          </Button>
        </Col>
      </Row>

      <UserTable filterTable={filterUser} searchTable={searchUser} />
      <InviteUserModal showModal={showModal} handleClose={handleClose} />
      <PendingInviteModal
        showModal={showPendingModal}
        handleClose={handlePendingClose}
      />
    </div>
  );
}

export default Home;
