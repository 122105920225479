import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

import CSRFToken from "./../utils/csrfToken";

const BaseModal = ({
  onSubmit,
  onConfirm,
  onClose,
  title,
  content,
  formWrapper,
  formLoading,
  submitButtonText,
  confirmButtonText,
  closeButtonText,
  showSubmitButton = true,
  showConfirmButton = true,
  showFooter = true,
  headerProps = {},
  actionButtons=false,
  ...props
}) => {
  const submitButton = showSubmitButton && (
    <Button disabled={formLoading} type="submit" className="ms-0 btn-unset-height">
      {submitButtonText || "Submit"}
    </Button>
  );

  const confirmButton = showConfirmButton && (
    <Button
      onClick={onConfirm}
      disabled={formLoading}
      type="submit"
      className="ms-0 btn-unset-height"
    >
      {confirmButtonText || "Confirm"}
    </Button>
  );

  const closeButton = (
    <Button
      onClick={onClose}
      disabled={formLoading}
      variant="outline-primary"
      className="me-0 ms-auto btn-unset-height"
    >
      {closeButtonText || "Cancel"}
    </Button>
  );

  return (
    <Modal
      {...props}
      onHide={onClose}
      contentClassName="pp-3 rounded-4  shadow-sm"
      centered
      scrollable={true}
    >
      <Modal.Header className="mb-2 justify-content-center" {...headerProps}>
        {title && (
          <Modal.Title className="text-capitalize">{title}</Modal.Title>
        )}
      </Modal.Header>

      <Modal.Body className="pt-3">
        {formWrapper ? (
          <>
            <form onSubmit={onSubmit}>
              <CSRFToken />
           
              {content}
             
         

              <Modal.Footer className="p-0 pt-3 justify-content-between">
                {formLoading ? (
                  <Spinner variant="primary" />
                ) : (
                  <>
                    {submitButton}
                    {closeButton}
                  </>
                )}
              </Modal.Footer>
            </form>
          </>
        ) : (
          <>
            {content}
            {(showFooter||actionButtons) && (
              <Modal.Footer className="p-0 pt-3 justify-content-between">
                {actionButtons?actionButtons:<>{confirmButton} {closeButton}</>}
                
              </Modal.Footer>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default BaseModal;
