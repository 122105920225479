import { createSlice } from "@reduxjs/toolkit";
import { BaseServiceReducer } from "utils/slices";

const block = {
  loading: false,
  error: "",
  success: false,
};

const initialState = {
  cleaners: { ...block, data: [] },
  schoolCleaners: {...block, data: []},
  districtCleaners: {...block, data: []},
  addCleaner: {...block},
  addExistingCleaner: {...block},
  deleteCleaner: {...block},
  updateCleaner: {...block},
};

const sliceName = 'cleaner';
const serviceReducer = new BaseServiceReducer(sliceName, initialState)
const cleanerSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...serviceReducer,
    fetchCleanersSuccess: ({cleaners}, action) => {
      cleaners.data = action.payload;
      cleaners.loading = false;
      cleaners.success = true;
      cleaners.error = "";
    },
    fetchCleanersFailure: ({cleaners}, action) => {
      cleaners.loading = false;
      cleaners.success = false;
      cleaners.error = action.payload;
    },
    addCleanerSuccess: ({cleaners, districtCleaners, addCleaner}, action) => {
      cleaners.data = [
        ...cleaners.data,
        {...action.payload},
      ]
      addCleaner.success = true;
      addCleaner.loading = false;
    },
    addSchoolCleanerSuccess: ({schoolCleaners, addExistingCleaner}, action) => {
        schoolCleaners.data = [
        ...schoolCleaners.data,
        {...action.payload},
      ]
      addExistingCleaner.success = true;
      addExistingCleaner.loading = false;
      
    },
    addDistrictCleanerSuccess: ({districtCleaners, addCleaner}, action) => {
        districtCleaners.data = [
        ...districtCleaners.data,
        {...action.payload},
      ]
      addCleaner.success = true;
      addCleaner.loading = false;
    },
    fetchSchoolCleanersSuccess: ({schoolCleaners}, action) => {
      schoolCleaners.data = action.payload;
      schoolCleaners.success = true;
      schoolCleaners.loading = false;
    },
    fetchDistrictCleanersSuccess: ({districtCleaners}, action) => {
      districtCleaners.data = action.payload;
      districtCleaners.success = true;
      districtCleaners.loading = false;
    },
    deleteDistrictCleanerSuccess: ({districtCleaners, deleteCleaner}, {payload: cleanerId}) => {
      districtCleaners.data = districtCleaners.data.filter(({id}) => cleanerId != id)
      deleteCleaner.success = true;
      deleteCleaner.loading = false;
    },
    deleteSchoolCleanerSuccess: ({schoolCleaners}, {payload: cleanerId}) => {
      schoolCleaners.data = schoolCleaners.data.filter(({id}) => cleanerId != id)
    },
    deleteCleanerSuccess: ({deleteCleaner, cleaners}, {payload: cleanerId}) => {
      cleaners.data = cleaners.data.filter(({id}) => cleanerId !== id)
      deleteCleaner.success = true;
      deleteCleaner.loading = false;
    },
    updateCleanerSuccess: ({updateCleaner, cleaners}, {payload: updated}) => {
      cleaners.data = cleaners.data.map((cleaner) => {
        return cleaner.id === updated.id ? updated : cleaner;
      });
      updateCleaner.success = true;
      updateCleaner.loading = false;
    },
    updateDistrictCleanerSuccess: ({updateCleaner, districtCleaners}, {payload: updated}) => {
      districtCleaners.data = districtCleaners.data.map((cleaner) => {
        return cleaner.id === updated.id ? updated : cleaner;
      });
      updateCleaner.success = true;
      updateCleaner.loading = false;
    },
  },
});

export const {
  startBlockFlags,
  resetBlockFlags,
  setBlockLoadingFlag,
  fetchCleanersSuccess,
  fetchCleanersFailure,
  addCleanerSuccess,
  fetchSchoolCleanersSuccess,
  setBlockFailure,
  resetBlock,
  addSchoolCleanerSuccess,
  deleteSchoolCleanerSuccess,
  deleteCleanerSuccess,
  updateCleanerSuccess,
  fetchDistrictCleanersSuccess,
  deleteDistrictCleanerSuccess,
  addDistrictCleanerSuccess,
  updateDistrictCleanerSuccess,
} = cleanerSlice.actions;
export default cleanerSlice.reducer;
