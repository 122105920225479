import React from "react";
import { useSelector } from "react-redux";
import SectionsCard from "./SectionCard";
import plusIcon from "../../Dashboard/assets/plus-icon.svg";
import deleteIcon from "../../Dashboard/assets/delete-icon.svg";
import editIcon from "../../Dashboard/assets/edit-icon.svg";

const SectionListing = (props) => {
  const {
    showIcon,
    setEditSectionModal,
    onOpenDeleteModal,
    onOpenModal,
    sections,
    handleClick,
    district,
    school,
    selectedSection,
    setSelectedSection,
    hoveredSectionId,
    setHoveredSectionId,
    setOpenSelectedSection,
  } = props;

  const {
    sections: { loading: sectionsLoading},
  } = useSelector(({ section }) => section);

  return (
    <div>
      <div className="section-header mb-4">
        <h5>Sections</h5>
        <div className="d-flex justify-content-between">
          {showIcon && (
            <>
              <button
                className="btn btn-outline-secondary d-flex align-items-center shadow-none section-card-edit-btn"
                onClick={() => setEditSectionModal(true)}
              >
                <img src={editIcon} alt="" className="image-responsive" />
              </button>
              <button
                className="mx-3 btn btn-outline-secondary d-flex align-items-center shadow-none section-card-delete-btn"
                onClick={onOpenDeleteModal}
              >
                <img src={deleteIcon} alt="" className="image-responsive" />
              </button>{" "}
            </>
          )}
          <button
            className="btn btn-outline-secondary d-flex align-items-center text-uppercase shadow-none"
            onClick={onOpenModal}
          >
            <img src={plusIcon} alt="" className="mr5 image-responsive" />
            <span>Section</span>
          </button>
        </div>
      </div>
      <div className="border-section" />
      <div className="section-list-holder-without-overflow">
        {sections.length == 0 && !sectionsLoading && (
          <p className="d-flex p-2 align-items-center text-center justify-content-center">No sections to show.</p>
        )}
        {sections.map((item) => (
          <SectionsCard
            key={`schoolSection${item.id}`}
            id={item.id}
            sectionName={item?.name}
            noOfRooms={item?.rooms}
            noOfPeople={item.people_count}
            handleClick={() => handleClick(item)}
            district={district}
            school={school}
            sectionDetails={item}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            hoveredSectionId={hoveredSectionId}
            setHoveredSectionId={setHoveredSectionId}
          />
        ))}
      </div>
    </div>
  );
};
export default SectionListing;
