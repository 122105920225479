import React, { useEffect, useState} from "react";
import { propTypes } from "react-bootstrap/esm/Image";

const AppContainer = ({
  children,
  props,
  }) => {
  const [containerHeight, setContainerHeight ] = useState(0);

  useEffect(() => {
    function onResize() {
      //We could have used 100vh with css, but that's not friendly for mobile devices
      setContainerHeight(window.innerHeight);
    }
    window.requestAnimationFrame(() => {
        onResize();
    })
    window.addEventListener('resize', onResize, false);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [])

  return (
    <div {...props} className="app-container" style={{height: containerHeight > 0 ? `${containerHeight}px` : '100vh'}}>
        {children}
    </div>
  );
};

export default AppContainer;

