import React from 'react';

import useWindowSize from '../../../utils/useWindowSize';

export default function Navbar({
  isSuperAdmin,
  isInspector,
  isDistrictAdmin,
  isBuildingAdmin,
  setSelectedTab,
  selectedTab,
}) {
  const { width: screenWidth } = useWindowSize();

  const handleTabClick = (type) => () => {
    if (screenWidth <= 768) {
      const toggleButton = document.querySelector(
        'button.navbar-toggler.toggle-btn',
      );
      if (toggleButton) {
        toggleButton.click();
      }
    }
    setSelectedTab(type);
    var event = new CustomEvent('onTabChange', {tabName: type});
    window.document.dispatchEvent(event);
  };

  return (
    <nav className="navbar navbar-light overview-navbar" data-sidebar-layout-height-item>
      <div className="container-fluid navbar-container">
        {screenWidth <= 1024 && (
          <div className="navbar-header">{selectedTab}</div>
        )}
        <button
          className="navbar-toggler toggle-btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <div className="nav nav-tabs w-100 nav-tabs-wrapper">
          {(isSuperAdmin || isDistrictAdmin || isInspector) && (
              <button
                className={`nav-link ${selectedTab === 'inspections' ? 'active' : ''}`}
                onClick={handleTabClick('inspections')}
                id="nav-inspections-tab"
                data-bs-toggle="tab"
                data-bs-target="#inspections"
                type="button"
                role="tab"
                aria-current="page"
                aria-controls="inspections"
                aria-selected="true"
              >
                Inspections
              </button>
            )}
            {(isSuperAdmin ||
              isDistrictAdmin ||
              isInspector) && (
                <button
                  className={`nav-link ${selectedTab === 'editor' ? 'active' : ''}`}
                  aria-current="page"
                  onClick={handleTabClick('editor')}
                  id="nav-editor-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#editor"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="false"
                >
                  Editor 
                </button>
              )}

            {(isSuperAdmin || isDistrictAdmin || isInspector) && (
              <button
                className={`nav-link ${selectedTab === 'map' ? 'active' : ''}`}
                onClick={handleTabClick('map')}
                id="nav-map-tab"
                data-bs-toggle="tab"
                data-bs-target="#map"
                type="button"
                role="tab"
                aria-controls="map"
                aria-selected="false"
              >
                Map
              </button>
            )}
            {/*{(isSuperAdmin || isDistrictAdmin || isBuildingAdmin) && (
              <button
                className="nav-link"
                onClick={handleTabClick('reports')}
                id="nav-reports-tab"
                data-bs-toggle="tab"
                data-bs-target="#reports"
                aria-controls="reports"
                type="button"
                role="tab"
                aria-selected="false"
              >
                Reports
              </button>
            )} */}

              {/*
              {(isSuperAdmin || isDistrictAdmin || isBuildingAdmin) && (
                <button
                  className="nav-link"
                  onClick={handleTabClick('people')}
                  id="nav-people-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#People"
                  type="button"
                  role="tab"
                  aria-controls="People"
                  aria-selected="false"
                >
                  People
                </button>
              )}
            */}
            {/*{(isSuperAdmin || isInspector || isDistrictAdmin || isBuildingAdmin) && (*/}
            {/*  <button*/}
            {/*    className="nav-link"*/}
            {/*    id="nav-profile-tab"*/}
            {/*    data-bs-toggle="tab"*/}
            {/*    data-bs-target="#Budgeting"*/}
            {/*    type="button"*/}
            {/*    role="tab"*/}
            {/*    aria-controls="Budgeting"*/}
            {/*    aria-selected="false"*/}
            {/*  >*/}
            {/*    Budgeting*/}
            {/*  </button>*/}
            {/*)}*/}

            {/*{isSuperAdmin && (*/}
            {/*  <button*/}
            {/*    className="nav-link"*/}
            {/*    id="nav-profile-tab"*/}
            {/*    data-bs-toggle="tab"*/}
            {/*    data-bs-target="#products_needed"*/}
            {/*    type="button"*/}
            {/*    role="tab"*/}
            {/*    aria-controls="products_needed"*/}
            {/*    aria-selected="false"*/}
            {/*  >*/}
            {/*    products needed*/}
            {/*  </button>*/}
            {/*)}*/}

            {/*{isSuperAdmin && (*/}
            {/*  <button*/}
            {/*    className="nav-link"*/}
            {/*    id="nav-profile-tab"*/}
            {/*    data-bs-toggle="tab"*/}
            {/*    data-bs-target="#savings"*/}
            {/*    type="button"*/}
            {/*    role="tab"*/}
            {/*    aria-controls="savings"*/}
            {/*    aria-selected="false"*/}
            {/*  >*/}
            {/*    savings*/}
            {/*  </button>*/}
            {/*)}*/}

            {/*{isSuperAdmin && (*/}
            {/*  <button*/}
            {/*    className="nav-link"*/}
            {/*    id="nav-profile-tab"*/}
            {/*    data-bs-toggle="tab"*/}
            {/*    data-bs-target="#cleaning_methods"*/}
            {/*    type="button"*/}
            {/*    role="tab"*/}
            {/*    aria-controls="nav-profile-tab"*/}
            {/*    aria-selected="false"*/}
            {/*  >*/}
            {/*    cleaning methods*/}
            {/*  </button>*/}
            {/*)}*/}

            {/*{isDistrictAdmin && (*/}
            {/*  <button*/}
            {/*    className="nav-link"*/}
            {/*    id="nav-profile-tab"*/}
            {/*    data-bs-toggle="tab"*/}
            {/*    data-bs-target="#cleaning_methods"*/}
            {/*    type="button"*/}
            {/*    role="tab"*/}
            {/*    aria-controls="nav-profile-tab"*/}
            {/*    aria-selected="false"*/}
            {/*  >*/}
            {/*    recommendations*/}
            {/*  </button>*/}
            {/*)}*/}

            {/* {(isSuperAdmin || isBuildingAdmin || isDistrictAdmin) && (
              <button
                className="nav-link"
                onClick={handleTabClick('sds')}
                id="nav-sds-tab"
                data-bs-toggle="tab"
                data-bs-target="#sds"
                type="button"
                role="tab"
                aria-controls="nav-profile-tab"
                aria-selected="false"
              >
                Sds
              </button>
            )} */}
          </div>
        </div>
      </div>
    </nav>
  );
}
