import "react-responsive-modal/styles.css";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "./OverviewComponents/DeleteModal";

import "./OverviewComponents/Styles.scss";
import { deleteDistrict } from "../../services/District";

import CardContainer from 'components/CardContainer';
import ActionDropdown from 'components/ActionDropdown';
import moment from "moment";

const District = ({
  district,
  onEditDistrict,
  handleDistrictClick,
}) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const {
    deleteDistrict: {loading: deleteDistrictLoading}
  } = useSelector(({ district }) => district);

  const toggleModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen((prev) => !prev)
  };

  const deleteDistrictHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation()
     dispatch(deleteDistrict(district.id,toggleModal));
  };
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onEditDistrict(district);
  };

  return (
    <>
        <CardContainer imageSource={district.logo} onClick={handleDistrictClick} cardAttrs={{title: "View District details"}}>
          <div className="align-items-center qck-card-footer d-flex flex-wrap w-100 py-2 px-2 position-relative" >
              <b className="mb-0 w-100 text-truncate align-items-center d-inline-block pe-4"> {district?.name?.length > 19
      ? district.name.substring(0, 16) + '...'
      : district.name} <span className="ps-1"> {district?.maxBuildingsAllowed}</span>
              <span className="ps-1"> {district?.subscriptionEndDate?moment(district?.subscriptionEndDate).format('MM/DD/YYYY'):''}</span>
              </b>
              <div className="position-absolute top-0 end-0">
              <ActionDropdown handlers={{
                'Edit Details': handleClick,
                'Delete District': toggleModal,
                }} />
            </div>
          </div>
      </CardContainer>

        { open && (
          <DeleteModal
            loading={deleteDistrictLoading}
            open={open}
            onCloseModal={toggleModal}
            message="district"
            onDelete={deleteDistrictHandler}
          />
        )}
      </>
  );
};

export default District;
