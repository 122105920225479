import React from "react";
import "./toggleSwitch.scss";

const ToggleSwitch = ({ name = "switch", enabled, handleToggle }) => {
  return (
    <div className="container">
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          name={name}
          id={name}
          checked={enabled}
          onChange={handleToggle}
        />
        <label className="label" htmlFor={name}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
