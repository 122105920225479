import { Axios } from "../../api/axios";

import { getHeader, toastMsg } from "../../utils/utility";
import { getSimplifiedError } from "../../utils/error";
import { logout } from "services/auth";
import {
  fetchAdminsFailure,
  fetchAdminsSuccess,
  fetchPendingInvitesFailure,
  fetchPendingInvitesSuccess,
  fetchRolesSuccess,
  fetchUsersFailure,
  fetchUsersSuccess,
  getDistrictEmployeesFailure,
  getDistrictEmployeesSuccess,
  setBlockLoading,
  updateUsersFailure,
  updateUsersSuccess,
  updateUserPasswordSuccess,
  updateUserPasswordFaillure,
} from "../../features/admin/adminSlice";
import { fetchReportFailure } from "../../features/school/schoolSlice";
import { getUserDetails } from "services/auth";

export const getDistricEmployees = (id) => async (dispatch) => {
  try {
    const response = await Axios.get(
      `/api/v1/admin-users/?district=${id}`,
      getHeader()
    );
    dispatch(getDistrictEmployeesSuccess(response));
  } catch (error) {
    dispatch(getDistrictEmployeesFailure(getSimplifiedError(error)));
  }
};

export const fetchUsers = () => async (dispatch) => {
  try {
    const response = await Axios.get(`/api/v1/users/`, getHeader());
    dispatch(fetchUsersSuccess(response));
  } catch (error) {
    dispatch(fetchUsersFailure(error.response.data.message));
  }
};
export const fetchAdmins = () => async (dispatch) => {
  try {
    const response = await Axios.get(`/api/v1/admin-users/`, getHeader());
    dispatch(fetchAdminsSuccess(response));
  } catch (error) {
    dispatch(fetchAdminsFailure(getSimplifiedError(error)));
  }
};

export const fetchRoles = () => async (dispatch) => {
  try {
    const response = await Axios.get(`/api/v1/auth/role/list/`, getHeader());
    dispatch(fetchRolesSuccess(response));
  } catch (error) {
    dispatch(fetchReportFailure(error.response.data.message));
  }
};

export const fetchPendingInvites = () => async (dispatch) => {
  try {
    const response = await Axios.get(
      `/api/v1/users/pending/invites/`,
      getHeader()
    );
    dispatch(fetchPendingInvitesSuccess(response));
  } catch (error) {
    dispatch(fetchPendingInvitesFailure(error.response.data.message));
  }
};

export const updateProfile = (id, formData,cb) => async (dispatch) => {
  try {
    dispatch(setBlockLoading({ blockType: "updateUser" }));
    const response = await Axios.patch(
      `/api/v1/users/profile/${id}/`,
      formData
    );
    dispatch(updateUsersSuccess(response));
    if(cb)
    cb();
    dispatch(getUserDetails());

  } catch (error) {
    dispatch(updateUsersFailure(error.response.data.message));
  }
};

export const updateUser = (id, formData) => async (dispatch) => {
  dispatch(setBlockLoading({ blockType: "updateUser" }));
  try {
    let payload = {
      role: formData.role,
      district: [parseInt(formData.district_id)],
      schools: formData.school_ids.map((id) => parseInt(id)),
    };
    if (payload.role === "simple_user") {
      // pop district and school
      delete payload.district;
      delete payload.schools;
    } else if (payload.role === "district_admin") {
      // pop schools
      delete payload.schools;
    }
    const response = await Axios.put(`/api/v1/users/${id}/`, payload);
    dispatch(updateUsersSuccess(response));
  } catch (error) {
    console.log("erros: ", error);
    dispatch(updateUsersFailure(error.response.data.message));
  }
};
export const deleteUser = (id,cb,setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const response = await Axios.delete(`/api/v1/users/${id}/`);
    dispatch(updateUsersSuccess(response));
    toastMsg("User Deleted successfully!")
    cb();
  } catch (error) {
    dispatch(updateUsersFailure(error.response.data.detail));
    toastMsg(`${error?.response?.data?.detail||'Something went wrong'}`)
  }
  finally{
    setLoading(false)
  }
};

export const updateUserPassword = (payload) => async (dispatch) => {
  try {
    dispatch(setBlockLoading({ blockType: "updateUserPassword" }));

    const response = await Axios.post(
      `/api/v1/auth/password/change/`,
     payload,
      getHeader()
    );
    dispatch(updateUserPasswordSuccess(response));
    dispatch(logout());
  } catch ({response}) {
    dispatch(updateUserPasswordFaillure(response?.data||"Some Thing Went Wrong"));
  }
};



