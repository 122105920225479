import React, { useMemo } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { Table, Button } from "react-bootstrap";
import { convertToHoursMinutesSeconds } from "utils/utility";
import Loader from "components/Loader";
import deleteIcon from "../Dashboard/assets/delete-icon.svg";
import editIcon from "../Dashboard/assets/edit-icon.svg";
import "./roomFeaturesTable.scss";
function RoomFeaturesTable({
  data,
  loading,
  removeFeatureHandler,
  editFeatureHandler,
}) {
  const columns = [
    {
      Header: "Feature Name",
      accessor: "name",
      filter: "text",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      filter: "text",
    },
    {
      Header: "Time In Minutes",
      accessor: "timeInMinutes",
      filter: "text",
    },

    {
      Header: "Workload",
      accessor: "workload",
      Cell: ({ row }) => convertToHoursMinutesSeconds((row.original.timeInSeconds||0)*(row.original.quantity)),
      filter: "text",
    },
  ];
  const memoizedColumns = useMemo(
    () => [
      ...columns,
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => (
          <>
          {row?.original?.isDefault===true?<>
          
            <span> Default Feature</span>
           </>:
             <span className="d-flex">
              <Button
                className="d-flex align-items-center  shadow-none"
                variant="outline-secondary"
                onClick={() => removeFeatureHandler(row.original)}
              >
                <img src={deleteIcon} alt="" className="image-responsive" />
              </Button>
              <Button
                className="d-flex align-items-center  shadow-none ms-1"
                variant="outline-success"
                onClick={() => editFeatureHandler(row.original)}
              >
                <img src={editIcon} alt="" className="image-responsive" />
              </Button>
            </span>}
         
          </>
        ),
      },
    ],
    [removeFeatureHandler,editFeatureHandler]
  );

  const memoizedData = useMemo(() => data, [data]);

  const {
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    prepareRow,
    state: { pageIndex },
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: {
        pageIndex: 0,
        sortBy: [{ id: "name", desc: false }],
        pageSize:5,
      },
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <div style={{ margin: 5, padding: 5 }}>
      <Table borderless hover className="roomFeaturesTable" responsive>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="py-10 px-10">
              {headerGroup.headers.map((column) => (
                <th>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading ? (
            <tr className="py-10 px-10">
              <td rowSpan={4} colSpan={4}>
                <Loader />
              </td>
            </tr>
          ) : memoizedData.length === 0 ? (
            <tr className="py-10 px-10">
              <td colSpan={4} className="text-center">
                No Record Found
              </td>
            </tr>
          ) : (
            page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="py-10 px-10">
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      {!loading && pageOptions.length > 1 && (
        <div className="pagination">
          <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>
          <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>
          <span className="ms-2">
            <span className="me-1"> Page </span>
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
        </div>
      )}
    </div>
  );
}

export default React.memo(RoomFeaturesTable);
