import React, {useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Section from "../Section";
import Inspection from "./Inspection";
import StartInspection from "./Inspector/StartInspection";
import Navbar from "./Navbar";
import SelectedBuilding from "./SelectedBuilding";
import Map from "./Map";
import DeleteModal from "./DeleteModal";
import { deleteInspection } from "services/inspection";
import { resetBlock, selectInspection } from "features/inspection/inspectionSlice";
import { toast } from "react-toastify";
import { getDateTime, getInspectionOfDate, getModelDate } from "utils/inspectionsGroup";



const OverviewMainContent = (props) => {
  const {
    isInspector,
    school,
    isSuperAdmin,
    isDistrictAdmin,
    isBuildingAdmin,
    setSelectedTab,
    selectedTab,
    districtId,
    sections,
    setShowDetails,
    showDetails,
    selectedSection,
    setSelectedSection,
  } = props;
  const dispatch = useDispatch();
  const [groups, setGroups] = useState([])
  const [groupInspections, setGroupInspections] = useState([])
  const [todaysGroup, setTodaysGroup] = useState();
  const [openSelectedSection, setOpenSelectedSection] = useState(false);
  const [openDeleteInspection, setOpenDeleteInspection] = useState(false);

  const {
    inspection: { isInspecting, selectedInspection },
    createInspection: { success: createInspectionSuccess },
    updateInspection: { success: updateInspectionSuccess },
    selectedInspectionsGroup: { data: selectedGroup },
    inspections: {
      data: inspectionsData,
      success: inspectionsSuccess,
    },
  } = useSelector(({ inspection }) => inspection);
  const {
    deleteInspection: { loading: deleteInspectionLoading, success: deleteInspectionSuccess, error: deleteInspectionError },
  } = useSelector(({ inspection }) => inspection);

  const handleDeleteInspection = async (e) => {
    if (!selectedInspection) {
      return;
    }
    await dispatch(deleteInspection({inspectionId: selectedInspection.id}));
  }
  const onOpenDeleteInspection = (inspection) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenDeleteInspection(true);
    dispatch(selectInspection(inspection))
  };
  const onCloseDeleteInspection = () => {
    setOpenDeleteInspection(false)
  }
  useEffect(() => {
    return () => {
      dispatch(resetBlock({blockType: 'deleteInspection'}));
    }
  }, [])
  useEffect(() => {
    if (inspectionsSuccess) {
      let groupDate = getModelDate(selectedGroup);
      let inspections = getInspectionOfDate(inspectionsData, groupDate);
      setGroupInspections([...inspections]); 
    }
  }, [selectedGroup, 
    inspectionsSuccess, 
    deleteInspectionSuccess, 
    createInspectionSuccess, 
    updateInspectionSuccess])
  useEffect(() => {
    if (deleteInspectionSuccess) {
      toast.success('Inspection deleted succesfully', {
         toastId: "delete-inspection-toast"
      });
      onCloseDeleteInspection();
    } else if (deleteInspectionError) {
      toast.error('Inspection could not be deleted');
    }
  }, [deleteInspectionSuccess, deleteInspectionError])

  //Get if there is an inspection group for today.
  useEffect(() => {
    for (const group of groups) {
      if (getModelDate(group) === getDateTime()) {
        setTodaysGroup(group);
        break;
      }
    }
  }, [groups]);

  return (
    <>
      {openDeleteInspection && (
        <DeleteModal
          loading={deleteInspectionLoading}
          open={openDeleteInspection}
          onCloseModal={onCloseDeleteInspection}
          onDelete={handleDeleteInspection}
          title={'Inspection'}
          message=" this inspection"
        />
      )}
      <div className="main-content w-100 h-100">
        <div className="main-content-tabs-section">
          <SelectedBuilding
            isInspector={isInspector}
            isBuildingAdmin={isBuildingAdmin}
            isSuperAdmin={isSuperAdmin}
            isDistrictAdmin={isDistrictAdmin}
            image={school?.image}
            name={school?.name}
            grade={school?.grade}
            school={school}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            openSelectedSection={openSelectedSection}
            setOpenSelectedSection={setOpenSelectedSection}
          />
          {!isInspecting && (
            <Navbar
              isSuperAdmin={isSuperAdmin}
              isInspector={isInspector}
              isDistrictAdmin={isDistrictAdmin}
              isBuildingAdmin={isBuildingAdmin}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          )}
          {isInspecting &&
          (isSuperAdmin ||
            isBuildingAdmin ||
            isDistrictAdmin ||
            isInspector) ? (
            <StartInspection
              school={school}
              isSuperAdmin={isSuperAdmin}
              isInspector={isInspector}
              isDistrictAdmin={isDistrictAdmin}
              onOpenDeleteInspection={onOpenDeleteInspection}
              groupInspections={groupInspections}
              todaysGroup={todaysGroup}
            />
          ) : (
            <div
              className="tab-content tab-content-wrapper"
              id="nav-tabContent"
              role="tabpanel"
            >
              <div
                className={`tab-pane fade ${
                  selectedTab === "inspections" ? "show active" : ""
                }`}
                id="inspections"
                role="tabpanel"
                aria-labelledby="inspections"
              >
                <Inspection
                  school={school}
                  schoolId={school?.id}
                  selectedTab={selectedTab}
                  isSuperAdmin={isSuperAdmin}
                  isInspector={isInspector}
                  isDistrictAdmin={isDistrictAdmin}
                  onOpenDeleteInspection={onOpenDeleteInspection}
                  groupInspections={groupInspections}
                  setGroupInspections={setGroupInspections}
                  setGroups={setGroups}
                  groups={groups}
                  todaysGroup={todaysGroup}
                />
              </div>
              <div
                className={`tab-pane fade ${
                  selectedTab === "editor" ? "show active" : ""
                }`}
                id="editor"
                role="tabpanel"
                aria-labelledby="editor"
              >
                <Section
                  isInspector={isInspector}
                  isSuperAdmin={isSuperAdmin}
                  isDistrictAdmin={isDistrictAdmin}
                  selectedTab={selectedTab}
                  district={districtId}
                  school={school}
                  sections={sections}
                  setShowDetails={setShowDetails}
                  showDetails={showDetails}
                  openSelectedSection={openSelectedSection}
                  setOpenSelectedSection={setOpenSelectedSection}
                  selectedSection={selectedSection}
                  setSelectedSection={setSelectedSection}
                />
              </div>
              <div
                className={`tab-pane fade ${
                  selectedTab === "map" ? "show active" : ""
                }`}
                id="map"
                role="tabpanel"
                aria-labelledby="map"
              >
                <Map districtId={districtId} schoolId={school?.id} />
              </div>

              {/* <Reports
                  roomSpecs={roomSpecs}
                  report={report}
                  selectedTab={selectedTab}
                  heading={heading}
                  setHeading={setHeading}
                  colName={colName}
                  setColName={setColName}
                  count={count}
                  setCount={setCount}
                  unit={unit}
                  setUnit={setUnit}
                  selectedReportId={selectedReportId}
                  setSelectedReportId={setSelectedReportId}
                /> */}

              {/*
                <People
                  school={school}
                  isDistrictAdmin={isDistrictAdmin}
                  isSuperAdmin={isSuperAdmin}
                  isBuildingAdmin={isBuildingAdmin}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                />
                */}
              {/*
                <Budgeting />
                <ProductNeeded />
                <Savings />
                <CleaningMethods />
                */}
              {/* <SDS
                  schoolId={school?.id}
                  selectedSds={selectedSds}
                  setSelectedSds={setSelectedSds}
                  deleteSdsModal={deleteSdsModal}
                  setDeleteSdsModal={setDeleteSdsModal}
                  handleSdsClick={handleSdsClick}
                  toggleDeleteDialog={toggleDeleteDialog}
                /> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default OverviewMainContent;
