import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetBlockSectionSuccess } from "../../../features/section/sectionSlice";
import { assignCleaner } from "../../../services/section";

import BaseModal from "components/BaseModal";

const AssignCleaner = ({ open, onClose, sectionId, cleanerId, sectionName = "" }) => {
  const dispatch = useDispatch();

  const {
    assignCleaner: { loading, success, error },
  } = useSelector(({ section }) => section);

  useEffect(() => {
    if (success) {
      dispatch(resetBlockSectionSuccess({ blockType: "assignCleaner" }));
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      assignCleaner({
        sectionId,
        data: {
          cleaner: cleanerId,
        },
      })
    );
  };

  return (
    <BaseModal
      onSubmit={handleSubmit}
      onClose={onClose}
      show={open}
      title={`Assign cleaner to “${sectionName}”`}
      submitButtonText="Confirm"
      formWrapper={true}
      formLoading={loading}
      content={
        error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )
      }
    />
  );
};

export default AssignCleaner;
