import React from "react";

const InnerLoading = ({show}) => {
  return show && (
    <div className="d-flex justify-content-center position-absolute w-100 vh-100 top-0 start-0" style={{background: "rgba(255, 255, 255, 0.7)", zIndex: '1080', minHeight: '200px'}}>
      <div className="spinner-border custom-loader mt-5" role="status" />
    </div>
  );
};

export default InnerLoading;
