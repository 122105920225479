import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import "./inspectReport.scss";
import ReportsTable from "components/inspectReport/InspectReportTable";
import NavBar from "../../components/MainNavbar";
import { REPORT_OPTIONS } from "constants/constants";
import { useSelector, useDispatch } from "react-redux";
import { fetchRoomTypes } from "services/inspection";

function Reports() {
  const user = useSelector(({ auth }) => auth.data?.user);
  const role = user?.role;
  const isDistrictAdmin = role === "district_admin";
  const [search, setSearch] = useState("");
  const rType = isDistrictAdmin ? "schools" : "districts";
  const [reportType, setReportType] = useState(rType);
  const [roomType, setRoomType] = useState("");

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const reportTypeChange = (event) => {
    const { value } = event.target;
    setReportType(value);
  };
  const roomTypeChange = (event) => {
    const { value } = event.target;
    setRoomType(value);
  };
  const dispatch = useDispatch();
  const {
    roomTypes: {
      success: roomTypesSuccess,
      loading: roomTypesLoading,
      data: roomTypes = [],
    },
  } = useSelector(({ inspection }) => inspection);

  useEffect(() => {
    if (!roomTypesSuccess) {
      dispatch(fetchRoomTypes());
    }
  }, [roomTypesSuccess, dispatch]);
  const filteredOptions = REPORT_OPTIONS.filter(
    (option) => !(isDistrictAdmin && option.value === "districts")
  );
  return (
    <Container fluid>
      <NavBar />
      <Row className="inspection-report-search">
        <Col sm={8} xl={4} md={6} xs={12} className="d-flex">
          <Form.Select
            aria-label="Default select example"
            className="me-3 search-items"
            onChange={reportTypeChange}
          >
            {filteredOptions.map((option) => (
              <option key={option?.value} value={option?.value}>
                {option?.label}
              </option>
            ))}
          </Form.Select>
          <Form.Control
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
            value={search}
            onChange={handleSearch}
          />
          <Button variant="secondary" className="me-5 search-items">
            Search
          </Button>
        </Col>
        <Col sm={4} md={3} xl={2} xs={6} className="d-flex col-invite">
          <Form.Select
            aria-label="Default select example"
            className="me-3 search-items"
            onChange={roomTypeChange}
            disabled={roomTypesLoading}
            value={roomType}
          >
            <option value="">Overall</option>
            {roomTypes?.map((item) => (
              <option key={item?.id} id={item?.id} value={item?.id}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="table-row">
        <ReportsTable
          filterTable={""}
          searchTable={search}
          roomType={roomType}
          reportType={reportType}
        />
      </Row>
    </Container>
  );
}

export default Reports;
