import { act } from "react-dom/test-utils";

export const block = {
    loading: false,
    error: "",
    success: false,
};
export function BaseServiceReducer(sliceName, initialState) {
    const resetBlockFlags = (state, action) => {
        const blockType = action.payload.blockType;
        if (state[blockType] === undefined) {
            console.log(`${sliceName}Slice:resetBlockFlags: blockType '${blockType}' not found.`)
            return;
        }
        state[blockType] = { ...state[blockType], ...block }
    }
    const setBlockLoadingFlag = (state, action) => {
        const blockType = action.payload.blockType;
        if (state[blockType] === undefined) {
            console.log(`${sliceName}Slice:setBlockLoadingFlag: blockType '${blockType}' not found.`)
            return;
        }
        state[blockType] = { ...state[blockType], loading: action.payload.loading }
    }
    return {
        setBlockLoadingFlag,
        resetBlockFlags,
        resetBlock(state, action) {
            const blockType = action.payload.blockType;
            if (state[blockType] === undefined) {
                console.log(`${sliceName}Slice:resetBlock: blockType '${blockType}' not found.`)
                return;
            }
            state[blockType] = { ...initialState[blockType] }
        },
        setBlockSuccessFlags(state, action) {
            const blockType = action.payload.blockType;
            if (state[blockType] === undefined) {
                console.log(`${sliceName}Slice:setBlockSuccessFlags: blockType '${blockType}' not found.`)
                return;
            }
            state[blockType] = { ...state[blockType], success: true, loading: false }
        },
        setBlockSuccess(state, action) {
            const blockType = action.payload.blockType;
            if (state[blockType] === undefined) {
                console.log(`${sliceName}Slice:setBlockSuccess: blockType '${blockType}' not found.`)
                return;
            }
            state[blockType] = { ...state[blockType], success: true, loading: false, data: action.payload.data }
        },
        setBlockFailure(state, action) {
            const blockType = action.payload.blockType;
            if (state[blockType] === undefined) {
                console.log(`${sliceName}Slice:setBlockFailure: blockType '${blockType}' not found.`)
                return;
            }
            state[blockType] = { ...state[blockType], success: false, loading: false, error: action.payload.error }
        },
        //Reset Blocks and set it to load; 
        startBlockFlags(state, action) {
            resetBlockFlags(state, action);
            let newAction = {...action};
            newAction.payload.loading = true;
            setBlockLoadingFlag(state, newAction);
        }
    }
}
