import { Axios } from "../../api/axios";
import {
  resetBlockFlags,
  setBlockLoadingFlag,
  createDistrictFailure,
  createDistrictSuccess,
  deleteDistrictFailure,
  deleteDistrictSuccess,
  fetchDistrictFailure,
  fetchDistrictSuccess,
  generateCodeFailure,
  generateCodeSuccess,
  getDistrictPeopleFailure,
  getDistrictPeopleSuccess,
  updateDistrictFailure,
  updateDistrictSuccess,
} from "../../features/district/districtSlice";

import { getHeader, toastMsg } from "../../utils/utility";
import { getSimplifiedError } from "../../utils/error";
import { NOTIFICATION } from "constants/constants";

export const fetchDistricts = () => async (dispatch) => {
  dispatch(resetBlockFlags({ blockType: "districts" }));
  dispatch(setBlockLoadingFlag({ blockType: "districts", loading: true }));
  try {
    const response = await Axios.get(`/api/v1/district/`, getHeader());
    dispatch(fetchDistrictSuccess(response));
  } catch (error) {
    dispatch(fetchDistrictFailure(getSimplifiedError(error)));
  } finally {
    dispatch(setBlockLoadingFlag({ blockType: "districts", loading: false }));
  }
};
export const getDistrictPeople = (districtId) => async (dispatch) => {
  dispatch(resetBlockFlags({ blockType: "districtPeople" }));
  dispatch(setBlockLoadingFlag({ blockType: "districtPeople", loading: true }));
  try {
    const response = await Axios.get(
      `/api/v1/people-in-district/?district=${districtId}`,
      getHeader()
    );

    dispatch(getDistrictPeopleSuccess(response));
  } catch (error) {
    dispatch(getDistrictPeopleFailure(getSimplifiedError(error)));
  } finally {
    dispatch(
      setBlockLoadingFlag({ blockType: "districtPeople", loading: false })
    );
  }
};
export const generateCode = () => async (dispatch) => {
  try {
    const response = await Axios.get(
      `/api/v1/district/district-code/`,
      getHeader()
    );

    dispatch(generateCodeSuccess(response));
  } catch (error) {
    dispatch(generateCodeFailure(getSimplifiedError(error)));
  }
};
export const deleteDistrict = (id, cb) => async (dispatch) => {
  let success = false;
  try {
    dispatch(resetBlockFlags({ blockType: "deleteDistrict" }));
    dispatch(
      setBlockLoadingFlag({ blockType: "deleteDistrict", loading: true })
    );
    await Axios.delete(`/api/v1/district/${id}/`, getHeader());
    success = true;
    toastMsg("District deleted successfully");

    dispatch(deleteDistrictSuccess(id));
    cb();
  } catch (error) {
    if (!success) {
      const response = error.response;
      toastMsg(
        response?.data?.detail || "District could not be deleted",
        NOTIFICATION.error
      );
      dispatch(
        deleteDistrictFailure(getSimplifiedError(response?.data?.detail))
      );
    }
  }
};
export const createDistrict =
  (
    {
      name,
      logo,
      admins,
      maxBuildingsAllowed,
      subscriptionStartDate,
      subscriptionEndDate,
    },
    cb
  ) =>
  async (dispatch) => {
    dispatch(resetBlockFlags({ blockType: "createDistrict" }));
    dispatch(
      setBlockLoadingFlag({ blockType: "createDistrict", loading: true })
    );
    try {
      let payload = {
        name,
        logo,
        admins,
        maxBuildingsAllowed,
        subscriptionStartDate,
        subscriptionEndDate,
      };

      if (subscriptionStartDate !== null && subscriptionEndDate !== null) {
        payload = {
          ...payload,
          subscriptionStartDate: new Date(subscriptionStartDate)
            .toISOString()
            .split("T")[0],
          subscriptionEndDate: new Date(subscriptionEndDate)
            .toISOString()
            .split("T")[0],
        };
      }
      const response = await Axios.post(`/api/v1/district/`, payload, getHeader());
      cb();
      toastMsg("District created successfully");
      dispatch(createDistrictSuccess(response));
    } catch (error) {
      dispatch(createDistrictFailure(getSimplifiedError(error)));
      toastMsg("District could be created", NOTIFICATION.error);
    }
  };

export const updateDistrict =
  ({ id, name, logo, admins, maxBuildingsAllowed,subscriptionStartDate,subscriptionEndDate }, cb) =>
  async (dispatch) => {
    dispatch(resetBlockFlags({ blockType: "updateDistrict" }));
    dispatch(
      setBlockLoadingFlag({ blockType: "updateDistrict", loading: true })
    );
    try {
      let data = {
        name,
        maxBuildingsAllowed,
        subscriptionStartDate,
        subscriptionEndDate,
      };

      if (logo && logo.startsWith("data")) {
        data.logo = logo;
      }

      let updateData = admins && !logo && !name ? { admins } : data;
      const response = await Axios.patch(
        `/api/v1/district/${id}/`,
        updateData,
        getHeader()
      );
      cb();
      dispatch(updateDistrictSuccess(response));
      toastMsg("District updated successfully");
    } catch (error) {
      dispatch(updateDistrictFailure(getSimplifiedError(error)));
      toastMsg("District could not be updated", NOTIFICATION.error);
    } finally {
      dispatch(
        setBlockLoadingFlag({ blockType: "updateDistrict", loading: false })
      );
    }
  };
