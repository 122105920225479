import React, {
  useState,
  useEffect,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addCleaner,
  deleteCleaner,
  updateCleaner,
  fetchDistrictCleaners,
} from "services/cleaner";
import { resetBlockFlags } from "features/cleaner/cleanerSlice";
import { toast } from "react-toastify";
import SingleValueForm from "components/SingleValueForm";
import { textIncludes } from "utils/utility";


export default function AddCleanerDropdown({ school, defaultValue, onChange }) {
  const dispatch = useDispatch();
  const $selectCleanerContainer = useRef();
  const $selectCleanerDropdown = useRef();
  const $input = useRef();
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [cleanersSelection, setCleanersSelection] = useState([]);
  const [newCleanerMode, setNewCleanerMode] = useState(false);
  const [cleanerId, setCleanerId] = useState(defaultValue);
  const [cleanerName, setCleanerName] = useState("");
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [filteredSelection, setFilteredSelection] = useState([]);
  const [cleanerOption, setCleanerOption] = useState();
  const [openDeleteCleaner, setOpenDeleteCleaner] = useState(false);
  const [openEditCleaner, setOpenEditCleaner] = useState(false);
  const [selectedCleaner, setSelectedCleaner] = useState();
  const [editedCleanerName, setEditedCleanerName] = useState("");
  const [showCleaners, setShowCleaners] = useState(false);

  const {
    districtCleaners: {
      loading: cleanersLoading,
      success: cleanersSuccess,
      error: fetchError,
      data: cleaners,
    },
    deleteCleaner: {
      loading: deleteCleanerLoading,
      success: deleteCleanerSuccess,
      error: deleteCleanerError,
    },
    updateCleaner: {
      loading: updateCleanerLoading,
      success: updateCleanerSuccess,
      error: updateCleanerError,
    },
  } = useSelector(({ cleaner }) => cleaner);

  const {
    //schools: {success: schoolsSuccess, data: schools, loading: schoolsLoading},
    addCleaner: { loading: addLoading, success: addSuccess, error: addError },
  } = useSelector(({ school }) => school);

  useEffect(() => {
    if (!cleanersSuccess) {
      dispatch(fetchDistrictCleaners({districtId: school.district}));
    }
    return () => {
      dispatch(resetBlockFlags({ blockType: "addCleaner" }));
      dispatch(resetBlockFlags({ blockType: "deleteCleaner" }));
      dispatch(resetBlockFlags({ blockType: "updateCleaner" }));
    };
  }, []);

  useEffect(() => {
    let options = [];
    if (
      cleanersSuccess ||
      addSuccess ||
      deleteCleanerSuccess ||
      updateCleanerSuccess
    ) {
      options = cleaners.map(({ id, name }) => ({ label: name, value: id }));
      setCleanersSelection(options);
    }
    if (cleanerId && options.length > 0) {
      const cleaner = options.find(({ value }) => value == cleanerId);
      if (cleaner) {
        setCleanerOption(cleaner);
        setCleanerName(cleaner.label);
      } else {
        setCleanerOption(null);
        setCleanerName("");
      }
    } else {
      setCleanerOption(null);
    }
  }, [
    cleanersSuccess,
    addSuccess,
    cleanerId,
    deleteCleanerSuccess,
    updateCleanerSuccess,
  ]);

  useEffect(() => {
    if (search.length > 0) {
      setFilteredSelection(
        cleanersSelection.filter(({ label }) => {
          return textIncludes(label, search);
        })
      );
    } else if (search.length === 0) {
      setFilteredSelection(cleanersSelection);
    }
  }, [search, cleanersSelection]);
  useEffect(() => {
    if (!isSearching) {
      setSearch("");
    }
  }, [isSearching])

  useEffect(() => {
    if (newCleanerMode) {
      const input = document.querySelector(".add-cleaner-input");
      if (input) {
        input.focus();
        input.select();
      }
    }
  }, [newCleanerMode]);

  useEffect(() => {
    if (deleteCleanerError) {
      toast.error("Cleaner could not be deleted");
    }
    if (deleteCleanerSuccess) {
      let wasSelectedDeleted = !cleaners.some(({id}) => {
        return id === cleanerId;
      })
      if (wasSelectedDeleted) {
        let label = "";
        let value = null;
        setCleanerId(value);
        setCleanerName(label);
        onChange({label, value });
      }
    }
  }, [deleteCleanerError, deleteCleanerSuccess]);
  useEffect(() => {
    if (updateCleanerSuccess) {
      toast.success("Cleaner updated successfully");
    } else if (updateCleanerError) {
      toast.error("Cleaner could not be updated");
    }
  }, [updateCleanerSuccess, updateCleanerError]);

  function onNewCleanerClick(e) {
    e.preventDefault();
    e.stopPropagation();

    setCleanerId(null);
    setCleanerName("");
    setNewCleanerMode(true);
  }

  const onCreateCleanerClick = async () => {
    var response = await dispatch(addCleaner({ name: cleanerName, district: school.district }));
    setCleanerId(response.id);
    onChange({ label: response.name, value: response.id });
    setNewCleanerMode(false);
  };

  const onInputChange = (e) => {
    const value = e.target.value;
    if (!isSearching) {
      setCleanerName(value);
    } else {
      setSearch(value);
    }
  };

  const onSelectChange = (option, e) => {
    setCleanerId(option.value);
    setCleanerName(option.label);
    setSearch("");
    setIsSearching(false);
    onChange(option);
  };
  const handleEditCleaner = async (cleanerName, e) => {
    if (!selectedCleaner) {
      return;
    }
    await dispatch(updateCleaner({ id: selectedCleaner, name: cleanerName }));
    onCloseEditCleaner(e);
  };
  const handleDeleteCleaner = (cleanerId) => async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!selectedCleaner) {
      return;
    }
    await dispatch(deleteCleaner({ cleanerId: selectedCleaner }));
    onCloseDeleteCleaner(e);
  };
  const onCloseCleanerAction = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenDeleteCleaner(false);
    setOpenEditCleaner(false);
    setSelectedCleaner();
    setEditedCleanerName("");
  };
  const onOpenDeleteCleaner = (cleaner) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenDeleteCleaner(true);
    setSelectedCleaner(cleaner);
  };
  const onOpenEditCleaner = (cleanerName, cleanerId) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    setEditedCleanerName(cleanerName);
    setSelectedCleaner(cleanerId);
    setOpenEditCleaner(true);
  };
  const onCloseDeleteCleaner = (e) => {
    onCloseCleanerAction(e);
  };
  const onCloseEditCleaner = (e) => {
    onCloseCleanerAction(e);
  };
  useEffect(() => {
    const onClick = function (e) {
      if ($selectCleanerContainer.current) {
        let isChild = $selectCleanerContainer.current.contains(e.target);
        let $editCleanerNameModal = document.getElementById(
          "editCleanerNameModal"
        );
        if (!isChild && $editCleanerNameModal) {
          isChild = $editCleanerNameModal.contains(e.target);
        }
        if (isChild) {
          return;
        }
        setShowCleaners(false);
        setIsSearching(false);
      }
    };
    const onResize = function () {
      if (!$selectCleanerDropdown.current) {
        return;
      }
      let $dropdown = $selectCleanerDropdown.current;
      let $items = $dropdown.querySelectorAll('li');
      let height = 0;
      let containerBox = $dropdown.getBoundingClientRect();

      for (let $item of $items) {
        let box = $item.getBoundingClientRect();
        height += box.height
      }
      let bottom = Math.round(containerBox.top + height);
      let wHeight = Math.round(window.innerHeight);
      let offset = 10;
      if (bottom > wHeight) {
        let diff = bottom - wHeight;
        setDropdownHeight(Math.abs(height - diff - offset));
      } else {
        setDropdownHeight('auto')
      }
    };
    document.addEventListener("click", onClick, true);
    window.addEventListener("resize", onResize);
    window.requestAnimationFrame(() => {
      onResize();
    });
    return () => {
      document.removeEventListener("click", onClick);
      window.removeEventListener("resize", onResize);
    };
  }, []);

  let optionsList = isSearching ? filteredSelection : cleanersSelection;
  return (
    <>
      {newCleanerMode && (
        <>
          <div className="d-flex">
            <div className="w-100 position-relative">
              <input
                type="text"
                className="add-cleaner-input form-control mb-0 h-100"
                name="name"
                required
                placeholder="Enter Cleaner Name"
                onChange={onInputChange}
              />
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setNewCleanerMode(false);
                  setCleanerName("");
                }}
                className="position-absolute top-50 mb-0 end-0 me-2 h5 translate-middle"
              >
                <i className="fa-solid fa-xmark text-primary"></i>
              </a>
            </div>
            <button
              disabled={addLoading || !cleanerName}
              type="button"
              className="btn btn-primary ms-2"
              onClick={onCreateCleanerClick}
            >
              Add
            </button>
            <span className="text-danger"></span>
          </div>
        </>
      )}
      {!newCleanerMode && (
        <div className="position-relative" ref={$selectCleanerContainer}>
          <div className="form-floating position-relative dropdown-select mb-3 go-bottom">
            <input
              ref={$input}
              readOnly={!isSearching}
              type="text"
              className="form-control cursor-pointer"
              name={`cleaner`}
              value={isSearching ? search : cleanerName}
              onChange={onInputChange}
              placeholder={"Select Cleaner..."}
              onFocus={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowCleaners(true);
                window.requestAnimationFrame(() => {
                  window.dispatchEvent(new Event("resize"));
                });
              }}
              style={{ paddingRight: "56px" }}
            />
            <label htmlFor={"cleaner"}>Select Cleaner</label>
            {!isSearching && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsSearching(true);
                  setSearch("");
                  $input.current.focus();
                  $input.current.select();
                }}
                title="Search cleaner"
                className="position-absolute end-0 top-50 px-2 translate-middle-y"
                style={{ marginRight: "32px" }}
              >
                <i className="fa-solid fa-magnifying-glass"></i>
              </a>
            )}
            {isSearching && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsSearching(false);
                  setSearch("");
                }}
                title="Cancel Search"
                className="position-absolute end-0 top-50 px-2 translate-middle-y"
                style={{ marginRight: "32px" }}
              >
                <i className="fa-solid fa-x"></i>
              </a>
            )}
          </div>
          <div
            ref={$selectCleanerDropdown}
            className={`dropdown-menu w-100 overflow-auto ${
              showCleaners ? "show" : ""
            }`}
            style={{ height: dropdownHeight > 0 ? dropdownHeight : "auto" }}
          >
            <li className="dropdown-item w-100 px-0">
              <a
                href="#"
                className="d-block sc-add-new-cleaner text-primary  pb-2 px-3 border-bottom"
                onClick={onNewCleanerClick}
              >
                <b>New Cleaner +</b>
              </a>
            </li>
            {isSearching && optionsList.length === 0 && (
              <li className="dropdown-item w-100 px-3">
                <b>No Cleaners Found</b>
              </li>
            )}
            {!isSearching && optionsList.length === 0 && (
              <li className="dropdown-item w-100 px-3">
                <b>No cleaners to select</b>
              </li>
            )}
            {optionsList.map((option) => {
              const { label, value } = option;
              return (
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSelectChange(option, e);
                    setShowCleaners(false);
                  }}
                  key={`addCleanerOption${option.value}`}
                  className={`dropdown-item w-100 px-0 py-2 border-bottom ${
                    value === cleanerId ? "active" : ""
                  }`}
                >
                  <div className="position-relative px-3 d-flex align-items-center">
                    <div className="text-truncate" style={{flex: '1 1', width: '200px'}}>{option.label}</div>
                    <div className="ms-auto d-flex align-items-center ms-auto" style={{flex: '0 0 auto'}}>
                      {!(openDeleteCleaner && selectedCleaner === value) && (
                        <>
                          <button
                            className="btn border-0 px-2 d-flex align-items-center shadow-none"
                            onClick={onOpenEditCleaner(label, value)}
                            title="Edit this cleaner"
                          >
                            <i className="fa-sharp fa-solid fa-pen"></i>
                          </button>
                          <button
                            className="btn px-2 border-0 d-flex align-items-center shadow-none"
                            onClick={onOpenDeleteCleaner(value)}
                            title="Delete this cleaner"
                          >
                            <i className="fa-sharp fa-solid fa-trash"></i>
                          </button>
                        </>
                      )}
                      {openDeleteCleaner && selectedCleaner === value && (
                        <>
                          <button
                            className="btn bg-transparent text-black  me-2 border-0 px-2 d-flex align-items-center shadow-none"
                            onClick={onCloseDeleteCleaner}
                            title="Cancel deletion"
                            disabled={deleteCleanerLoading}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn text-white me-2  bg-danger py-0 border-0 d-flex align-items-center shadow-none"
                            onClick={handleDeleteCleaner(value)}
                            title="Confirm the deletion of this cleaner. This action cannot be undone"
                            disabled={deleteCleanerLoading}
                          >
                            Confirm
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
          </div>
        </div>
      )}
      <SingleValueForm
        id="editCleanerNameModal"
        open={openEditCleaner}
        defaultValue={editedCleanerName}
        onSubmit={handleEditCleaner}
        title={"Edit Cleaner"}
        onCloseModal={onCloseEditCleaner}
        placeholder={"Name"}
        label={"Cleaner Name"}
        loading={updateCleanerLoading}
        valueName={"name"}
      />
    </>
  );
}
