export const alertConstants = {
  SUCCESS: 'success',
  ERROR: 'invalid response',
  CLEAR: 'response is clear',
};

export const roomType = [
  {
    id: 1,
    name: 'Small classroom',
  },
  {
    id: 2,
    name: 'Medium classroom',
  },
  {
    id: 3,
    name: 'Large classroom',
  },
  {
    id: 4,
    name: 'Single bathroom',
  },
  {
    id: 5,
    name: 'Double bathroom',
  },
  {
    id: 6,
    name: 'Triple bathroom',
  },
  {
    id: 7,
    name: 'Quad bathroom',
  },
  {
    id: 8,
    name: 'Hallway',
  },
  {
    id: 9,
    name: 'Cafeteria',
  },
  {
    id: 10,
    name: 'Kitchen',
  },
  {
    id: 11,
    name: 'Office',
  },
  {
    id: 12,
    name: 'Gym',
  },
  {
    id: 13,
    name: 'Auditorium',
  },
  {
    id: 14,
    name: 'Locker room',
  },
];

export const roomTypes = [
  {
    roomId: 1,
    roomType: 'classrooms',
    questions: {
      id: [
        {
          tool: {
            name: 'floor_mopping',
            options: [
              { name: 'String mop', id: 4 },
              { name: 'Flat mop', id: 3 },
            ],
            toolSize: false,
          },
        },
        {
          tool: {
            name: 'cleaning_table',
            options: [
              { name: 'Spray and wipe', id: 8 },
              { name: 'Pretreat', id: 9 },
              { name: 'Pads', id: 10 },
            ],
            toolSize: false,
          },
        },
        {
          tool: {
            name: 'misting_table',
            options: [
              { name: 'Electostatic sprayer', id: 11 },
              { name: 'No', id: 13 },
            ],
            toolSize: false,
          },
        },
      ],
    },
  },
  {
    roomId: 4,
    roomType: 'bathrooms',
    questions: {
      id: [
        {
          tool: {
            name: 'floor_mopping',
            options: [
              { name: 'String mop', id: 4 },
              { name: 'Microfiber', id: 23 },
            ],
            toolSize: false,
          },
        },
      ],
    },
  },
  {
    roomId: 8,
    roomType: 'hallways',
    questions: {
      id: [
        {
          tool: {
            name: 'dust_cleaning',
            options: [
              { name: 'Ride on sweeper', id: 2 },
              { name: 'Dust mop', id: 1, isToolSize: false },
            ],
            toolSize: 'dust_cleaning_size',
          },
        },
        {
          tool: {
            name: 'floor_mopping',
            options: [
              { name: 'Walk behind scrubber', id: 12 },
              { name: 'Ride on scrubber', id: 5 },
              { name: 'String mop', id: 4 },
              { name: 'Flat mop', id: 3 },
            ],
            toolSize: 'floor_mopping_size',
          },
        },
        {
          tool: {
            name: 'floor_burnishing',
            options: [
              { name: 'ride on burnisher', id: 6 },
              { name: 'electric burnisher', id: 7 },
            ],
            toolSize: 'floor_burnishing_size',
          },
        },
      ],
    },
  },
  {
    roomId: 9,
    roomType: 'cafeterias',
    questions: {
      id: [
        {
          tool: {
            name: 'dust_cleaning',
            options: [
              { name: 'Ride on sweeper', id: 2 },
              { name: 'Dust mop', id: 1 },
            ],
            toolSize: 'dust_cleaning_size',
          },
        },
        {
          tool: {
            name: 'floor_mopping',
            options: [
              { name: 'Walk behind scrubber', id: 12 },
              { name: 'Ride on scrubber', id: 5 },
              { name: 'String mop', id: 4 },
              { name: 'Flat mop', id: 3 },
            ],
            toolSize: 'floor_mopping_size',
          },
        },
        {
          tool: {
            name: 'floor_burnishing',
            options: [
              { name: 'ride on burnisher', id: 6 },
              { name: 'electric burnisher', id: 7 },
            ],
            toolSize: 'floor_burnishing_size',
          },
        },
        {
          tool: {
            name: 'cleaning_table',
            options: [
              { name: 'Spray and wipe', id: 8 },
              { name: 'Pretreat', id: 9 },
              { name: 'Pads', id: 10 },
            ],
            toolSize: false,
          },
        },
        {
          tool: {
            name: 'misting_table',
            options: [
              { name: 'Electrostatic sprayer', id: 11 },
              { name: 'No', id: 13 },
            ],
            toolSize: false,
          },
        },
      ],
    },
  },
  {
    roomId: 10,
    roomType: 'kitchens',
    questions: {
      id: [
        {
          tool: {
            name: 'dust_cleaning',
            options: [
              { name: 'Ride on sweeper', id: 2 },
              { name: 'Dust mop', id: 1 },
            ],
            toolSize: 'dust_cleaning_size',
          },
        },
        {
          tool: {
            name: 'floor_mopping',
            options: [
              { name: 'Walk behind scrubber', id: 12 },
              { name: 'Ride on scrubber', id: 5 },
              { name: 'String mop', id: 4 },
              { name: 'Flat mop', id: 3 },
            ],
            toolSize: 'floor_mopping_size',
          },
        },
        {
          tool: {
            name: 'floor_burnishing',
            options: [
              { name: 'ride on burnisher', id: 6 },
              { name: 'electric burnisher', id: 7 },
            ],
            toolSize: 'floor_burnishing_size',
          },
        },
        {
          tool: {
            name: 'cleaning_table',
            options: [
              { name: 'Spray and wipe', id: 8 },
              { name: 'Pretreat', id: 9 },
              { name: 'Pads', id: 10 },
            ],
            toolSize: false,
          },
        },
        {
          tool: {
            name: 'misting_table',
            options: [
              { name: 'Electrostatic sprayer', id: 11 },
              { name: 'No', id: 13 },
            ],
            toolSize: false,
          },
        },
      ],
    },
  },
  {
    roomId: 11,
    roomType: 'offices',
    questions: {
      id: [
        {
          tool: {
            name: 'dust_cleaning',
            options: [
              { name: 'Ride on sweeper', id: 2 },
              { name: 'Dust mop', id: 1 },
            ],
            toolSize: 'dust_cleaning_size',
          },
        },
        {
          tool: {
            name: 'floor_mopping',
            options: [
              { name: 'Walk behind scrubber', id: 12 },
              { name: 'Ride on scrubber', id: 5 },
              { name: 'String mop', id: 4 },
              { name: 'Flat mop', id: 3 },
            ],
            toolSize: 'floor_mopping_size',
          },
        },
        {
          tool: {
            name: 'floor_burnishing',
            options: [
              { name: 'ride on burnisher', id: 6 },
              { name: 'electric burnisher', id: 7 },
            ],
            toolSize: 'floor_burnishing_size',
          },
        },
        {
          tool: {
            name: 'cleaning_table',
            options: [
              { name: 'Spray and wipe', id: 8 },
              { name: 'Pretreat', id: 9 },
              { name: 'Pads', id: 10 },
            ],
            toolSize: false,
          },
        },
        {
          tool: {
            name: 'misting_table',
            options: [
              { name: 'Electrostatic sprayer', id: 11 },
              { name: 'No', id: 13 },
            ],
            toolSize: false,
          },
        },
      ],
    },
  },
  {
    roomId: 12,
    roomType: 'gyms',
    questions: {
      id: [
        {
          tool: {
            name: 'dust_cleaning',
            options: [
              { name: 'Ride on sweeper', id: 2 },
              { name: 'Dust mop', id: 1 },
            ],
            toolSize: 'dust_cleaning_size',
          },
        },
        {
          tool: {
            name: 'floor_mopping',
            options: [
              { name: 'Walk behind scrubber', id: 12 },
              { name: 'Ride on scrubber', id: 5 },
              { name: 'String mop', id: 4 },
              { name: 'Flat mop', id: 3 },
            ],
            toolSize: 'floor_mopping_size',
          },
        },
      ],
    },
  },
  {
    roomId: 13,
    roomType: 'auditoriums',
    questions: {
      id: [
        {
          tool: {
            name: 'dust_cleaning',
            options: [
              { name: 'Ride on sweeper', id: 2 },
              { name: 'Dust mop', id: 1 },
            ],
            toolSize: 'dust_cleaning_size',
          },
        },
        {
          tool: {
            name: 'floor_mopping',
            options: [
              { name: 'Walk behind scrubber', id: 12 },
              { name: 'Ride on scrubber', id: 5 },
              { name: 'String mop', id: 4 },
              { name: 'Flat mop', id: 3 },
            ],
            toolSize: 'floor_mopping_size',
          },
        },
        {
          tool: {
            name: 'floor_burnishing',
            options: [
              { name: 'ride on burnisher', id: 6 },
              { name: 'electric burnisher', id: 7 },
            ],
            toolSize: 'floor_burnishing_size',
          },
        },
      ],
    },
  },
  {
    roomId: 14,
    roomType: 'locker rooms',
    questions: {
      id: [
        {
          tool: {
            name: 'dust_cleaning',
            options: [
              { name: 'Ride on sweeper', id: 2 },
              { name: 'Dust mop', id: 1 },
            ],
            toolSize: 'dust_cleaning_size',
          },
        },
        {
          tool: {
            name: 'floor_mopping',
            options: [
              { name: 'Walk behind scrubber', id: 12 },
              { name: 'Ride on scrubber', id: 5 },
              { name: 'String mop', id: 4 },
              { name: 'Flat mop', id: 3 },
            ],
            toolSize: 'floor_mopping_size',
          },
        },
        {
          tool: {
            name: 'floor_burnishing',
            options: [
              { name: 'ride on burnisher', id: 6 },
              { name: 'electric burnisher', id: 7 },
            ],
            toolSize: 'floor_burnishing_size',
          },
        },
      ],
    },
  },
];


export const INSPECTION_ITEM_PASS_OR_FAIL_TYPE = "pass_or_fail"
export const INSPECTION_ITEM_PASS_OR_FAIL_TYPE_NAME = "Pass or Fail"
export const INSPECTION_ITEM_NUMERICAL_TYPE = "numeric"
export const INSPECTION_ITEM_NUMERICAL_TYPE_NAME = "Numeric"