import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { Table, Button } from "react-bootstrap";
import sortArrow from "../Dashboard/assets/sort-arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../services/admin";
import AssignUserModal from "../../modals/AssignUserModal";
import DeleteUserModal from "../../modals/DeleteModal";

function UserTable({ filterTable, searchTable }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const handleClose = () => setShowModal(false);
  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const { users, updateUser } = useSelector(({ admin }) => admin);
  const { data: rawData, success, error } = users;
  const { success: updateSuccess, error: updateError } = updateUser;
  useEffect(() => {
    dispatch(fetchUsers());
  }, [success, updateSuccess]);

  let userData = rawData?.users || [];

  useEffect(() => {
    if (searchTable !== "") {
      setGlobalFilter(searchTable.toLowerCase());
    } else if (filterTable !== "") {
      setGlobalFilter(filterTable.toLowerCase());
    } else {
      setGlobalFilter("");
    }
  }, [filterTable, searchTable]);

  const columns = [
    {
      Header: "User Information",
      accessor: (row) => `${row.name}\n${row.email}`,
      Cell: ({ value }) =>
        value.split("\n").map((line, index) => <div key={index}>{line}</div>),
      filter: "text",
    },
    {
      Header: "Date Invited",
      accessor: (row) => `${row.acceptedInvite},${row.dateJoined}`,
      Cell: ({ value }) =>
        value.split(",")[0] === "true" ? value.split(",")[1] : "-------",
    },
    {
      Header: "Role",
      accessor: "role",
      Cell: ({ value }) => value.label,
      filter: "text",
    },
    {
      Header: "District",
      accessor: "districts",
      Cell: ({ value }) => value.map((district) => district.name).join(", "),
      defaultCanSort: true,
      filter: "text",
    },
    {
      Header: "School",
      accessor: "schools",
      Cell: ({ value }) => value.map((school) => school.name).join(", "),
      filter: "text",
    },
  ];
  const memoizedColumns = useMemo(
    () => [
      ...columns,
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => (
          <>
            <Button variant="info" size="sm" onClick={() => handleEdit(row)}>
              Edit
            </Button>{" "}
            <Button
              variant="danger"
              size="sm"
              onClick={() => handleDelete(row)}
            >
              Delete
            </Button>
          </>
        ),
      },
    ],
    []
  );

  const memoizedData = useMemo(() => userData, [success, updateSuccess]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    prepareRow,
    state: { pageIndex, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: {
        pageIndex: 0,
        // sortBy: [{ id: "name", desc: true }],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleEdit = (row) => {
    setRowData(row.original);
    setShowModal(true);
  };

  const handleDelete = (row) => {
    setRowData(row.original);
    setShowDeleteModal(true);
  };

  return (
    <div style={{ margin: 5, padding: 5 }}>
      <Table striped bordered hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {" "}
                    <img src={sortArrow} />
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="pagination">
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <span className="ms-2">
            <span className="me-1"> Page </span>
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
      </div>
      <AssignUserModal
        handleClose={handleClose}
        showModal={showModal}
        rowData={rowData}
      />
      <DeleteUserModal
        handleClose={handleDeleteModalClose}
        showModal={showDeleteModal}
        rowData={rowData}
      />
    </div>
  );
}

export default React.memo(UserTable);
