import AppContainer from "components/AppContainer";
import MainNavbar from "components/MainNavbar";
import { Outlet } from "react-router-dom";

function DefaultRoomsPage() {
  return (
    <AppContainer className="page-content">
      <MainNavbar />

      <Outlet />
    </AppContainer>

  );
}

export default DefaultRoomsPage;