import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { resetUserStatus } from "../features/admin/adminSlice";
import { deleteUser } from "../services/admin";
import "./InviteUserModal.scss";

import BaseModal from "components/BaseModal";


const DeleteUserModal = ({ showModal, handleClose, rowData }) => {
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const successCB=()=>{
      handleClose();
    }
    dispatch(deleteUser(rowData.id,successCB,setLoading));
  };
  useEffect(() => {
    dispatch(resetUserStatus());
  }, [showModal, rowData]);

  return (
    <BaseModal
      onConfirm={handleSubmit}
      onClose={handleClose}
      show={showModal}
      title="Delete User"
      formLoading={loading}
      content={
        (
          <p>
            Are you sure you want to delete user
            <strong>
              {rowData?.name
                ? " " + rowData.name
                : " " + rowData?.firstName + " " + rowData?.lastName + ""}
            </strong>
            ? This action cannot be undone.
          </p>
        )
      }
    />
  );
};

export default DeleteUserModal;
