import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetBlockFlags } from "../../../features/section/sectionSlice";
import {
  createRoomFeature,
  editRoomFeature,
  removeRoomFeature,
  updateRoom,
} from "../../../services/section";
import AddCleanerDropdown from "components/Dashboard/OverviewComponents/Cleaner/AddCleanerDropdown";
import BaseModal from "components/BaseModal";
import { fetchRoomTypes } from "services/inspection";
import { addCleaner } from "services/cleaner";
import {
  Container,
  Col,
  Row,
  Button,
} from "react-bootstrap";
import { convertSecondsToMinutes, convertToHoursMinutesSeconds, sortByProperty } from "utils/utility";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import RoomFeaturesTable from "components/RoomFeatures/RoomFeaturesTable";
export default function RoomEditForm({
  open,
  onCloseModal,
  school,
  selectedRoom,
}) {
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState("editRoom");
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedFeature] = useState("Feature");
  const [confirmModalDetails, setConfirmModalDetails] = useState({
    title: "",
    message: "",
    id: "",
  });
  const [roomDetails, setRoomDetails] = useState({
    name: selectedRoom?.name || "",
    roomType: selectedRoom?.roomType || "",
    section: selectedRoom?.section || "",
    cleaner: selectedRoom?.cleaner || "",
  });
  const [roomFeatureDetail, setRoomFeatureDetail] = useState({
    name: "",
    quantity: "",
    timeInMinutes: "",
  });
  const {
    sections: { success: sectionsSuccess },
    editRoom: { loading, success, error },
    roomFeatureProcessing,
  } = useSelector(({ section }) => section);

  const {
    roomTypes: {
      success: roomTypesSuccess,
      loading: roomTypesLoading,
      data: roomTypes = [],
    },
  } = useSelector(({ inspection }) => inspection);

  const previousSection = useRef();
  useEffect(() => {
    previousSection.current = selectedRoom?.section || "";
  }, [selectedRoom?.section]);

  useEffect(() => {
    if (!roomTypesSuccess) {
      dispatch(fetchRoomTypes());
    }
  }, [dispatch,roomTypesSuccess]);

  useEffect(() => {
    if (success && sectionsSuccess) {
      setRoomDetails({
        name: "",
        roomType: "",
        section: "",
        cleaner: "",
      });
      previousSection.current = undefined;
      onCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, sectionsSuccess]);

  useEffect(() => {
    if (success || error) {
      dispatch(resetBlockFlags({ blockType: "editRoom" }));
    }
  }, [success, error,dispatch]);

  const submitHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const toggleEdit = () => setActionType("editRoom");
    try {
      if (actionType === "addFeature") {
    
          dispatch(
            createRoomFeature(
              { room: selectedRoom.id, ...roomFeatureDetail },
              toggleEdit
            )
          );
        
      } else if (actionType === "editFeature") {
       
          dispatch(editRoomFeature(roomFeatureDetail, toggleEdit));
        
      } else {
        await dispatch(
          updateRoom({
            id: selectedRoom.id,
            data: {
              name: roomDetails.name,
              roomType: +roomDetails.roomType,
              section: +roomDetails.section,
              cleaner: roomDetails.cleaner,
              //flushable: +roomDetails.flushable,
            },
            schoolId: school.id,
            fetchSectionsAfterUpdate:
              previousSection.current !== roomDetails.section,
          })
        );
        if (roomDetails.cleaner) {
          await dispatch(
            addCleaner({
              id: roomDetails.cleaner,
              school: school.id,
              district: school.district,
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = ({ target: { name, value } }) => {
    setRoomDetails({ ...roomDetails, [name]: value });
  };
  const handleFeatureChange = ({ target: { name, value } }) => {
    if(name==="timeInMinutes"){}
    setRoomFeatureDetail({ ...roomFeatureDetail, [name]: value });
  };
  const toggleConfirMModal = () => {
    setConfirmModal(!confirmModal);
  };
  const { name, roomType } = roomDetails;

  function handleCleanerSelect({ label, value }) {
    setRoomDetails({ ...roomDetails, cleaner: value });
  }
  const removeFeatureHandler = (feature) => {
    setConfirmModalDetails((prev) => ({
      ...prev,
      title: "Remove Room Feature",
      message: `Are You sure you want to remove this feature?`,
      id: feature.id,
    }));

    toggleConfirMModal();
  };
  const onConfirm = () => {
    try {
      const cb = () => {
        setConfirmModalDetails({ id: "", message: "", title: "" });
      };
     
        dispatch(
          removeRoomFeature(
            { id: confirmModalDetails.id, room: selectedRoom?.id },
            toggleConfirMModal,
            "room",
            cb
          )
        );
      
    } catch (error) {
      console.error(error);
    }
  };
  const editFeatureHandler = (feature) => {
    setRoomFeatureDetail(feature);
    setActionType("editFeature");
  };

  const totalWorkload = useMemo(() => {
    const features = selectedRoom?.roomFeatures || [];
    let totalSeconds = 0;
    features.forEach((feature) => {
      totalSeconds +=  ((feature.timeInSeconds || 0)*(feature.quantity||0));
    });
   
    return convertToHoursMinutesSeconds(totalSeconds);
  }, [selectedFeature, selectedRoom]);
  const data=   selectedRoom?.roomFeatures.map((item) => ({ ...item, timeInMinutes:convertSecondsToMinutes(item?.timeInSeconds||0) })) || [];
  return (
    <BaseModal
      onSubmit={submitHandler}
      onClose={
        actionType.includes("Feature")
          ? () => setActionType("editRoom")
          : onCloseModal
      }
      show={open}
      title={
        actionType === "addFeature"
          ? `Add ${selectedFeature}`
          : actionType === "editFeature"
          ? `Edit ${selectedFeature}`
          : "Edit Room Details"
      }
      submitButtonText={actionType.includes("add") ? "Add" : "Update"}
      formWrapper={true}
      formLoading={
        actionType.includes("Feature")
          ? roomFeatureProcessing 
          : loading
      }
      size="lg"
      content={
        <>
          <Container fluid>
            {actionType.includes("Feature") ? (
              <Row>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="name"
                      value={roomFeatureDetail?.name}
                      placeholder="Feature Name"
                      onChange={handleFeatureChange}
                    />
                    <label htmlFor="name">Feature Name</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      type="number"
                      className="form-control"
                      name="quantity"
                      value={roomFeatureDetail?.quantity}
                      placeholder="Quantity"
                      onChange={handleFeatureChange}
                      min={0}
                      required
                    />
                    <label htmlFor="quantity">Quantity</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      type="number"
                      className="form-control"
                      name="timeInMinutes"
                      value={roomFeatureDetail?.timeInMinutes}
                      placeholder="timeInMinutes"
                      onChange={handleFeatureChange}
                      required
                    />
                    <label htmlFor="quantity">Time In Minutes</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="workloadInMinutes"
                      value={convertToHoursMinutesSeconds((roomFeatureDetail?.quantity||0)*(roomFeatureDetail?.timeInMinutes * 60))}
                      placeholder="Workload"
                      disabled
                    />
                    <label htmlFor="workloadInMinutes">Workload</label>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="name"
                      value={name}
                      placeholder="Room Name"
                      onChange={handleChange}
                    />
                    <label htmlFor="name">Room Name</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <select
                      required
                      disabled={roomTypesLoading}
                      className="form-select"
                      name="roomType"
                      value={roomType}
                      onChange={handleChange}
                    >
                      <option value="">Select room type</option>
                      {sortByProperty(roomTypes,"name")?.map((item) => (
                        <option key={item.id} id={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="name">Room Type</label>
                  </div>
                </Col>
                <Col md={6}>
                  <AddCleanerDropdown
                    onChange={handleCleanerSelect}
                    school={school}
                    defaultValue={selectedRoom.cleaner}
                  />
                </Col>
                <Col md={6}>
                
                    <div className="form-floating mb-4 go-bottom">
                      <input
                        required
                        type="text"
                        className="form-control"
                        name="totalWorkload"
                        value={totalWorkload}
                        placeholder="Workload"
                        disabled
                      />
                      <label htmlFor="totalWorkload">Total Workload</label>
                    </div>
                  
                </Col>
                <Col
                  md={12}
                  className="d-flex flex-wrap align-items-center justify-content-between flex-grow-1"
                >
                  <h5> {selectedFeature}s </h5>
                  <Button
                    variant="light"
                    size="sm"
                    className="btn-unset-height"
                    onClick={() => {
                      setActionType("addFeature");
                      setRoomFeatureDetail({
                        name: "",
                        quantity: "",
                        timeInMinutes: "",
                      });
                    }}
                  >
                    Add {selectedFeature}
                  </Button>
                </Col>
                <Col md={12} className="mt-2">
                  <RoomFeaturesTable
                    data={data
                   }
                    editFeatureHandler={editFeatureHandler}
                    removeFeatureHandler={removeFeatureHandler}
                  />
                </Col>
              </Row>
            )}
          </Container>
          <ConfirmationModal
            show={confirmModal}
            onCancel={toggleConfirMModal}
            title={confirmModalDetails?.title}
            message={confirmModalDetails?.message}
            onConfirm={onConfirm}
            loading={roomFeatureProcessing}
          />
        </>
      }
    />
  );
}
