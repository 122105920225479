import { Axios } from "../../api/axios";
import { convertMinutesToSeconds, convertSecondsToMinutes, getHeader, toastMsg } from "../../utils/utility";
import { getSimplifiedError } from "../../utils/error";
import {
  resetBlockFlags,
  resetFlagsSectionSuccess,
  setBlockLoadingFlag,
  assignCleanerFailure,
  assignCleanerSuccess,
  createRoomFailure,
  createSectionFailure,
  createSectionSuccess,
  editSectionSuccess,
  editSectionFailure,
  resetSectionsBlock,
  editRoomFailure,
  editRoomSuccess,
  fetchRoomFailure,
  fetchRoomSuccess,
  setRoomsLoading,
  resetRoomsBlock,
  createRoomSuccess,
  fetchSectionFailure,
  fetchSectionSuccess,
  setSectionsLoading,
  getInspectionQuestionsFailure,
  getInspectionQuestionsSuccess,
  getRandomRoomsFailure,
  getRandomRoomsSuccess,
  getRoomFailure,
  getRoomSuccess,
  saveInspectionResultFailure,
  saveInspectionResultSuccess,
  deleteSectionSuccess,
  deleteSectionFailure,
  deleteRoomSuccess,
  deleteRoomFailure,
  updateRoomFeatureProcessing,
  updateRoomFeature,
  setDefaultRoomFeaturesLoading,
  fetchDefaultRoomFeaturesSuccess,
  updateDefaultRoomFeature,
  updateDefaultRoomFeatureProcessing,
  updateSpecificRoomFeature,
  updateSpecificRoomProcessing,
  updateSpecificRoom,
} from "../../features/section/sectionSlice";
import {
  fetchReportFailure,
  fetchReportSuccess,
} from "../../features/school/schoolSlice";

import { fetchRooms, fetchSchools } from "services/school";
import { fetchWorkLoad } from "services/workload/workloadService";
import { fetchCleaners } from "services/cleaner";
import { NOTIFICATION } from "constants/constants";

export const fetchSections = (schoolId) => async (dispatch) => {
  dispatch(resetSectionsBlock());
  dispatch(setSectionsLoading(true));
  try {
    const response = await Axios.get(
      `/api/v1/section/?school=${schoolId}`,
      getHeader()
    );
    dispatch(fetchSectionSuccess(response));
  } catch (error) {
    dispatch(fetchSectionFailure(getSimplifiedError(error)));
  } finally {
    dispatch(setSectionsLoading(false));
  }
};
export const fetchRoom = (id) => async (dispatch) => {
  dispatch(resetRoomsBlock());
  dispatch(setRoomsLoading(true));
  try {
    const response = await Axios.get(
      `/api/v1/room/?section=${id}`,
      getHeader()
    );
    dispatch(fetchRoomSuccess(response));
  } catch (error) {
    dispatch(fetchRoomFailure(getSimplifiedError(error)));
  } finally {
    dispatch(setRoomsLoading(false));
  }
};
export const fetchReports = (schoolId) => async (dispatch) => {
  try {
    const response = await Axios.get(
      `/api/v1/school/${schoolId}/report/`,
      getHeader()
    );

    dispatch(fetchReportSuccess(response));
  } catch (error) {
    dispatch(fetchReportFailure(getSimplifiedError(error)));
  }
};

export const updateRoom =
  ({ id, data, schoolId, fetchSectionsAfterUpdate, fetchWorkload = false,key="room" },cb) =>
  async (dispatch) => {
    try {
      let response
      if(key=="room"){
        dispatch(resetFlagsSectionSuccess({ blockType: "editRoom" }));
        dispatch(setBlockLoadingFlag({ blockType: "editRoom", loading: true }));
         response= await Axios.patch(`/api/v1/room/${id}/`, data);
      if (response && fetchWorkload === false) {
        dispatch(editRoomSuccess(response));
        dispatch(fetchRoom(data.section));
        if (fetchSectionsAfterUpdate) {
          dispatch(fetchSections(schoolId));
        }
        dispatch(fetchRooms({ schoolId }));
      }
      }
      else {
        dispatch(updateSpecificRoomProcessing(true));
        response= await Axios.patch(`/api/v1/room/${id}/`, data);
        dispatch(updateSpecificRoom(response))
        dispatch(updateSpecificRoomProcessing(false))
        toastMsg("Room Updated Successfully",NOTIFICATION.success)
        if(cb){
          cb();
        }
      }
 
      
      return response;
    } catch (error) {
      const errorMsg=getSimplifiedError(error);
      if(key=="room"){
        dispatch(editRoomFailure(errorMsg));
      }
      else {
        dispatch(updateSpecificRoomProcessing(false))
        toastMsg(errorMsg||"SomeThing went Wrong",NOTIFICATION.error)
      }
  
    }
  };

export const createSection =
  ({ name, school, district, contact_name, contact_email }) =>
  async (dispatch) => {
    dispatch(resetFlagsSectionSuccess({ blockType: "createSection" }));
    dispatch(
      setBlockLoadingFlag({ blockType: "createSection", loading: true })
    );
    try {
      const response = await Axios.post(
        `/api/v1/section/`,
        {
          name,
          school,
          ...(contact_name ? { contact_name } : {}),
          ...(contact_email ? { contact_email } : {}),
        },
        getHeader()
      );
      dispatch(createSectionSuccess(response));
      dispatch(fetchSections(school));
      dispatch(fetchReports(school));
      if (district) {
        dispatch(fetchSchools(district));
      }
    } catch (error) {
      dispatch(createSectionFailure(getSimplifiedError(error)));
    } finally {
      dispatch(
        setBlockLoadingFlag({ blockType: "createSection", loading: false })
      );
    }
  };

export const editSection =
  ({ id, school, name, contact_name, contact_email }) =>
  async (dispatch) => {
    dispatch(resetFlagsSectionSuccess({ blockType: "editSection" }));
    dispatch(setBlockLoadingFlag({ blockType: "editSection", loading: true }));
    try {
      const response = await Axios.patch(
        `/api/v1/section/${id}/`,
        {
          name,
          ...(contact_name ? { contact_name } : {}),
          ...(contact_email ? { contact_email } : {}),
        },
        getHeader()
      );
      dispatch(editSectionSuccess(response));
      dispatch(fetchSections(school));
    } catch (error) {
      dispatch(editSectionFailure(getSimplifiedError(error)));
    } finally {
      dispatch(
        setBlockLoadingFlag({ blockType: "editSection", loading: false })
      );
    }
  };

export const createRoom =
  ({ roomDetail, sectionDetails, school, district, roomFeatures = [] }) =>
  async (dispatch) => {
    dispatch(resetBlockFlags({ blockType: "createRoom" }));
    dispatch(setBlockLoadingFlag({ blockType: "createRoom", loading: true }));
    try {
      const response = await Axios.post(
        `/api/v1/room/`,
        roomDetail,
        getHeader()
      );
      const promises = roomFeatures.map(async (payload) => {
        const {timeInMinutes,...rest}=payload;
        const roomFeatureResponse = await Axios.post(
          `/api/v1/room-feature/`,
          {...rest,timeInSeconds:convertMinutesToSeconds(timeInMinutes), room: response?.id },
          getHeader()
        );
        return roomFeatureResponse;
      });
      const features = await Promise.all(promises);
      const data = {
        ...response,
        roomFeatures: [...response?.roomFeatures, ...features],
      };

      dispatch(fetchRoom(sectionDetails.id));
      dispatch(fetchSections(school));
      dispatch(fetchReports(school));
      dispatch(createRoomSuccess(data));

      if (district) {
        dispatch(fetchSchools(district));
      }
      dispatch(fetchRooms({ schoolId: school }));
    } catch (error) {
      dispatch(createRoomFailure(getSimplifiedError(error)));
    }
  };

export const deleteSection =
  ({ school, sectionId, district }) =>
  async (dispatch) => {
    dispatch(resetBlockFlags({ blockType: "deleteSection" }));
    dispatch(
      setBlockLoadingFlag({ blockType: "deleteSection", loading: true })
    );
    try {
      await Axios.delete(`/api/v1/section/${sectionId}/`, getHeader());

      dispatch(fetchSections(school));
      dispatch(fetchReports(school));
      if (district) {
        dispatch(fetchSchools(district));
      }
      dispatch(deleteSectionSuccess());
    } catch (error) {
      dispatch(deleteSectionFailure(getSimplifiedError(error)));
    }
  };

export const deleteRoom =
  ({ roomId, sectionDetails, school, district }) =>
  async (dispatch) => {
    dispatch(resetBlockFlags({ blockType: "deleteRoom" }));
    dispatch(setBlockLoadingFlag({ blockType: "deleteRoom", loading: true }));
    try {
      await Axios.delete(`/api/v1/room/${roomId}/`, getHeader());
      dispatch(deleteRoomSuccess());

      dispatch(fetchRoom(sectionDetails.id));
      dispatch(fetchSections(school));
      if (district) {
        dispatch(fetchSchools(district));
      }
      dispatch(fetchRooms({ schoolId: school }));
      dispatch(fetchReports(school));
    } catch (error) {
      dispatch(deleteRoomFailure(getSimplifiedError(error)));
      throw error;
    }
  };

export const assignCleaner =
  ({ sectionId, data }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(
        `/api/v1/section/${sectionId}/add-cleaner/`,
        data
      );

      if (response) {
        dispatch(assignCleanerSuccess(response));
      }
      // dispatch(createRoomSuccess(response));
    } catch (error) {
      dispatch(assignCleanerFailure(getSimplifiedError(error)));
    }
  };

export const getSpecificRoom =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(setBlockLoadingFlag({ blockType: "specificRoom", loading: true }));
      const response = await Axios.get(`/api/v1/room/${id}/`, getHeader());

      dispatch(getRoomSuccess(response));
    } catch (error) {
      dispatch(getRoomFailure(getSimplifiedError(error)));
    }
  };
export const getRandomRooms =
  ({ schoolId, cleanerId = "" }) =>
  async (dispatch) => {
    dispatch(resetFlagsSectionSuccess({ blockType: "getRandomRooms" }));
    dispatch(
      setBlockLoadingFlag({ blockType: "getRandomRooms", loading: true })
    );
    try {
      let url = `/api/v1/school/${schoolId}/random-rooms/`;
      if (cleanerId) url += `?cleaner=${cleanerId}`;
      const response = await Axios.get(url);

      dispatch(getRandomRoomsSuccess(response));
      // dispatch(createRoomSuccess(response));
    } catch (error) {
      dispatch(getRandomRoomsFailure(getSimplifiedError(error)));
    } finally {
      dispatch(
        setBlockLoadingFlag({ blockType: "getRandomRooms", loading: false })
      );
    }
  };

export const getInspectionQuestions = () => async (dispatch) => {
  try {
    const response = await Axios.get("/api/v1/inspection-parameter/");

    dispatch(getInspectionQuestionsSuccess(response));
  } catch (error) {
    dispatch(getInspectionQuestionsFailure(getSimplifiedError(error)));
  }
};

export const saveInspectionResult = (payload) => async (dispatch) => {
  try {
    dispatch(resetBlockFlags({ blockType: "saveInspectionResult" }));
    dispatch(
      setBlockLoadingFlag({ blockType: "saveInspectionResult", loading: true })
    );
    if (payload.id) {
      const response = await Axios.put("/api/v1/inspection/", payload);
      dispatch(saveInspectionResultSuccess(response));
    } else {
      delete payload.id;
      const response = await Axios.post("/api/v1/inspection/", payload);
      dispatch(saveInspectionResultSuccess(response));
    }
  } catch (error) {
    dispatch(saveInspectionResultFailure(getSimplifiedError(error)));
  } finally {
    dispatch(
      setBlockLoadingFlag({ blockType: "saveInspectionResult", loading: false })
    );
  }
};

export const removeRoomCleaners =
  (ids, buildingId = "", toggle, cb,dId="") =>
  async (dispatch) => {
    dispatch(resetFlagsSectionSuccess({ blockType: "editRoom" }));
    dispatch(setBlockLoadingFlag({ blockType: "editRoom", loading: true }));
    try {
      const promises = ids.map(async (id) => {
        const response = await Axios.patch(`/api/v1/room/${id}/`, {
          cleaner: null,
        });
        return response;
      });
      await Promise.all(promises);
      toastMsg("Successfully Removed cleaners", NOTIFICATION.success);
      dispatch(fetchWorkLoad(buildingId));
      dispatch(fetchCleaners(buildingId,dId));
      if (cb) {
        cb();
      }
    } catch (error) {
      console.error(error);
      getSimplifiedError(error);
      toastMsg(error.message || "Something Went Wrong", NOTIFICATION.error);
    } finally {
      dispatch(setBlockLoadingFlag({ blockType: "editRoom", loading: false }));
      toggle();
    }
  };

export const removeCleanerFromAllRooms =
  (cleanerId, buildingId = "", toggle, cb,dId="") =>
  async (dispatch) => {
    dispatch(resetFlagsSectionSuccess({ blockType: "editRoom" }));
    dispatch(setBlockLoadingFlag({ blockType: "editRoom", loading: true }));
    try {
      await Axios.delete(`/api/v1/remove/cleaner/all/rooms/${cleanerId}/`);

      toastMsg(
        "Successfully Removed cleaner from all rooms",
        NOTIFICATION.success
      );
      dispatch(fetchWorkLoad(buildingId));
      dispatch(fetchCleaners(buildingId,dId));
      if (cb) {
        cb();
      }
    } catch (error) {
      console.error(error);
      getSimplifiedError(error);
      toastMsg(error.message || "Something Went Wrong", NOTIFICATION.error);
    } finally {
      dispatch(setBlockLoadingFlag({ blockType: "editRoom", loading: false }));
      toggle();
    }
  };

export const createRoomFeature = (payload, toggle,key="room", cb) => async (dispatch) => {
  try {
    dispatch(updateRoomFeatureProcessing(true));

    const{timeInMinutes,...rest}=payload;
    
    const response = await Axios.post(
      `/api/v1/room-feature/`,
      {...rest,timeInSeconds:convertMinutesToSeconds(timeInMinutes)},
      getHeader()
    );
    if(key==="specificRoom"){
     dispatch(updateSpecificRoomFeature(response))
    }
    else{
      dispatch(updateRoomFeature(response));
    }
    if (cb) {
      cb();
    }
  } catch (error) {
    const msg = getSimplifiedError(error).join("");
    toastMsg(msg || "SomeThing Went Wrong", NOTIFICATION.error);
  } finally {
    if (toggle) {
      toggle();
    }
    dispatch(updateRoomFeatureProcessing(false));
  }
};
export const removeRoomFeature = (payload, toggle,key="room", cb) => async (dispatch) => {
  dispatch(updateRoomFeatureProcessing(true));
  try {
    await Axios.delete(`/api/v1/room-feature/${payload.id}/`, getHeader());
    if(key==="specificRoom"){
      dispatch(updateSpecificRoomFeature({ ...payload, isRemove: true }))
     }
     else{
      dispatch(updateRoomFeature({ ...payload, isRemove: true }));
     }
    toastMsg("Room Feature Removed Successfully", NOTIFICATION.success);
  } catch (error) {
    let msg = getSimplifiedError(error).join("");
    if (msg.includes("html")) {
      msg = error.message;
    }
    toastMsg(msg || "SomeThing Went Wrong", NOTIFICATION.error);
  } finally {
    dispatch(updateRoomFeatureProcessing(false));
    toggle();
    if (cb) {
      cb();
    }
  }
};

export const editRoomFeature = (payload, toggle,key="room",cb) => async (dispatch) => {
  dispatch(updateRoomFeatureProcessing(true));
  try {
     const {timeInMinutes,...rest}=payload;
     const timeInSeconds=convertMinutesToSeconds(timeInMinutes);
    await Axios.put(
      `/api/v1/room-feature/${payload.id}/`,
      {...rest,timeInSeconds},
      getHeader()
    );
    if(key=="specificRoom"){
      dispatch(updateSpecificRoomFeature({ ...rest,timeInSeconds,timeInMinutes, isUpdate: true }))
    }
    else{
      dispatch(updateRoomFeature({ ...rest,timeInSeconds,timeInMinutes, isUpdate: true }));
    }
   
    toastMsg("Room Feature Updated Successfully", NOTIFICATION.success);
    if (cb) {
      cb();
    }
  } catch (error) {
    let msg = getSimplifiedError(error).join("");
    if (msg.includes("html")) {
      msg = error.message;
    }
    toastMsg(msg || "SomeThing Went Wrong", NOTIFICATION.error);
  } finally {
    dispatch(updateRoomFeatureProcessing(false));
    toggle();
  }
};

export const fetchDefaultRoomFeatures = (id) => async (dispatch) => {
  try {
    dispatch(setDefaultRoomFeaturesLoading(true));
    if (id) {
      const response = await Axios.get(
        `/api/v1/default-room-feature/?room_type=${id}`,
        getHeader()
      );
     const data= response.map((item) => ({ ...item, timeInMinutes: convertSecondsToMinutes(item.timeInSeconds) }))

      dispatch(fetchDefaultRoomFeaturesSuccess(data));
    } else {
      dispatch(fetchDefaultRoomFeaturesSuccess([]));
    }
  } catch (error) {
    const msg = getSimplifiedError(error).join("");
    toastMsg(msg || "SomeThing Went Wrong", NOTIFICATION.error);
    dispatch(fetchDefaultRoomFeaturesSuccess([]));
  } finally {
    dispatch(setDefaultRoomFeaturesLoading(false));
  }
};

export const createDefaultRoomFeature =
  (payload, toggle, cb) => async (dispatch) => {
    try {
      const {timeInMinutes,...rest}=payload

      dispatch(updateDefaultRoomFeatureProcessing(true));
      const response = await Axios.post(
        `/api/v1/default-room-feature/`,
        {...rest,timeInSeconds:convertMinutesToSeconds(timeInMinutes)},
        getHeader()
      );
      const data={...response,timeInMinutes:convertSecondsToMinutes(response.timeInSeconds)}
      dispatch(updateDefaultRoomFeature({...data,isNew:true}));
      if (cb) {
        cb();
      }
    } catch (error) {
      const msg = getSimplifiedError(error).join("");
      toastMsg(msg || "SomeThing Went Wrong", NOTIFICATION.error);
    } finally {
      if (toggle) {
        toggle();
      }
      dispatch(updateDefaultRoomFeatureProcessing(false));
    }
  };

export const removeDefaultRoomFeature =
  (payload, toggle, cb) => async (dispatch) => {
    dispatch(updateDefaultRoomFeatureProcessing(true));
    try {
      await Axios.delete(
        `/api/v1/default-room-feature/${payload.id}/`,
        getHeader()
      );
      dispatch(updateDefaultRoomFeature({ ...payload, isRemove: true }));

      toastMsg(
        "Room Default Feature Removed Successfully",
        NOTIFICATION.success
      );
    } catch (error) {
      let msg = getSimplifiedError(error).join("");
      if (msg.includes("html")) {
        msg = error.message;
      }
      toastMsg(msg || "SomeThing Went Wrong", NOTIFICATION.error);
    } finally {
      dispatch(updateDefaultRoomFeatureProcessing(false));
      toggle();
      if (cb) {
        cb();
      }
    }
  };

export const editDefaultRoomFeature =
  (payload, toggle, cb) => async (dispatch) => {
    dispatch(updateDefaultRoomFeatureProcessing(true));
    try {
      const {timeInMinutes,...rest}=payload;
      await Axios.put(
        `/api/v1/default-room-feature/${payload.id}/`,
        {...rest,timeInSeconds:convertMinutesToSeconds(timeInMinutes)},
        getHeader()
      );
      dispatch(updateDefaultRoomFeature({ ...payload, timeInSeconds:convertMinutesToSeconds(timeInMinutes), isUpdate: true }));
      if (cb) {
        cb();
      }
      toastMsg(
        "Default Room Feature Updated Successfully",
        NOTIFICATION.success
      );
    } catch (error) {
      let msg = getSimplifiedError(error).join("");
      if (msg.includes("html")) {
        msg = error.message;
      }
      toastMsg(msg || "SomeThing Went Wrong", NOTIFICATION.error);
    } finally {
      dispatch(updateDefaultRoomFeatureProcessing(false));
      toggle();
    }
  };
