import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDistrict } from "../../services/District";

import BaseModal from "components/BaseModal";

const AdminList = ({ districtId, onOpenModal, onCloseModal }) => {
  const dispatch = useDispatch();
  const {
    admins: { data },
  } = useSelector(({ admin }) => admin);

  const [adminUsers, setAdminUsers] = useState([]);

  useEffect(() => {
    if (data) {
      const adminUsersList = [];
      data.forEach((d) => {
        if (d.is_user) {
          adminUsersList.push(d.employeeDetail.id);
        }
      });
      setAdminUsers(adminUsersList);
    }
  }, [data]);

  const handleCheckboxChange = (event) => {
    const value = Number(event.target.value);
    if (adminUsers.includes(value)) {
      const filteredAdminUsers = adminUsers.filter((i) => i !== value);
      setAdminUsers(filteredAdminUsers);
    } else {
      setAdminUsers([...adminUsers, value]);
    }
  };

  const saveHandler = (e) => {
    dispatch(
      updateDistrict({
        id: districtId,
        admins: adminUsers,
      })
    );
    onCloseModal();
  };

  return (
    <BaseModal
      onConfirm={saveHandler}
      onClose={onCloseModal}
      show={onOpenModal}
      title="Select district admin"
      content={
        data.length > 0 &&
        data.map((admin) => (
          <div
            className="d-flex items-center justify-content-between mb-4"
            key={admin?.id}
          >
            <div>{admin?.employeeDetail?.name}</div>
            <div>
              <input
                type="checkbox"
                className="form-check-input big-checkbox"
                checked={adminUsers.includes(admin?.employeeDetail?.id)}
                value={admin?.employeeDetail?.id}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>
        ))
      }
    />
  );
};

export default AdminList;
