import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import device from "../../assets/images/device.jpg";
import eye from "../../assets/images/eye.svg";
import useWindowSize from "../../utils/useWindowSize";
import styled from "styled-components";
import logo from "assets/images/logo.png";
import { signup } from "../../services/auth";

export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width: screenWidth } = useWindowSize();
  const [error, setError] = useState();
  const { signupSuccess: success, signUpError } = useSelector(
    ({ auth }) => auth
  );

  useEffect(() => {
    if (success) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (signUpError) {
      setError(signUpError);
    }
  }, [signUpError]);

  const [signUpDetails, setSignUpDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    password: "",
    confirm_password: "",
    employer_code: "",
  });
  const handleChange = ({ target: { name, value } }) => {
    setSignUpDetails({ ...signUpDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (signUpDetails.password !== signUpDetails.confirm_password) {
      setError("Confirm password not match with the password");
      return;
    }
    if (signUpDetails.password < 1 || signUpDetails.confirm_password < 1) {
      setError("Please Enter Valid Password");
    } else {
      setError();
      dispatch(signup(signUpDetails));
    }
  };

  useEffect(() => {
    const togglePassword = document.querySelector("#togglePassword");
    const togglePasswordConfirm = document.querySelector(
      "#togglePasswordConfirm"
    );
    const password = document.querySelector("#floatingPassword");
    const confirmPassword = document.querySelector("#floatingConfirmPassword");
    togglePassword.addEventListener("click", function (e) {
      const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
    });
    togglePasswordConfirm.addEventListener("click", function (e) {
      const type =
        confirmPassword.getAttribute("type") === "password"
          ? "text"
          : "password";
      confirmPassword.setAttribute("type", type);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="device-holder d-flex h-100">
      <div className="align-items-center d-flex flex-fill justify-content-center login-content">
        <div className="login-holder">
          <h2>Sign up</h2>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingFirstName"
                  placeholder="First Name"
                  name="first_name"
                  value={signUpDetails.first_name}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingFirstName">First Name</label>
              </div>
            </div>
            <div className="mb-4">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingLastName"
                  placeholder="Last Name"
                  name="last_name"
                  value={signUpDetails.last_name}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingLastName">Last Name</label>
              </div>
            </div>
            <div className="mb-4">
              <div className="form-floating mb-3 go-bottom">
                <input
                  type="email"
                  className="form-control"
                  id="floatingEmail"
                  placeholder="samplee@example.com"
                  name="email"
                  value={signUpDetails.email}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingEmail">Email address</label>
              </div>
            </div>
            <div className="mb-4">
              <div className="form-floating">
                <input
                  type="number"
                  min="1"
                  className="form-control"
                  id="floatingPhone"
                  placeholder="Phone no"
                  name="phone_no"
                  value={signUpDetails.phone_no}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingPhone">Phone no.</label>
              </div>
            </div>
            <div className="mb-4">
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  name="password"
                  min="1"
                  value={signUpDetails.password}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingPassword">Password</label>
                <img
                  src={eye}
                  alt=""
                  className="image-responsive eye-icon"
                  id="togglePassword"
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="floatingConfirmPassword"
                  placeholder="Confirm Password"
                  name="confirm_password"
                  min="1"
                  value={signUpDetails.confirm_password}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingConfirmPassword">
                  Confirm Password
                </label>
                <img
                  src={eye}
                  alt=""
                  className="image-responsive eye-icon"
                  id="togglePasswordConfirm"
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  id="floatingInputCode"
                  placeholder="Employer Code"
                  name="employer_code"
                  min="1"
                  value={signUpDetails.employer_code}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingInputCode">Employer Code</label>
              </div>
            </div>
            <button type="submit" className="btn btn-primary w-100">
              REGISTER
            </button>
            {screenWidth < 768 && (
              <div className="caption-footer">
                <div className="new-user d-inline-block cursor-pointer">
                  Already registered ?
                </div>
                <a className="btn btn-light w-100" href="/" role="button">
                  Login
                </a>
              </div>
            )}
          </form>
        </div>
      </div>
      <div className="image-holder flex-fill">
        <img src={device} alt="" className="image-responsive form-image" />
        <div className="image-caption">
          <div className="caption-head">
            <LogoWrapper>
              <img src={logo} alt="" className="image-responsive" />
            </LogoWrapper>
          </div>
          <div className="caption-footer">
            <div className="new-user d-inline-block cursor-pointer">
              Already registered ?
            </div>
            <a className="btn btn-light w-100" href="/" role="button">
              Login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

const LogoWrapper = styled.div`
  img {
    width: 250px !important;
    height: auto;
  }
`;
