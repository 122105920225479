import "react-responsive-modal/styles.css";
import React, { useState } from "react";


export default function ActionDropdown(props) {
  const {handlers} = props;
  if (!handlers || handlers.length === 0) {
    return
  }
  return (
      <div className="dropdown-container">
          <button
            onClick={(e) => e.stopPropagation()}
            className="dropdown btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
          <i className="fa-solid fa-ellipsis"></i>
          </button>
          <ul
            className="dropdown-menu"
          >
          {Object.entries(handlers).map(([label, action], i) =>( 
            <li
              key={`${label} - ${i}`}
            >
            <a href="#" 
              className="dropdown-item"
                onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                action(e);
              }}>
            {label}
            </a>
            </li>
          ))}
          </ul>
    </div>
  );
}
