import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import { editSchool } from "../../../services/school";
import BaseModal from "components/BaseModal";
import { toastMsg, checkValidEmail } from "utils/utility";
import { NOTIFICATION } from "constants/constants";

export default function SchoolEdit({
  name,
  image,
  district,
  id,
  setOpenEditModal,
  onCloseModal,
  open,
  contact_email,
}) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.school?.editSchool);
  const [buildingDetail, setBuildingDetail] = useState({
    name,
    contact_email,
    logo: image,
  });
  const [changedLogoImage, setchangedLogoImage] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      setDisabled(true);
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
        setchangedLogoImage(true);
        setDisabled(false);
      };
      fileReader.onerror = (error) => {
        reject(error);
        setDisabled(false);
      };
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) {
      return;
    }
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      try {
        if (!buildingDetail.name || buildingDetail.name === "") {
          toastMsg("Please enter a name for the building", NOTIFICATION.error);
        } else if (
          buildingDetail.contact_email !== "" &&
          buildingDetail.contact_email !== null &&
          !checkValidEmail(buildingDetail?.contact_email)
        ) {
          toastMsg("Please enter a valid contact email", NOTIFICATION.error);
        } else {
          const obj = {
            data: {
              name: buildingDetail.name,
              image: buildingDetail.logo,
              contact_email:
                buildingDetail?.contact_email === ""
                  ? null
                  : buildingDetail?.contact_email,
              district: district,
            },
            school: id,
          };
          if (!changedLogoImage) {
            delete obj.data["image"];
          }
          setDisabled(true);
          const successCB = () => {
            setDisabled(false);
            setOpenEditModal(false);
            setBuildingDetail({ name: "", logo: "", contact_email: "" });
            setchangedLogoImage(false);
          };
          dispatch(editSchool(obj, successCB));
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChange = async ({ target: { name, value, files } }) => {
    if (name === "logo") {
      const file = files[0];
      const base64 = await convertToBase64(file);
      setBuildingDetail({
        ...buildingDetail,
        logo: base64,
      });
    } else {
      setBuildingDetail({ ...buildingDetail, [name]: value });
    }
  };
  const handleImagesChange = async (e) => {
    const file = e.target.files[0];
    e.target.value = "";
    const base64 = await convertToBase64(file);

    setBuildingDetail({
      ...buildingDetail,
      logo: base64,
    });
  };

  return (
    <BaseModal
      onSubmit={submitHandler}
      onClose={onCloseModal}
      show={open}
      title="Edit Building"
      submitButtonText="Update"
      formWrapper={true}
      formLoading={loading}
      content={
        <>
          <div className="mb-4">
            <div className="form-floating mb-4 go-bottom">
              <input
                type="text"
                className="form-control"
                name="name"
                value={buildingDetail.name}
                onChange={handleChange}
                id="floatingInput"
                placeholder="Building Name"
              />
              <label htmlFor="name">Building Name</label>
            </div>
          </div>
          <div className="mb-4">
            <div className="form-floating mb-4 go-bottom">
              <input
                type="email"
                className="form-control"
                name="contact_email"
                value={buildingDetail.contact_email}
                onChange={handleChange}
                id="floatingInput"
                placeholder="Contact Email"
              />
              <label htmlFor="contact_email">Contact Email</label>
            </div>
          </div>

          <div className="logo-uploader mb-4">
            <div className="logo-uploader-header d-flex align-items-center justify-content-between mb-3">
              <div>School Image</div>
              <input
                type="file"
                onChange={handleImagesChange}
                id="select-images"
                className="btn btn-outline-secondary"
                style={{ display: "none" }}
              />
              <label
                htmlFor="select-images"
                className="btn btn-outline-secondary btn-lg d-flex align-items-center text-uppercase"
              >
                Select image
              </label>
            </div>
            <div className="logo-uploader-area">
              {buildingDetail.logo === "" ? (
                <p>Building Image</p>
              ) : (
                <Image
                  src={buildingDetail.logo}
                  style={{ maxHeight: "100px" }}
                  fluid
                />
              )}
            </div>
          </div>
        </>
      }
    />
  );
}
