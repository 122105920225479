import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

const block = {
  loading: false,
  error: "",
  success: false,
};

const initialState = {
  admins: { ...block, data: [] },
  users: { ...block, data: [] },
  updateUser: { ...block, data: [] },
  roles: { ...block, data: [] },
  invites: { ...block, data: [] },
  updateUserPassword: { ...block, data: [] },
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setBlockLoading(state, {payload: {blockType, loading = true}}) {
      state[blockType] = {...state[blockType], loading}
    },
    getDistrictEmployeesSuccess: ({ admins }, action) => {
      admins.data = action.payload;
      admins.loading = false;
      admins.success = true;
    },
    getDistrictEmployeesFailure: ({ admins }, action) => {
      admins.error = action.payload;
      admins.loading = false;
      admins.success = true;
    },
    fetchAdminsSuccess: ({ admins }, action) => {
      admins.data = action.payload;
      admins.loading = false;
      admins.success = true;
    },
    fetchAdminsFailure: ({ admins }, action) => {
      admins.error = action.payload;
      admins.loading = false;
      admins.success = true;
    },
    fetchUsersSuccess: ({ users }, action) => {
      users.data = action.payload;
      users.loading = false;
      users.success = true;
    },
    fetchUsersFailure: ({ users }, action) => {
      users.error = action.payload;
      users.loading = false;
      users.success = true;
    },
    updateUsersSuccess: ({ updateUser }, action) => {
      updateUser.data = action.payload;
      updateUser.loading = false;
      updateUser.success = true;
      updateUser.error = false;
    },
    updateUsersFailure: ({ updateUser }, action) => {
      updateUser.error = true;
      updateUser.data = action.payload;
      updateUser.loading = false;
      updateUser.success = false;
    },
    fetchRolesSuccess: ({ roles }, action) => {
      roles.data = action.payload;
      roles.loading = false;
      roles.success = true;
    },
    fetchRolesFailure: ({ roles }, action) => {
      roles.error = true;
      roles.data = action.payload;
      roles.loading = false;
      roles.success = false;
    },
    fetchPendingInvitesSuccess: ({ invites }, action) => {
      invites.data = action.payload;
      invites.loading = false;
      invites.success = true;
    },
    fetchPendingInvitesFailure: ({ invites }, action) => {
      invites.error = true;
      invites.data = action.payload;
      invites.loading = false;
      invites.success = false;
    },
    resetUserStatus: ({ updateUser, users }) => {
      updateUser.loading = false;
      updateUser.success = false;
      updateUser.error = false;
      updateUser.data = null;
      users.loading = false;
      users.success = false;
    },
    updateUserPasswordSuccess: ({ updateUserPassword }, action) => {
      updateUserPassword.data = action.payload;
      updateUserPassword.loading = false;
      updateUserPassword.success = true;
      updateUserPassword.error = false;
    },
    updateUserPasswordFaillure: ({ updateUserPassword }, action) => {
      updateUserPassword.error = action?.payload;
      updateUserPassword.loading = false;
      updateUserPassword.success = false;
    },
  },
});

export const {
  fetchAdminsSuccess,
  fetchAdminsFailure,
  getDistrictEmployeesSuccess,
  getDistrictEmployeesFailure,
  fetchUsersSuccess,
  fetchUsersFailure,
  updateUsersSuccess,
  updateUsersFailure,
  fetchRolesSuccess,
  fetchRolesFailure,
  fetchPendingInvitesSuccess,
  fetchPendingInvitesFailure,
  resetUserStatus,
  setBlockLoading,
  updateUserPasswordFaillure,
  updateUserPasswordSuccess,
} = adminSlice.actions;
export default adminSlice.reducer;
