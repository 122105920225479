import { createSlice } from "@reduxjs/toolkit";

const block = {
  loading: false,
  error: "",
  success: false,
};

const initialState = {
  sections: { ...block, data: [] },
  room: { ...block, data: [] },
  createRoom: { ...block, data: [] },
  createSection: { ...block, data: [] },
  editSection: { ...block },
  deleteSection: { ...block },
  assignCleaner: { ...block },
  editRoom: { ...block },
  deleteRoom: { ...block },
  specificRoom: { ...block, data: null, isEditProcessing:false },
  getRandomRooms: { ...block, data: [] },
  getInspectionQuestions: { ...block, data: [] },
  saveInspectionResult: { ...block },
  roomFeatureProcessing: false,
  roomDefaultFeatures: {
    loading: false,
    data: [],
    roomDefaultFeatureProcessing: false,
  },
};

const sectionSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    setSectionsLoading: ({ sections }, action) => {
      sections.loading = action.payload;
    },
    setRoomsLoading: ({ room }, action) => {
      room.loading = action.payload;
    },
    resetSectionsBlock: (state, action) => {
      state.sections = { ...state.sections, ...block };
    },
    resetRoomsBlock: (state, action) => {
      state.room = { ...state.room, ...block };
    },
    resetEditSectionBlock: (state, action) => {
      state.editSection = { ...state.editSection, ...block };
    },
    fetchSectionSuccess: ({ sections }, action) => {
      sections.data = action.payload;
      sections.loading = false;
      sections.success = true;
    },
    fetchSectionFailure: ({ sections }, action) => {
      sections.loading = false;
      sections.success = false;
      sections.error = action.payload;
    },
    createSectionSuccess: ({ createSection, sections }, action) => {
      sections.data.push(action.payload);
      createSection.loading = false;
      createSection.success = true;
      sections.success = true;
    },
    createSectionFailure: ({ createSection }, action) => {
      createSection.error = action.payload;
      createSection.loading = false;
      createSection.success = false;
    },
    editSectionSuccess: ({ editSection }, action) => {
      editSection.loading = false;
      editSection.success = true;
    },
    editSectionFailure: ({ editSection }, action) => {
      editSection.loading = false;
      editSection.error = true;
    },
    deleteSectionSuccess: ({ deleteSection }, action) => {
      deleteSection.loading = false;
      deleteSection.success = true;
    },
    deleteSectionFailure: ({ deleteSection }, action) => {
      deleteSection.loading = false;
      deleteSection.error = action.payload;
    },
    fetchRoomSuccess: ({ room }, action) => {
      room.data = action.payload;
      room.loading = false;
      room.success = true;
    },
    fetchRoomFailure: ({ room }, action) => {
      room.loading = false;
      room.success = false;
      room.error = action.payload;
    },
    createRoomSuccess: ({ createRoom ,room}, action) => {
      createRoom.data = action.payload;
      createRoom.loading = false;
      createRoom.success = true;
      room.data=[...room.data,action.payload]
    },
    createRoomFailure: ({ createRoom}, action) => {
      createRoom.loading = false;
      createRoom.success = false;
      createRoom.error = action.payload;
   
    },

    getRoomSuccess: ({ specificRoom }, action) => {
      specificRoom.data = action.payload;
      specificRoom.loading = false;
      specificRoom.success = true;
    },
    getRoomFailure: ({ specificRoom }, action) => {
      specificRoom.loading = false;
      specificRoom.success = false;
      specificRoom.error = action.payload;
    },
    deleteRoomSuccess: ({ deleteRoom }) => {
      deleteRoom.loading = false;
      deleteRoom.success = true;
    },
    deleteRoomFailure: ({ deleteRoom }, action) => {
      deleteRoom.loading = false;
      deleteRoom.error = action.payload;
    },
    assignCleanerSuccess: ({ assignCleaner }, action) => {
      assignCleaner.data = action.payload;
      assignCleaner.loading = false;
      assignCleaner.success = true;
    },
    assignCleanerFailure: ({ assignCleaner }, action) => {
      assignCleaner.loading = false;
      assignCleaner.success = false;
      assignCleaner.error = action.payload;
    },

    editRoomSuccess: ({ editRoom }, action) => {
      editRoom.loading = false;
      editRoom.success = true;
    },
    editRoomFailure: ({ editRoom }, action) => {
      editRoom.loading = false;
      editRoom.success = false;
      editRoom.error = action.payload;
    },

    getRandomRoomsSuccess: ({ getRandomRooms }, action) => {
      getRandomRooms.data = action.payload;
      getRandomRooms.loading = false;
      getRandomRooms.success = true;
    },
    getRandomRoomsFailure: ({ getRandomRooms }, action) => {
      getRandomRooms.loading = false;
      getRandomRooms.success = false;
      getRandomRooms.error = action.payload;
    },

    getInspectionQuestionsSuccess: ({ getInspectionQuestions }, action) => {
      getInspectionQuestions.data = action.payload;
      getInspectionQuestions.loading = false;
      getInspectionQuestions.success = true;
    },
    getInspectionQuestionsFailure: ({ getInspectionQuestions }, action) => {
      getInspectionQuestions.loading = false;
      getInspectionQuestions.success = false;
      getInspectionQuestions.error = action.payload;
    },

    saveInspectionResultSuccess: (
      { saveInspectionResult, getRandomRooms },
      action
    ) => {
      const randomRooms = [...getRandomRooms.data?.randomRooms];
      if (randomRooms && randomRooms.length > 0) {
        const data = action.payload;
        const updatedRoom = randomRooms.find((room) => data.room === room.id);
        updatedRoom.inspectionParametersResults =
          data.inspectionParametersResults;
      }
      getRandomRooms.data = {
        randomRooms: randomRooms,
        inspectionNoRel: action.payload.inspectionNoRel,
      };
      getRandomRooms.loading = false;
      getRandomRooms.success = true;

      saveInspectionResult.loading = false;
      saveInspectionResult.success = true;
    },
    saveInspectionResultFailure: ({ saveInspectionResult }, action) => {
      saveInspectionResult.loading = false;
      saveInspectionResult.success = false;
      saveInspectionResult.error = action.payload;
    },
    setBlockLoadingFlag: (state, action) => {
      const blockType = action.payload.blockType;
      if (state[blockType] === undefined) {
        console.log(
          `sectionSlice:setBlockLoadingFlag: blockType '${blockType}' not found.`
        );
        return;
      }
      state[blockType] = {
        ...state[blockType],
        loading: action.payload.loading,
      };
    },
    resetBlockFlags: (state, action) => {
      const blockType = action.payload.blockType;
      if (state[blockType] === undefined) {
        console.log(
          `sectionSlice:resetBlockFlags: blockType '${blockType}' not found.`
        );
        return;
      }
      state[blockType] = { ...state[blockType], ...block };
    },
    resetBlock(state, action) {
      const blockType = action.payload.blockType;
      if (state[blockType] === undefined) {
        console.log(
          `sectionSlice:resetBlock: blockType '${blockType}' not found.`
        );
        return;
      }
      state[blockType] = { ...initialState[blockType] };
    },
    resetBlockSectionSuccess: (state, action) => {
      // TODO: update later
      state[action.payload.blockType] = {
        ...state[action.payload.blockType],
        ...initialState[action.payload.blockType],
      };
    },

    resetFlagsSectionSuccess: (state, action) => {
      // TODO: update later
      state[action.payload.blockType] = {
        ...state[action.payload.blockType],
        ...block,
      };
    },

    updateRoomFeatureProcessing: (state, action) => {
      state.roomFeatureProcessing = action.payload;
    },
    updateRoomFeature: (state, action) => {
      const { isRemove = false, isUpdate = false, ...feature } = action.payload;
      state.room.data = state.room.data.map((room) => {
        if (room.id == feature.room) {
          let updatedFeatures = room.roomFeatures.map((updatedFeature) => {
            if (isUpdate && updatedFeature.id === feature.id) {
              return { ...updatedFeature, ...feature };
            }
            return updatedFeature;
          });
          if (isRemove) {
            updatedFeatures = updatedFeatures.filter(
              (updatedFeature) => updatedFeature.id !== feature.id
            );
          } else if (!isUpdate) {
            updatedFeatures = [...updatedFeatures, feature];
          }
          return {
            ...room,
            roomFeatures: updatedFeatures,
          };
        }
        return room;
      });
    },
    updateSpecificRoomFeature: (state, action) => {
      try{
        const { isRemove = false, isUpdate = false, ...feature } = action.payload;
        const room=state.specificRoom.data;
         
            let updatedFeatures = room.roomFeatures.map((updatedFeature) => {
              if (isUpdate && updatedFeature.id === feature.id) {
                return { ...updatedFeature, ...feature };
              }
              return updatedFeature;
            });
            if (isRemove) {
              updatedFeatures = updatedFeatures.filter(
                (updatedFeature) => updatedFeature.id !== feature.id
              );
            } else if (!isUpdate) {
              updatedFeatures = [...updatedFeatures, feature];
            }
            state.specificRoom.data={
              ...room,
              roomFeatures: updatedFeatures,
            };
          
        
      }
      catch(error){
        console.error(error)
      }

    },
    updateSpecificRoom: (state, action) => {
        const payload = action.payload;
            state.specificRoom.data=payload;
    },
    updateSpecificRoomProcessing: ({ specificRoom }, action) => {
      specificRoom.isEditProcessing = action.payload;
    },
    setDefaultRoomFeaturesLoading: ({ roomDefaultFeatures }, action) => {
      roomDefaultFeatures.loading = action.payload;
    },
    fetchDefaultRoomFeaturesSuccess: ({ roomDefaultFeatures }, action) => {
      roomDefaultFeatures.data = action.payload;
    },

    updateDefaultRoomFeatureProcessing: ({ roomDefaultFeatures }, action) => {
      roomDefaultFeatures.roomDefaultFeatureProcessing = action.payload;
    },
    updateDefaultRoomFeature: ({ roomDefaultFeatures }, action) => {
      const { isRemove = false, isUpdate = false, isNew=false, ...feature } = action.payload;

      let updatedFeatures = roomDefaultFeatures.data.map((updatedFeature) => {
        if (isUpdate && updatedFeature.id === feature.id) {
          return { ...feature };
        }
        return updatedFeature;
      });
      if (isRemove) {
        updatedFeatures = updatedFeatures.filter(
          (updatedFeature) => updatedFeature.id !== feature.id
        );
      } else if(isNew) {
        updatedFeatures = [...updatedFeatures, feature];
      }
      roomDefaultFeatures.data =updatedFeatures;
    },
  },
});

export const {
  resetBlockFlags,
  resetBlock,
  deleteSectionSuccess,
  deleteSectionFailure,
  getDistrictSuccess,
  getDistrictFailure,
  updateDistrictSuccess,
  updateDistrictFailure,
  deleteDistrictSuccess,
  deleteDistrictFailure,
  fetchDistrictSuccess,
  fetchDistrictFailure,
  createDistrictSuccess,
  createDistrictFailure,
  generateCodeSuccess,
  generateCodeFailure,
  getDistrictPeopleSuccess,
  getDistrictPeopleFailure,
  assignCleanerFailure,
  assignCleanerSuccess,
  createRoomFailure,
  createRoomSuccess,
  createSectionFailure,
  createSectionSuccess,
  editRoomFailure,
  editRoomSuccess,
  setBlockLoadingFlag,
  resetSectionsBlock,
  resetRoomsBlock,
  resetEditSectionBlock,
  setRoomsLoading,
  editSectionFailure,
  editSectionSuccess,
  fetchRoomFailure,
  fetchRoomSuccess,
  fetchSectionFailure,
  fetchSectionSuccess,
  setSectionsLoading,
  getInspectionQuestionsFailure,
  getInspectionQuestionsSuccess,
  getRandomRoomsFailure,
  getRandomRoomsSuccess,
  getRoomFailure,
  getRoomSuccess,
  resetBlockSectionSuccess,
  resetFlagsSectionSuccess,
  saveInspectionResultFailure,
  saveInspectionResultSuccess,
  deleteRoomSuccess,
  deleteRoomFailure,
  updateRoomFeature,
  updateRoomFeatureProcessing,
  fetchDefaultRoomFeaturesSuccess,
  setDefaultRoomFeaturesLoading,
  updateDefaultRoomFeature,
  updateDefaultRoomFeatureProcessing,
  updateSpecificRoomFeature,
  updateSpecificRoomProcessing,
  updateSpecificRoom
} = sectionSlice.actions;
export default sectionSlice.reducer;
