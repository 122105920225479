import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createRoomFeature,
  editRoomFeature,
  removeRoomFeature,
  updateRoom,
} from "../../services/section";
import BaseModal from "components/BaseModal";
import { fetchRoomTypes } from "services/inspection";
import {
  Container,
  Col,
  Row,
  Button,
  Spinner,
} from "react-bootstrap";
import {  convertToHoursMinutesSeconds, sortByProperty } from "utils/utility";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import RoomFeaturesTable from "components/RoomFeatures/RoomFeaturesTable";
import { convertSecondsToMinutes} from "utils/utility";
export default function WorkloadEditRoom({
  open,
  onCloseModal,
  roomDistrictId,
  callBackSuccess
}) {
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState("editRoom");
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedFeature] = useState("Feature");
  const [confirmModalDetails, setConfirmModalDetails] = useState({
    title: "",
    message: "",
    id: "",
  });
  const [isRefetch, setRefetch] = useState(false);
  const refetchCallback=()=>{
    if(isRefetch===false){
      setRefetch(true);

    }
  }
  const {
    specificRoom: { loading: isDataFetching, data,isEditProcessing},
    roomFeatureProcessing,
  } = useSelector(({ section }) => section);
  const {
    cleaners: { loading: cleanersLoading, data: cleaners },
  } = useSelector(({ cleaner }) => cleaner);
 const filteredCleaners=cleaners.filter((item)=>item.district===roomDistrictId)
  const [roomDetails, setRoomDetails] = useState({
    name: data?.name || "",
    roomType: data?.roomType || "",
    section: data?.section || "",
    cleaner: data?.cleaner || "",
  });
  const [roomFeatureDetail, setRoomFeatureDetail] = useState({
    name: "",
    quantity: "",
    timeInMinutes: "",
  });
 

  const {
    roomTypes: {
      success: roomTypesSuccess,
      loading: roomTypesLoading,
      data: roomTypes = [],
    },
  } = useSelector(({ inspection }) => inspection);

  const previousSection = useRef();
  useEffect(() => {
    previousSection.current = data?.section || "";
  }, [data?.section]);

  useEffect(() => {
    if (!roomTypesSuccess) {
      dispatch(fetchRoomTypes());
    }
  }, [dispatch,roomTypesSuccess]);
  const submitHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const toggleEdit = () => setActionType("editRoom");
    try {
      if (actionType === "addFeature") {
           
          dispatch(
            createRoomFeature(
              { room: data?.id, ...roomFeatureDetail },
              toggleEdit,
              "specificRoom",
              refetchCallback,
            )
          );
        
      } else if (actionType === "editFeature") {
       
          dispatch(editRoomFeature(roomFeatureDetail, toggleEdit,"specificRoom",refetchCallback));
        
      } else {
      dispatch(
          updateRoom({
            id: data?.id,
            data: {
              name: roomDetails.name,
              roomType: +roomDetails.roomType,
              section: +roomDetails.section,
              cleaner: roomDetails.cleaner,
              //flushable: +roomDetails.flushable,
            },
            key:"specificRoom"
          },()=>{callBackSuccess(); onCloseModal();})
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = ({ target: { name, value } }) => {
    setRoomDetails({ ...roomDetails, [name]: value });
  };
  const handleFeatureChange = ({ target: { name, value } }) => {
    setRoomFeatureDetail({ ...roomFeatureDetail, [name]: value });
  };
  const toggleConfirMModal = () => {
    setConfirmModal(!confirmModal);
  };
  const removeFeatureHandler = (feature) => {
    setConfirmModalDetails((prev) => ({
      ...prev,
      title: "Remove Room Feature",
      message: `Are You sure you want to remove this feature?`,
      id: feature.id,
    }));

    toggleConfirMModal();
  };
  const onConfirm = () => {
    try {
      const cb = () => {
        setConfirmModalDetails({ id: "", message: "", title: "" });
      };
     
        dispatch(
          removeRoomFeature(
            { id: confirmModalDetails.id, room:data?.id },
            toggleConfirMModal,
            "specificRoom",
            cb
          )
        );
      
    } catch (error) {
      console.error(error);
    }
  };
  const editFeatureHandler = (feature) => {
    setRoomFeatureDetail(feature);
    setActionType("editFeature");
  };

  const totalWorkload = useMemo(() => {
    const features = data?.roomFeatures || [];
    let totalSeconds = 0;
    features.forEach((feature) => {
      totalSeconds +=  ((feature.timeInSeconds || 0)*(feature.quantity||0));
    });
    return convertToHoursMinutesSeconds(totalSeconds);
  }, [selectedFeature,data]);

  useMemo(()=>{
    if(data?.name){

      setRoomDetails({
        name:data?.name,
        section:data?.section,
        roomType:data?.roomType,
        cleaner:data?.cleaner,

      })
    }

    
  },[data,isDataFetching])
  return (
    <BaseModal
      onSubmit={submitHandler}
      onClose={
        actionType.includes("Feature")
          ? () => setActionType("editRoom")
          :()=>{
            if(isRefetch){
              callBackSuccess();
            }
            onCloseModal();

          }
      }
      show={open}
      title={
        actionType === "addFeature"
          ? `Add ${selectedFeature}`
          : actionType === "editFeature"
          ? `Edit ${selectedFeature}`
          : "Edit Room Details"
      }
      submitButtonText={actionType.includes("add") ? "Add" : "Update"}
      formWrapper={true}
      formLoading={
        actionType.includes("Feature")
          ? roomFeatureProcessing 
          : isEditProcessing
      }
      size="lg"
      content={
        <>
          <Container fluid>
            
            {
            isDataFetching?<div className="h-100 w-100 d-flex align-items-center justify-center">
              <Spinner variant="primary" className="d-block"/>
            </div>:
            actionType.includes("Feature") ? (
              <Row>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="name"
                      value={roomFeatureDetail?.name}
                      placeholder="Feature Name"
                      onChange={handleFeatureChange}
                    />
                    <label htmlFor="name">Feature Name</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      type="number"
                      className="form-control"
                      name="quantity"
                      value={roomFeatureDetail?.quantity}
                      placeholder="Quantity"
                      onChange={handleFeatureChange}
                      min={0}
                      required
                    />
                    <label htmlFor="quantity">Quantity</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      type="number"
                      className="form-control"
                      name="timeInMinutes"
                      value={roomFeatureDetail?.timeInMinutes}
                      placeholder="timeInMinutes"
                      onChange={handleFeatureChange}
                      required
                    />
                    <label htmlFor="quantity">Time In Minutes</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="workloadInMinutes"
                      value={convertToHoursMinutesSeconds((roomFeatureDetail?.quantity||0)*((roomFeatureDetail?.timeInMinutes||0)*60))}
                      placeholder="Workload"
                      disabled
                    />
                    <label htmlFor="workloadInMinutes">Workload</label>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="name"
                      value={roomDetails?.name}
                      placeholder="Room Name"
                      onChange={handleChange}
                    />
                    <label htmlFor="name">Room Name</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <select
                      required
                      disabled={roomTypesLoading}
                      className="form-select"
                      name="roomType"
                      value={roomDetails?.roomType}
                      onChange={handleChange}
                    >
                      <option value="">Select room type</option>
                      {sortByProperty(roomTypes,"name")?.map((item) => (
                        <option key={item.id} id={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="name">Room Type</label>
                  </div>
                </Col>
                <Col md={6}>
                <div className="form-floating mb-4 go-bottom">
                    <select
                      disabled={cleanersLoading}
                      className="form-select"
                      name="cleaner"
                      value={roomDetails?.cleaner}
                      onChange={handleChange}
                    >
                      <option value="">Select Cleaner</option>
                      {sortByProperty(filteredCleaners,"name")?.map((item) => (
                        <option key={item.id} id={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="cleaner">Cleaner</label>
                  </div>
                </Col>
                <Col md={6}>
                
                    <div className="form-floating mb-4 go-bottom">
                      <input
                        required
                        type="text"
                        className="form-control"
                        name="totalWorkload"
                        value={totalWorkload}
                        placeholder="Workload"
                        disabled
                      />
                      <label htmlFor="totalWorkload">Total Workload</label>
                    </div>
                  
                </Col>
                <Col
                  md={12}
                  className="d-flex flex-wrap align-items-center justify-content-between flex-grow-1"
                >
                  <h5> {selectedFeature}s </h5>
                  <Button
                    variant="light"
                    size="sm"
                    className="btn-unset-height"
                    onClick={() => {
                      setActionType("addFeature");
                      setRoomFeatureDetail({
                        name: "",
                        quantity: "",
                        timeInMinutes: "",
                      });
                    }}
                  >
                    Add {selectedFeature}
                  </Button>
                </Col>
                <Col md={12} className="mt-2">
                  <RoomFeaturesTable
                    data={
                      data?.roomFeatures.map((item) => ({ ...item, timeInMinutes:convertSecondsToMinutes(item?.timeInSeconds||0) })) || [] 
                    }
                    editFeatureHandler={editFeatureHandler}
                    removeFeatureHandler={removeFeatureHandler}
                  />
                </Col>
              </Row>
            )}
          </Container>
          <ConfirmationModal
            show={confirmModal}
            onCancel={toggleConfirMModal}
            title={confirmModalDetails?.title}
            message={confirmModalDetails?.message}
            onConfirm={()=>{onConfirm(); refetchCallback();}}
            loading={roomFeatureProcessing}
          />
        </>
      }
    />
  );
}
