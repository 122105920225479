import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import DistrictList from "../District";

import "./dashboard.scss";
import { getUserDetails } from "../../services/auth/index.js";
import { fetchDistricts } from "../../services/District/index.js";
import NavBar from "../../components/MainNavbar.js";
import DistrictForm from "components/Dashboard/DistrictForm";
import InnerLoading from "components/InnerLoading";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginSuccess: userSuccess, data: userData } = useSelector(({ auth }) => auth);

  const {
    districts: { data: districts, loading: districtLoading, success: districtSuccess },
    createDistrict: {loading: createDistrictLoading}
  } = useSelector(({ district }) => district);

  // const { role, employer_code } = userData?.user;
  // const isSuperAdmin = role === "super_admin";
  // const isDistrictAdmin = role === "admin" && !!employer_code;
  // const isBuildingAdmin = role === "building_admin" && !!employer_code;
  // const isInspector = role === "inspector" && !!employer_code;

  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [openDistrictModal, setOpenDistrictModal] = useState(false);
  const toggleDistrictModal = () => setOpenDistrictModal((prev) => !prev);

  useEffect(() => {
    if (userSuccess && !userData.user) {
      dispatch(getUserDetails());
    }
    if (userData.user) {
      const { role, employer_code } = userData?.user;
      const isSuperAdmin = role === "super_admin";
      const isDistrictAdmin = role === "admin" && !!employer_code;
      const isBuildingAdmin = role === "building_admin" && !!employer_code;
      const isInspector = role === "inspector" && !!employer_code;
      if (isInspector || isDistrictAdmin || isBuildingAdmin) {
        navigate("/overview", {
          state: {
            districtId: selectedDistrict?.id,
          },
        });
      }
      if (isSuperAdmin && !districtLoading && !districtSuccess) {
        dispatch(fetchDistricts());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSuccess]);

  // useEffect(() => {
  //   if (userSuccess) {
  //     if (isInspector || isDistrictAdmin || isBuildingAdmin) {
  //       navigate("/overview", {
  //         state: {
  //           districtId: selectedDistrict?.id,
  //         },
  //       });
  //     }
  //     if (isSuperAdmin && !districtLoading && !districtSuccess) {
  //       dispatch(fetchDistricts());
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userSuccess]);

  return (

    <>
      <NavBar />
      <nav className="navbar navbar-light justify-content-between navbar-container">
        <div className="container-fluid">
          <div className="ms-auto">
            <button
              className="btn btn-outline-secondary d-flex align-items-center shadow-none"
              onClick={toggleDistrictModal}
            >
              <i className="fa-solid fa-plus me-1"></i>
              District
            </button>
          </div>
        </div>
      </nav>
      <div className="position-relative">
        <InnerLoading show={districtLoading} />
        {districts.length === 0 && !districtLoading && (
          <p className="d-flex align-items-center justify-content-center p4 mt-5">No districts to show</p>
        )}
        {districtSuccess && districts.length > 0 && (
          <>

            <main className="container-fluid container-xxl overflow-auto">
              <DistrictList onDistrictSelected={setSelectedDistrict} />
            </main>
          </>
        )}
        {openDistrictModal && (
          <DistrictForm
            loading={createDistrictLoading}
            openDistrictModal={openDistrictModal}
            onCloseDistrictModal={toggleDistrictModal}
          />
        )}
        </div>
      </>
      );
};

      export default Dashboard;
