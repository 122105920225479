import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { usePreview } from "react-dnd-preview";
import { useDispatch, useSelector } from "react-redux";

import DeleteModal from "../../../components/Dashboard/OverviewComponents/DeleteModal";
import { deleteRoom } from "../../../services/section";
import useWindowSize from "../../../utils/useWindowSize";
import EditRoomForm from "./EditRoomForm";
import RoomForm from "./RoomForm";
import plusIcon from "../../Dashboard/assets/plus-icon.svg";
import deleteIcon from "../../Dashboard/assets/delete-icon.svg";
import editIcon from "../../Dashboard/assets/edit-icon.svg";
import {closeEditRoomInspectionItemsView, openEditRoomInspectionItemsView, startInspection, stopInspection} from "features/inspection/inspectionSlice"
import { toast } from "react-toastify";

import InnerLoading from "components/InnerLoading"
import { fetchDistrictCleaners, fetchSchoolCleaners } from "services/cleaner";

export default function SectionsDetails({
  school = {},
  sectionDetails,
  rooms,
  district,
  isSuperAdmin,
  isDistrictAdmin,
  isInspector,
  handleBackClick,
}) {
  const dispatch = useDispatch();
  const { width: screenWidth } = useWindowSize();
  const {
    room: { success: roomsSuccess, loading: roomsLoading },
    createRoom: { success: createRoomSuccess, error: createRoomError },
    editRoom: { success: editRoomSuccess , error: editRoomError},
    deleteRoom: {loading: deleteRoomLoading},
  } = useSelector(({ section }) => section);

  const {
    schoolCleaners: { loading: cleanersLoading, success: cleanersSuccess, error: fetchError, data: cleaners = [] },
  } = useSelector(({ cleaner }) => cleaner)


  useEffect(() => {
    if (!cleanersSuccess) {
      dispatch(fetchSchoolCleaners({schoolId: school.id}));
    }
  }, []);


  useEffect(() => {
    if (roomsSuccess) {
      const updatedRoom = rooms.find((room) => room.id === selectedRoom?.id);
      setSelectedRoom(updatedRoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomsSuccess,rooms]);

  useEffect(() => {
    if (createRoomSuccess) {
      toast.success("Room successfully added");
    }
    if (createRoomError) {
      toast.error("Room could not be added");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createRoomSuccess, createRoomError]);

  useEffect(() => {
    if (editRoomSuccess) {
      toast.success("Room successfully updated");
    }
    if (editRoomError) {
      toast.error("Room could not be updated");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRoomSuccess, editRoomError]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState();
  const [hideBtn, setHideBtn] = useState(false);
  const [openSectionModal, setOpenSectionModal] = useState(false);
  const [openRoomEditForm, setOpenRoomEditForm] = useState(false);


  const onOpenDeleteModal = () => setOpenDeleteModal(true);
  const onCloseDeleteModal = () => setOpenDeleteModal(false);

  const onOpenRoomEditModal = () => setOpenRoomEditForm(true);
  const onCloseRoomEditModalModal = () => setOpenRoomEditForm(false);

  const onOpenModal = () => setOpenSectionModal(true);
  const onCloseModal = () => setOpenSectionModal(false);

  const handleDelete = async () => {
    const obj = {
      roomId: selectedRoom.id,
      sectionDetails: sectionDetails,
      school: school.id,
      district: district,
    };
    await dispatch(deleteRoom(obj));
    setOpenDeleteModal(false);
  };

  const handleClick = (room) => {
    if (isInspector) {
      dispatch(startInspection(room))
    }
    setSelectedRoom(room);
    setHideBtn(true);
  };
  const onEditRoomClick = (room, evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setSelectedRoom(room);
    onOpenRoomEditModal();
  }
  const onDeleteRoomClick = (room, evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setSelectedRoom(room);
    onOpenDeleteModal();
  }

  const getCleanerName = (cleanerId) => {
    const cleaner = cleaners.find((c) => c.id === cleanerId);
    return cleaner?.name || "";
  };
  const handleEditInspectionItemsClick = (room) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(openEditRoomInspectionItemsView(room));
  }

  return (
      <div className="section-details">
        <div className="d-flex justify-content-between section-details-header align-items-center">
          <div className="section-header align-items-center">
            <h5>{sectionDetails.name}</h5>
          </div>
              <div className="d-flex header-button justify-content-between">
              {hideBtn && (
                <>
                  {selectedRoom && (
                    <button
                      className="btn btn-outline-secondary d-flex align-items-center shadow-none"
                      onClick={onOpenRoomEditModal}
                    >
                      <img src={editIcon} alt="" className="image-responsive" />
                    </button>
                  )}
                  {selectedRoom && (
                    <button
                      className="mx-3 btn btn-outline-secondary d-flex align-items-center text-uppercase shadow-none"
                      onClick={onOpenDeleteModal}
                    >
                      <img
                        src={deleteIcon}
                        alt=""
                        className="image-responsive"
                      />
                    </button>
                  )}
                </>
              )}
              <button
                className="btn btn-outline-secondary d-flex align-items-center text-uppercase shadow-none"
                onClick={onOpenModal}
              >
                <img src={plusIcon} alt="" className="mr5 image-responsive" />
                <span>Rooms</span>
              </button>{" "}
            </div>
            </div>
            <div className="section-details-content">
              <InnerLoading show={roomsLoading}/>
              {rooms.length == 0 && !roomsLoading && (
                <p className="p-4 text-center">No rooms to show</p>
              )}
              {roomsSuccess && rooms.length > 0 && (
              <div className="inner-tab-section-list-holder-without-scroll room-block position-relative">
                <ul className="section-list-block p-0 m-0 position-relative">
                  {rooms.map((room) => {
                    const cleanerName = getCleanerName(room.cleaner);
                    return(
                    <li
                      id={`inner-tab-section-list-item-${room.id}`}
                      key={`sectionRoom${room.id}`}
                      onClick={() => {
                        handleClick(room);
                      }}
                      className={`border d-flex flex-column inner-tab-section-list-item justify-content-between mt-4 cursor-pointer ${
                        selectedRoom?.id === room.id ? "active-item" : ""
                      }`}
                    >
                      <div className="list-label">
                        
                        {room.name}
                         
                      </div>
                      <div className="room-details d-flex align-items-center mt-2 mt-lg-0 flex-wrap">
                      {
                        room?.roomFeatures?.map((feature)=><span> <b>{feature?.quantity}</b> {feature?.name} </span>)
                      }
                        {cleanerName.length > 0 && (
                          <span className="me-3"><i className="fa-solid fa-user me-2"></i>{cleanerName}</span>
                        )}
                      <div className="room-actions ms-auto d-flex align-items-center">
                        {(isSuperAdmin || isDistrictAdmin) && (
                          <button
                            className="ms-2 btn btn-outline-primary btn-small d-flex align-items-center shadow-none"
                            onClick={handleEditInspectionItemsClick(room)}
                            title="Edit this room inspection items"
                          >
                          <i className="fa-solid fa-circle-question"></i>
                          </button>
                        )}
                          <button
                            className="mx-2 btn btn-outline-primary btn-small d-flex align-items-center shadow-none"
                            onClick={(evt) => onEditRoomClick(room, evt)}
                            title="Edit this room"
                          >
                          <i className="fa-sharp fa-solid fa-pen"></i>
                          </button>
                          <button
                            className="ms-2 btn btn-outline-primary btn-small d-flex align-items-center shadow-none"
                            onClick={(evt) => onDeleteRoomClick(room, evt)}
                            title="Delete this room"
                          >
                          <i className="fa-sharp fa-solid fa-trash"></i>
                          </button>
                      </div>
                      </div>
                    </li>
                  )})}
                </ul>
              </div>
              )}
            </div>
        {openDeleteModal && (
          <DeleteModal
            loading={deleteRoomLoading}
            open={openDeleteModal}
            onOpenModal={onOpenDeleteModal}
            onCloseModal={onCloseDeleteModal}
            onDelete={handleDelete}
            message="Room"
          />
        )}

        {openSectionModal && (
          <RoomForm
            open={openSectionModal}
            onCloseModal={onCloseModal}
            sectionDetails={sectionDetails}
            school={school}
            district={district}
          />
        )}
        {openRoomEditForm && selectedRoom && (
          <EditRoomForm
            open={openRoomEditForm}
            onCloseModal={onCloseRoomEditModalModal}
            school={school}
            selectedRoom={selectedRoom}
          />
        )}
        </div>
  );
}
