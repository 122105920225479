import { Axios } from "../../api/axios";

import { getHeader, toastMsg } from "../../utils/utility";
import { getSimplifiedError } from "../../utils/error";
import {
  fetchReportsFailure,
  fetchReportsSuccess,
  setBlockLoading,
  fetchGraphReportFailure,
  fetchGraphReportSuccess,
} from "../../features/report/reportSlice";
import { NOTIFICATION } from "constants/constants";

export const fetchInspectReport = (filterBy, roomType) => async (dispatch) => {
  try {
    dispatch(setBlockLoading({ blockType: "reports" }));
    let url = `/api/v1/inspection-report/?filter_by=${filterBy}`;
    if (roomType) {
      url += `&room_type=${roomType}`;
    }
    const response = await Axios.get(url, getHeader());
    if (roomType) {
      url += `&room_type=${roomType}`;
    }
    dispatch(fetchReportsSuccess(response));
  } catch (error) {
    dispatch(fetchReportsFailure(getSimplifiedError(error)));
  }
};

export const fetchInspectReportGraph =
  ({ roomType, type, pk, filter, startDate, endDate, timeRange }) =>
  async (dispatch) => {
    try {
      dispatch(setBlockLoading({ blockType: "reportGraph" }));
      let url = `/api/v1/inspection-report/time-series/?filter_by=${type}&pk=${pk}`;
      if (roomType) {
        url += `&room_type=${roomType}`;
      }
      if (filter) {
        if (type === "districts") {
          url += `&school=${filter}`;
        } else if (type === "schools") {
          url += `&cleaner=${filter}`;
        }
      }
      if (startDate && endDate) {
        const start_date = new Date(startDate).toISOString().split("T")[0];
        const end_date = new Date(endDate).toISOString().split("T")[0];
        url += `&custom_date_range=${start_date},${end_date}`;
      } else if (timeRange) {
        url += `&time_range=${timeRange}`;
      }

      const response = await Axios.get(url, getHeader());
      if (roomType) {
        url += `&room_type=${roomType}`;
      }
      dispatch(fetchGraphReportSuccess(response));
    } catch (error) {
      dispatch(fetchGraphReportFailure(getSimplifiedError(error)));
    }
  };

export const sendInspectionEmail = async (setProcessing) => {
  try {
    setProcessing(true);
    const url = `/api/v1/trigger_user_reports_test/`;
     await Axios.post(
      url,
      {},
      getHeader()
    );
    
    toastMsg("Email Request has been send")
  } catch (error) {
    console.error("send inspection email error",error);
    toastMsg(error.message,NOTIFICATION.error)
  } finally {
    setProcessing(false);
  }
};

///api/v1/inspection/daily-inspection-result/


export const getPrintableDailyInspectionReport = async (id) => {
  try {
    const url = `/api/v1/inspection/${id}/print_inspection_report/`;
     const res=await Axios.get(
      url,
      getHeader()
    );
    return res;
  } catch (error) {
    console.error("printable inspection email error",error);
    toastMsg(error.message,NOTIFICATION.error)
  }
};