import { createSlice } from "@reduxjs/toolkit";

const block = {
  loading: false,
  error: "",
  success: false,
};

const initialState = {
  workloads: { ...block, data: [] },
  isAssignCleanersProcessing: false,
};

const workloadSlice = createSlice({
  name: "workload",
  initialState,
  reducers: {
    setBlockLoading(state, { payload: { blockType, loading = true } }) {
      state[blockType] = { ...state[blockType], loading };
    },
    fetchWorkLoadSuccess: ({ workloads }, action) => {
      workloads.data = action.payload;
      workloads.loading = false;
      workloads.success = true;
      workloads.error = "";
    },
    fetchWorkLoadFailure: ({ workloads }, action) => {
      workloads.error = action.payload;
      workloads.loading = false;
      workloads.success = false;
    },
    assignCleanerProcessing(state, action) {
      state.isAssignCleanersProcessing = action.payload;
    },
  },
});

export const {
  fetchWorkLoadFailure,
  fetchWorkLoadSuccess,
  setBlockLoading,
  assignCleanerProcessing,
} = workloadSlice.actions;
export default workloadSlice.reducer;
