import React, { useState } from "react";
import eye from "../assets/images/eye.svg";

const InputPassword = (props) => {
  const { title, name, onChange = () => {}, value, error } = props;
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="mb-4">
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="form-floating password-holder">
        <input
          type={showPassword ? "text" : "password"}
          className="form-control"
          id="floatingPassword"
          placeholder={title}
          name={name}
          value={value}
          onChange={onChange}
          required
        />
        <label htmlFor="floatingPassword">{title}</label>
        <img
          src={eye}
          alt=""
          onClick={() => setShowPassword(!showPassword)}
          className="image-responsive eye-icon"
          id="togglePassword"
        />
      </div>
    </div>
  );
};

export default InputPassword;
