import React from "react";

import BaseModal from "components/BaseModal";

const DeleteModal = ({ open, message, onDelete, onCloseModal, loading = false, title='' }) => {
  return (
    <BaseModal
      onConfirm={onDelete}
      onClose={onCloseModal}
      show={open}
      title={`Delete ${title ? title : message}`}
      className="text-center"
      formLoading={loading}
      content={<p>Are you sure you want to delete {message}?</p>}
    />
  );
};

export default DeleteModal;
