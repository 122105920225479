import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import NavBar from "../../components/MainNavbar.js";

import { Form, Button, Card, Spinner } from "react-bootstrap";
import { updateProfile } from "services/admin";
import CSRFToken from "utils/csrfToken.js";
import person from "../../components/Dashboard/assets/profile.svg";

const Profile = () => {
  const dispatch = useDispatch();

  const { loginSuccess: userSuccess, data: userData } = useSelector(
    ({ auth }) => auth
  );
  const { loading } = useSelector(({ admin }) => admin.updateUser);

  const {
    id,
    firstName: fname,
    lastName: lname,
    email,
    username,
    role,
  } = userData?.user;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSuccess]);
  const [firstName, setFirstName] = useState(fname);
  const [lastName, setLastName] = useState(lname);


  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      updateProfile(id, {
        firstName,
        lastName,
        username,
      })
    );
  };

  return (
    <>
      <NavBar />
      <Card className="mx-auto mt-4" style={{ maxWidth: "600px" }}>
        <div className="text-center my-3">
          <img
            src={person}
            alt="User Avatar"
            className="rounded-circle"
            style={{ height: "150px", width: "150px" }}
          />
          <h5 className="mt-2">{firstName + " " + lastName}</h5>
        </div>
        <Card.Body>
          <Card.Title className="text-center mx-3">
            Update Your Profile
          </Card.Title>
          <Form onSubmit={handleSubmit}>
            <CSRFToken />
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                value={firstName}
                onChange={handleFirstNameChange}
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                value={lastName}
                onChange={handleLastNameChange}
              />
            </Form.Group>
            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Form.Control type="text" value={role} disabled={true} />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={email} disabled={true} />
            </Form.Group>
            {loading ? (
              <div className="me-3">
                <Spinner variant="primary" />
              </div>
            ) : (
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default Profile;
