import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AdminList from "../../components/Dashboard/AdminList";
import District from "../../components/Dashboard/District";
import DistrictEditForm from "../../components/Dashboard/DistrictEditForm";

const Content = ({ onDistrictSelected }) => {
  const navigate = useNavigate();

  const [openEditDistrictModal, setOpenEditDistrictModal] = useState(false);

  const toggleEditDistrictModal = () =>
    setOpenEditDistrictModal((prev) => !prev);

  const {
    districts: { data },
    updateDistrict: {loading: updateDistrictLoading},
  } = useSelector(({ district }) => district);

  const [editedDistrict, setEditedDistrict] = useState({
    id: "",
    name: "",
    logo: "",
    maxBuildingsAllowed:"",
    subscriptionStartDate:null,
    subscriptionEndDate:null,
  });

  const [selectedDistrict, setSelectedDistrict] = useState(0);

  const selectDistrict = (id) => {
    let updatedDistrict = data.find((district) => district.id === id);
    setSelectedDistrict(updatedDistrict.id);
    onDistrictSelected(updatedDistrict);
  };

  const editFormHandler = (district) => {
    setEditedDistrict({
      id: district.id,
      name: district.name,
      logo: district.logo,
      maxBuildingsAllowed:district?.maxBuildingsAllowed,
      subscriptionStartDate:district?.subscriptionStartDate,
      subscriptionEndDate:district?.subscriptionEndDate,
    });
    toggleEditDistrictModal();
  };

  const handleClick = (districtId) => {

    navigate(`/districts/${districtId}`, {
      state: {
        districtId: districtId,
      },
    });
  };

  return (
    <>
      <div className="row district-holder mb-4 mt--4">
        {data.length > 0 &&
          data.map((district) => {
            return (
              <div key={`district${district.id}`} className="col-lg-3 col-md-4 col-12 mt-4 d-flex justify-content-center justify-content-md-start">
                <District
                  district={district}
                  selected={selectedDistrict}
                  onSelectDistrict={selectDistrict}
                  onEditDistrict={editFormHandler}
                  handleDistrictClick={() => handleClick(district.id)}
                />
              </div>
            );
          })}
    </div>
    {openEditDistrictModal && (
      <DistrictEditForm
        loading={updateDistrictLoading}
        district={editedDistrict}
        openEditDistrictModal={openEditDistrictModal}
        CloseEditDistrictModal={toggleEditDistrictModal}
      />
    )}

    <AdminList districtId={selectedDistrict} />
  </>
  );
};

export default Content;
