
export function getUserHomeURL(user) {
    let url = '/users';
    if (!user) {
        return url;
    }
    const role = user.role;
    const isDistrictAdmin = role === "district_admin";
    const isInspector = role === "inspector";
    let districtId;
    let schoolId;

    if (isDistrictAdmin) {
        let districts = user.districts;
        districtId = districts[0].id;
        url = districtId ? `/districts/${districtId}` :  '/404';
    } else if (isInspector) {
        let schools = user.schools;
        schoolId = schools[0].id;
        url = schoolId ? `/schools/${schoolId}` :  '/404';
    }
    return url

}