import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import adminReducer from "../features/admin/adminSlice";
import districtReducer from "../features/district/districtSlice";
import schoolReducer from "../features/school/schoolSlice";
import sectionSlice from "../features/section/sectionSlice";
import inspectionSlice from "features/inspection/inspectionSlice";
import cleanerSlice from "features/cleaner/cleanerSlice";
import reportSlice from "features/report/reportSlice";
import workloadSlice from "features/workload/workloadSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  district: districtReducer,
  school: schoolReducer,
  section: sectionSlice,
  inspection: inspectionSlice,
  cleaner: cleanerSlice,
  report: reportSlice,
  workload:workloadSlice,
});

export default persistReducer(persistConfig, rootReducer);
