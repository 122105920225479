export function getDateTime(date = Date.now()) {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
}
export function createInspectionsGroup({
    created = Date.now(),
    inspectedBy,
    inspectedByName,
}) {
    var todaysTime = getDateTime();
    var inspectionTime = getDateTime(created);
    var isTodays = todaysTime === inspectionTime;
    return { created: inspectionTime, inspectedBy, inspectedByName, isTodays };
}

export function getInspectionOfDate(inspections, date) {
    const time = getDateTime(date);
    return inspections.filter(({ created }) => {
        return time === getDateTime(created);
    });
}
export function getInspectionsGroupRooms(inspections, rooms, date) {
    var inspections = getInspectionOfDate(inspections, date);
    return rooms.filter(({ id }) => {
        return !!inspections.find(({ room }) => id === room);
    });
}

export function getModelDate(model) {
    if (!model) {
        return 
    }
    return model.created;
}

export function isDateToday(date) {
    if (!date) {
      return false;
    }
    let dateTime = getDateTime(date)
    let todaysTime = getDateTime();
    return dateTime === todaysTime;
}
