import BaseModal from "components/BaseModal";
import { useEffect, useState } from "react";
const SingleValueForm = ({ 
  defaultValue = '', 
  open, 
  onCloseModal, 
  placeholder,
  title,
  valueName,
  label,
  loading,
  onSubmit,
  ...props
}) => {

  const [value, setValue] = useState("")
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const submitHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(value, e);

  };

  const handleChange = ({ target: { value } }) => {
    setValue(value)
  };

  return (
    <BaseModal
      {...props}
      onSubmit={submitHandler}
      onClose={onCloseModal}
      formLoading={loading}
      show={open}
      title={title}
      submitButtonText={"Save"}
      formWrapper={true}
      size="sm"
      content={
        <>
          <div className="mb-4">
            <div className="form-floating mb-3 go-bottom">
              <input
                type="text"
                className="form-control"
                name={valueName}
                value={value}
                onChange={handleChange}
                required
                autoFocus
                placeholder={placeholder}
              />
              <label htmlFor={valueName}>{label}</label>
            </div>
          </div>
        </>
      }
    />
  );
};

export default SingleValueForm;
