import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editDefaultRoomFeature } from "../../services/section";
import BaseModal from "components/BaseModal";
import { Container, Row, Col, Button } from "react-bootstrap";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  createDefaultRoomFeature,
  fetchDefaultRoomFeatures,
  removeDefaultRoomFeature,
} from "../../services/section";
import { generateUniqueId,convertMinutesToSeconds, convertToHoursMinutesSeconds } from "utils/utility";
import RoomFeaturesTable from "components/RoomFeatures/RoomFeaturesTable";
import { bulkUpdateItems, createRoomType,updateRoomType } from "services/inspection";
const RoomForm = ({ onCloseModal, open, id, name,workloadInMinutes }) => {
    const defaultActionType=id?"editRoomType":"addRoomType";
  const [actionType, setActionType] = useState(defaultActionType);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalDetails, setConfirmModalDetails] = useState({
    title: "",
    message: "",
    id: "",
  });
  const [roomFeatureDetail, setRoomFeatureDetail] = useState({
    name: "",
    quantity: "",
    timeInMinutes: "",
  });
  const [roomFeatures, setRoomFeatures] = useState([]);
  const dispatch = useDispatch();
  const {
    roomDefaultFeatures: {
      data,
      loading: roomDefaultFeatureLoading,
      roomDefaultFeatureProcessing,
    },
    roomFeatureProcessing,
  } = useSelector(({ section }) => section);

  const {
    createRoomType: {
      loading: createRoomLoading,
    },
    updateRoomType: {
      loading: updateRoomLoading,
    } } = useSelector(({ inspection }) => inspection);

  const [roomDetail, setRoomDetail] = useState({
    name,
    workloadInMinutes,
  });
  const submitHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const toggleEdit = () => setActionType(defaultActionType);
    if (actionType === "addFeature") {
        if (id) {
          dispatch(
            createDefaultRoomFeature(
              { roomType:id, ...roomFeatureDetail,timeInSeconds:convertMinutesToSeconds(roomFeatureDetail?.timeInMinutes) },
              toggleEdit
            )
          );
        } else {
          const id = generateUniqueId();
          
          const data = { id,...roomFeatureDetail,timeInSeconds:convertMinutesToSeconds(roomFeatureDetail?.timeInMinutes) };
          setRoomFeatures((prev) => [...prev, data]);
          toggleEdit();
        }
      } else if (actionType === "editFeature") {
        if (id) {
          dispatch(
            editDefaultRoomFeature(
              { roomType:id, ...roomFeatureDetail },
              toggleEdit
            )
          );
        } else {
          const updatedRoomFeatures = roomFeatures.map((updatedFeature) => {
            if (updatedFeature.id === roomFeatureDetail.id) {
              return { ...updatedFeature, ...roomFeatureDetail,timeInSeconds:convertMinutesToSeconds(roomFeatureDetail?.timeInMinutes)  };
            }
            return updatedFeature;
          });
          setRoomFeatures(updatedRoomFeatures);
          toggleEdit();
        }
      }else {
       
        if (id) {
           dispatch(updateRoomType({roomType:{id,name:roomDetail?.name}},onCloseModal))
          } else {
            const successCb=(id)=>{
                dispatch(bulkUpdateItems({ roomId:id, items:[],closeModal:onCloseModal }))
            }
             dispatch(createRoomType({ name:roomDetail?.name},successCb,roomFeatures));
             
          }
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setRoomDetail({ ...roomDetail, [name]: value });
  };
  const handleFeatureChange = ({ target: { name, value } }) => {
    setRoomFeatureDetail({ ...roomFeatureDetail, [name]: value });
  };
  const toggleConfirMModal = () => {
    setConfirmModal(!confirmModal);
  };

  const removeFeatureHandler = (feature) => {
    setConfirmModalDetails((prev) => ({
      ...prev,
      title: "Remove Room Feature",
      message: `Are You sure you want to remove this feature?`,
      id: feature.id,
    }));

    toggleConfirMModal();
  };
  const onConfirm = () => {
    try {
      const cb = () => {
        setConfirmModalDetails({ id: "", message: "", title: "" });
      };
      if (id) {
        dispatch(
          removeDefaultRoomFeature(
            { id: confirmModalDetails.id },
            toggleConfirMModal,
            cb
          )
        );
      } else {
        const filteredFeatures = roomFeatures.filter(
          (item) => item.id !== confirmModalDetails?.id
        );
        setRoomFeatures(filteredFeatures);
        cb();
        toggleConfirMModal();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const editFeatureHandler = (feature) => {
    setRoomFeatureDetail(feature);
    setActionType("editFeature");
  };
  useEffect(() => {
    if (id !== "") {
      dispatch(fetchDefaultRoomFeatures(id));
    }
  }, [id]);
  const totalWorkload = useMemo(() => {
    const features = id?data|| []
    : roomFeatures || []
    let totalSeconds = 0;
    features.forEach((feature) => {
      totalSeconds += (feature.timeInSeconds || 0) * (feature.quantity || 0);
    });
 
    return convertToHoursMinutesSeconds(totalSeconds);
  }, [roomFeatures, id, data]);
  return (
    <BaseModal
      onSubmit={submitHandler}
      onClose={
        actionType.includes("Feature")
          ? () => setActionType(defaultActionType)
          :onCloseModal
      }
      show={open}
      title={
        actionType === "addFeature"
          ? `Add Default Feature`
          : actionType === "editFeature"
          ? `Edit  Default Feature`
          : "Room Type"
      }
      submitButtonText={actionType.includes("edit") ? "Update" : "Add"}
      formWrapper={true}
      formLoading={
        actionType.includes("Feature")
          ? roomFeatureProcessing || roomDefaultFeatureProcessing
          : createRoomLoading||updateRoomLoading
      }
      size={"lg"}
      content={
        <>
          <Container fluid>
            {actionType.includes("Feature") ? (
              <Row>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="name"
                      value={roomFeatureDetail?.name}
                      placeholder="Feature Name"
                      onChange={handleFeatureChange}
                    />
                    <label htmlFor="name">Feature Name</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      type="number"
                      className="form-control"
                      name="quantity"
                      value={roomFeatureDetail?.quantity}
                      placeholder="Quantity"
                      onChange={handleFeatureChange}
                      min={0}
                    />
                    <label htmlFor="quantity">Quantity</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      type="number"
                      className="form-control"
                      name="timeInMinutes"
                      value={roomFeatureDetail?.timeInMinutes}
                      placeholder="Time in Minutes"
                      onChange={handleFeatureChange}
              
                    />
                    <label htmlFor="timeInMinutes">Time In Minutes</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="workloadInMinutes"
                      value={convertToHoursMinutesSeconds(
                        (roomFeatureDetail?.quantity || 0) *
                          ((roomFeatureDetail?.timeInMinutes|| 0)*60)
                      )}
                      placeholder="Workload"
                      disabled
                    />
                    <label htmlFor="workloadInMinutes">Workload</label>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="name"
                      value={roomDetail?.name}
                      placeholder="Name"
                      onChange={handleChange}
                    />
                    <label htmlFor="Name">Name</label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-floating mb-4 go-bottom">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="totalWorkload"
                      value={roomDefaultFeatureLoading?convertToHoursMinutesSeconds(roomDetail?.workloadInSeconds||0):totalWorkload}
                      placeholder="Workload"
                      disabled
                    />
                    <label htmlFor="totalWorkload">Total Workload</label>
                  </div>
                </Col>
                <Col
                  md={12}
                  className="d-flex flex-wrap align-items-center justify-content-between flex-grow-1"
                >
                  <h5>  Default Features </h5>
                  <Button
                    variant="light"
                    size="sm"
                    className="btn-unset-height"
                    onClick={() => {
                      setActionType("addFeature");
                      setRoomFeatureDetail({
                        name: "",
                        quantity: "",
                        timeInMinutes: "",
                      });
                    }}
                  >
                    Add  Default Feature
                  </Button>
                </Col>
                <Col md={12} className="mt-2">
                  <RoomFeaturesTable
                    loading={roomDefaultFeatureLoading}
                    data={id?data || []:roomFeatures||[]}
                    removeFeatureHandler={removeFeatureHandler}
                    editFeatureHandler={editFeatureHandler}
                  />
                </Col>
              </Row>
            )}
          </Container>
          <ConfirmationModal
            show={confirmModal}
            onCancel={toggleConfirMModal}
            title={confirmModalDetails?.title}
            message={confirmModalDetails?.message}
            onConfirm={onConfirm}
            loading={roomFeatureProcessing || roomDefaultFeatureProcessing}
          />
        </>
      }
    />
  );
};
export default RoomForm;
