import Cookies from "js-cookie";
import { Axios } from "../../api/axios";
import {
  setBlockLoading,
  updateUsersFailure,
  updateUsersSuccess,
} from "../../features/admin/adminSlice";
import {
  forgotPasswordFailure,
  forgotPasswordSuccess,
  getUserProfileFailure,
  getUserProfileSuccess,
  loginFailure,
  loginSuccess,
  singupFailure,
  singupSuccess,
  logoutSuccess,
  loginMagicRequestSuccess,
  loginMagicRequestFailure,
  loginMagicSuccess,
  loginMagicFailure,
} from "../../features/auth/authSlice";
import { getSimplifiedError } from "../../utils/error";
import { toastMsg } from "utils/utility";
import { ERRORS, NOTIFICATION } from "constants/constants";

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("/api/v1/auth/login/", {
        email,
        password,
      });

      const { refreshToken, accessToken } = response;
      const options = { path: "/" };
      Cookies.set("token", accessToken, options);
      Cookies.set("refresh", refreshToken, options);
      dispatch(loginSuccess(response));
    } catch (error) {
      dispatch(loginFailure(getSimplifiedError(error)));
    }
  };

export const logout = () => async (dispatch) => {
  try {
    const refresh = Cookies.get("refresh");
    await Axios.post("/api/v1/auth/logout/", {
      refresh: refresh,
    });
    Cookies.remove("token", { path: "/" });
    Cookies.remove("refresh", { path: "/" });
    dispatch(logoutSuccess());
  } catch (error) {
    console.log(error);
  }
};

export const signup =
  ({
    first_name,
    last_name,
    email,
    phone_no,
    password,
    confirm_password,
    employer_code,
  }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("rest-auth/registration/", {
        first_name,
        last_name,
        email,
        phone: phone_no,
        password1: password,
        password2: confirm_password,
        employer_code,
      });

      dispatch(singupSuccess(response));
    } catch (error) {
      dispatch(singupFailure(getSimplifiedError(error)));
    }
  };

export const sendMagicLink =
  ({ email }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("/api/v1/auth/magic/login/", {
        email,
      });

      dispatch(loginMagicRequestSuccess(response));
    } catch (error) {
      dispatch(loginMagicRequestFailure(error.response.data.message));
    }
  };

export const verifyMagicLink =
  ({ token }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("/api/v1/auth/magic/login/access/", {
        token,
      });
      const { refreshToken, accessToken} = response;
      const options = { path: "/" };
      Cookies.set("token", accessToken, options);
      Cookies.set("refresh", refreshToken, options);
      dispatch(loginMagicSuccess(response));
    } catch (error) {
      dispatch(loginMagicFailure(error.response.data.message));
    }
  };

export const sendUserInvite = (payload) => async (dispatch) => {
  dispatch(setBlockLoading({ blockType: "updateUser" }));
  try {
    const response = await Axios.post("/api/v1/auth/magic/invite/", payload);

    dispatch(updateUsersSuccess(response));
    toastMsg("User invited successfully")
  } catch (error) {
    dispatch(updateUsersFailure(error.response.data.message));
    toastMsg(error?.response?.data?.message||ERRORS.common,NOTIFICATION.error)
  }
};
export const resendUserInvite = (payload, setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    let url="/api/v1/auth/magic/invite/"
      await Axios.post(url, payload);
    toastMsg("User reinvited successfully")
  } catch (error) {
    console.error(error);
    toastMsg(error?.response?.data?.message||ERRORS.common,NOTIFICATION.error)
  } finally {
    setLoading(false);
  }
};

export const magicPasswordReset =
  ({ uid, token, password, confirm_password }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("/api/v1/auth/magic/change/password/", {
        uid,
        token,
        new_password1: password,
        new_password2: confirm_password,
      });

      dispatch(forgotPasswordSuccess(response));
    } catch (error) {
      dispatch(forgotPasswordFailure(getSimplifiedError(error)));
    }
  };

export const forgotPassword =
  ({ email }) =>
  async (dispatch) => {
    try {
        await Axios.post("/api/v1/auth/password/reset/", {
        email,
      });

      // dispatch(forgotPasswordSuccess(response));
    } catch (error) {
      dispatch(forgotPasswordFailure(getSimplifiedError(error)));
    }
  };

export const resetPassword =
  ({ uid, token, password, confirm_password }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("/rest-auth/password/reset/confirm/", {
        uid,
        token,
        new_password1: password,
        new_password2: confirm_password,
      });

      dispatch(forgotPasswordSuccess(response));
    } catch (error) {
      dispatch(forgotPasswordFailure(getSimplifiedError(error)));
    }
  };

export const getUserDetails = () => async (dispatch) => {
  try {
    const response = await Axios.get("/api/v1/auth/user/");

    dispatch(getUserProfileSuccess(response));
  } catch (error) {
    dispatch(getUserProfileFailure(getSimplifiedError(error)));
  }
};
