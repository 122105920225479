import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./WorkLoad.scss";
import NavBar from "../../components/MainNavbar";
import {
  Row,
  Col,
  Form,
  Nav,
  Container,
  ListGroup,
  ListGroupItem,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import {
  assignCleanersMultipleRoom,
  fetchWorkLoad,
} from "services/workload/workloadService";
import WorkLoaDatble from "components/WorkLoad/WorkLoadTable";
import { fetchCleaners } from "services/cleaner";
import {
  convertToHoursMinutesSeconds,
  sortByProperty,
  toastMsg,
} from "utils/utility";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import Loader from "components/Loader";
import { fetchBuildings } from "services/school";
import {
  getSpecificRoom,
  removeCleanerFromAllRooms,
  removeRoomCleaners,
} from "services/section";
import { NOTIFICATION } from "constants/constants";
import WorkloadEditRoom from "components/WorkLoad/WorkloadEditRoom";
const WorkLoad = () => {
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [roomId, setRoomID] = useState("");
  const [roomDistrictId,setRoomDistrictId]=useState('')
  const [confirmModal, setConfirmModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [selectedCleaner, setSelectedCleaner] = useState({
    id: "",
    name: "",
  });
  const {
    cleaners: { loading: cleanersLoading, data: cleaners },
  } = useSelector(({ cleaner }) => cleaner);
  const {
    buildings: { loading: buildingLoading, data: buildings },
  } = useSelector(({ school }) => school);
  const user = useSelector(({ auth }) => auth.data?.user);
  const role = user?.role;
  const districtId = user?.districts?.[0]?.id;
  const isDistrictAdmin = role === "district_admin";
  const dId = isDistrictAdmin ? districtId : "";
  const dispatch = useDispatch();
  const {
    workloads: { success, loading, data },
    isAssignCleanersProcessing,
  } = useSelector(({ workload }) => workload);
  const {
    editRoom: { loading: editRoomProcessing } = { loading: false },
} = useSelector(({ section }) => section);
  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };
  const [buildingId, setBuildingId] = useState("");
  useEffect(() => {
    if (buildings.length === 0) {
      dispatch(fetchBuildings(dId));
    }
  }, [dispatch, isDistrictAdmin, districtId]);
  useEffect(() => {
    dispatch(fetchWorkLoad(buildingId));
    dispatch(fetchCleaners(buildingId, dId));
  }, [dispatch, buildingId]);
  const calculateTotalWorkload = () => {
    let totalSeconds = 0;
    cleaners.forEach((cleaner) => {
      totalSeconds += cleaner.workloadInSeconds || 0;
    });
   
    return convertToHoursMinutesSeconds(totalSeconds);
  };
  const sortedCleaners = sortByProperty(cleaners, "name");
  const removeAllHandler = (cleaner) => {
    setActionType("removeAll");
    setSelectedCleaner({ name: cleaner.name, id: cleaner.id });
    toggleConfirmModal();
  };
  const removeCleaner = () => {
    try {
      if (actionType === "removeAll") {
        const cb = () => {
          setSelectedCleaner({ id: "", name: "" });
        };
        dispatch(
          removeCleanerFromAllRooms(
            selectedCleaner?.id,
            buildingId,
            toggleConfirmModal,
            cb,
            dId
          )
        );
      } else {
        const ids = actionType === "removeSingle" ? [roomId] : selectedRooms;
        const cb = () => {
          setSelectedRooms([]);
        };

        dispatch(
          removeRoomCleaners(ids, buildingId, toggleConfirmModal, cb, dId)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const assignCleaner = () => {
    try {
      const cb = () => {
        setSelectedRooms([]);
      };
      dispatch(
        assignCleanersMultipleRoom(
          selectedCleaner.id,
          selectedRooms,
          buildingId,
          toggleConfirmModal,
          cb,
          dId
        )
      );
    } catch (error) {
      console.error(error);
    }
  };
  const assignCleanerHandler = (cleaner) => {
    try {
      if (selectedRooms.length > 0) {
        setActionType("assignCleaner");
        setSelectedCleaner({ name: cleaner.name, id: cleaner.id });
        toggleConfirmModal();
      } else {
        toastMsg("Select Rooms First", NOTIFICATION.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
const callBackSuccess=()=>{
  dispatch(fetchWorkLoad(buildingId));
  dispatch(fetchCleaners(buildingId,dId));
}
useMemo(()=>{
  if(actionType==="editRoom"&&roomId){
    dispatch(getSpecificRoom({id:roomId}))
  }
},[actionType,roomId])

  return (
    <div className="page-content">
      <NavBar />
      <Nav className="navbar navbar-light justify-content-between navbar-container">
        <Container fluid>
          <Row className="d-flex justify-content-between align-items-center flex-grow-1">
          <Col sm={12} md={2} className="text-sm-center">
            <p className="workLoadTitle"> Workload </p>
          </Col>
          <Col className="d-flex align-items-center justify-content-end" sm={12} md={10}>
            <Row>
              <Col style={{ maxWidth: "250px",minWidth:"200px" }}>
                <Form.Select
                  aria-label="Default select example"
                  className="me-3 search-items"
                  onChange={(e) => setBuildingId(e.target.value)}
                  value={buildingId}
                  disabled={buildingLoading}
                >
                  <option value="">All Buildings</option>
                  {sortByProperty(buildings, "name").map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col>
                <DropdownButton title="Assign Cleaner" variant="secondary">
                  {sortedCleaners.map((cleaner) => (
                    <Dropdown.Item
                      as="button"
                      key={cleaner?.name}
                      onClick={() => assignCleanerHandler(cleaner)}
                    >
                      {cleaner.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
            </Row>
          </Col>
          </Row>
        </Container>
      </Nav>
      <Container fluid>
        <Row className="align-items-start">
          <Col md={8} lg={9}>
            <WorkLoaDatble
              data={data}
              success={success}
              loading={loading}
              selectedRooms={selectedRooms}
              setSelectedRooms={setSelectedRooms}
              setActionType={setActionType}
              toggleConfirmModal={toggleConfirmModal}
              setRoomID={setRoomID}
              setRoomDistrictId={setRoomDistrictId}
            />
          </Col>
          <Col md={3} lg={3}>
            <ListGroup variant="flush" className="mt-2 cleanerWorkLoadList">
              {cleanersLoading ? (
                <Loader />
              ) : (
                <>
                  <ListGroupItem
                    disabled
                    className="d-flex align-items-center justify-content-between py-10 px-10 header"
                  >
                    Total Work Time :
                    <span>
                      {calculateTotalWorkload()}
                    </span>
                  </ListGroupItem>
                  {sortedCleaners.length === 0 ? (
                    <ListGroupItem className="d-flex align-items-center justify-content-between py-10 px-10 item">
                      <span>No Cleaner Found</span>
                    </ListGroupItem>
                  ) : (
                    sortedCleaners.map((item) => (
                      <ListGroupItem
                        className="d-flex align-items-center justify-content-between py-10 px-10 item"
                        key={item?.name + item?.id}
                      >
                        <span>
                          {item?.name || "NA"}
                          {item?.workloadInSeconds >= 0 && (
                            <span
                              className="remove-link text-info"
                              onClick={() => removeAllHandler(item)}
                            >
                              Remove
                            </span>
                          )}
                        </span>
                        <span>
                          <b>
                           {
                            convertToHoursMinutesSeconds(item?.workloadInSeconds||0)
                           }
                          </b>
                        </span>
                      </ListGroupItem>
                    ))
                  )}
                </>
              )}
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <ConfirmationModal
        title={
          actionType === "assignCleaner"
            ? "Assign Cleaner"
            : actionType === "removeAll"
            ? "Remove from all rooms"
            : "Remove Cleaner"
        }
        message={
          actionType === "removeAll"
            ? `Are you sure you want  remove this ${selectedCleaner?.name} cleaner from all rooms assigned? This action cannot be undone`
            : actionType === "assignCleaner"
            ? `Are you sure you want  assign this ${selectedCleaner?.name} cleaner to all selected rooms? This action cannot be undone`
            : `Are you sure you want to remove the cleaner from ${
                actionType == "removeMultiple"
                  ? "from selected rooms"
                  : "from this room"
              }? This action cannot be undone`
        }
        show={confirmModal}
        onCancel={toggleConfirmModal}
        onConfirm={() => {
          if (actionType === "assignCleaner") {
            assignCleaner();
          } else {
            removeCleaner();
          }
        }}
        loading={editRoomProcessing || isAssignCleanersProcessing}
      />
      {
        actionType==="editRoom"&&roomId&&(
          <WorkloadEditRoom
          open={actionType==="editRoom"&&roomId}
          onCloseModal={()=>{setActionType("");setRoomID(""); setRoomDistrictId("")}}
          roomDistrictId={roomDistrictId}
          callBackSuccess={callBackSuccess}
         />
          
        )
      }
    
    </div>
  );
};

export default WorkLoad;
