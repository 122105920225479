import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import $ from "jquery";

import top from "../../assets/images/top.jpg";

import "./resetpassword.scss";
import InputPassword from "../../components/InputPassword";
import { resetPassword } from "../../services/auth";
import { resetAuthStatus } from "../../features/auth/authSlice";
import styled from "styled-components";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let query = useQuery();

  const { forgotPasswordSuccess: success, forgotPasswordError: error } =
    useSelector(({ auth }) => auth);

  useEffect(() => {
    dispatch(resetAuthStatus());
  }, []);

  useEffect(() => {}, [error]);
  useEffect(() => {
    if (success) {
      $(".reset-password_success .toast").addClass("show");
      setTimeout(() => {
        $(".reset-password_success .toast").removeClass("show");
        navigate("/");
      }, 3000);
    }
  }, [success]);

  const [resetDetails, setResetDetails] = useState({
    password: "",
    confirm_password: "",
    // old_password: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setResetDetails({ ...resetDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      resetPassword({
        ...resetDetails,
        token: query.get("token"),
        uid: query.get("uid"),
      })
    );
  };

  return (
    <div className="reset-password ">
      <div className="reset-password_success">
        <div
          className="toast align-items-center text-white bg-success border-0 mx-auto "
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body">
              Password has been reset with the new password.
            </div>
            <button
              type="button"
              className="btn-close btn-close-white me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>

      <div className="reset-password-holder">
        <div className="banner">
          <img src={top} alt="" className="image-responsive" />
          <div className="caption-head">
            <h2>Reset Password</h2>
          </div>
        </div>
        <div className="form-holder">
          <h2>Password Reset</h2>
          <div className="form-box">
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
              <InputPassword
                title="New Password"
                onChange={handleChange}
                name="password"
                value={resetDetails.password}
              />
              <InputPassword
                title="Confirm Password"
                onChange={handleChange}
                name="confirm_password"
                value={resetDetails.confirm_password}
              />
              <button type="submit" className="btn btn-primary w-100">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
