import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./inspectReportGraph.scss";
import NavBar from "../../components/MainNavbar";
import { useSelector, useDispatch } from "react-redux";
import { fetchRoomTypes } from "services/inspection";
import { fetchSchoolCleaners } from "services/cleaner";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { useNavigate } from "react-router-dom";
import { fetchSchools } from "services/school";
import { fetchInspectReportGraph } from "services/inspectReport/inspectReportService";
import Spinner from "react-bootstrap/Spinner";
import { GRAPH_RANGE_OPTIONS } from "constants/constants";

function InspectReportGraph() {
  const {
    schools: { data: schools, loading: schoolsLoading },
  } = useSelector(({ school }) => school);
  const {
    schoolCleaners: { data: cleanersData, loading: cleanerLoading },
  } = useSelector(({ cleaner }) => cleaner);
  const { reportGraph } = useSelector(({ report }) => report);
  const { data, loading } = reportGraph;
  const navigate = useNavigate();

  const { pk, type } = useParams();

  const [values, setValues] = useState({
    startDate: "",
    endDate: "",
    roomType: "",
    filter: "",
    timeRange: "Last 30 Days",
  });
  const [isTimeDis,setTimeDisable]=useState(false);
  const onChangeHandler = (event) => {
    const { value, name } = event.target;

    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const dispatch = useDispatch();
  const {
    roomTypes: {
      success: roomTypesSuccess,
      loading: roomTypesLoading,
      data: roomTypes = [],
    },
  } = useSelector(({ inspection }) => inspection);

  useEffect(() => {
    if (!roomTypesSuccess) {
      dispatch(fetchRoomTypes());
    }
  }, []);
  const fetchGraph = (filters) => {
    dispatch(
      fetchInspectReportGraph({
        pk,
        type,
        ...filters,
      })
    );
  };
  const { startDate, endDate, timeRange, ...rest } = values;
  const resetDateHandler = () => {
    setTimeDisable(false);
    setValues((prevState) => ({
      ...prevState,
      startDate: "",
      endDate: "",
    }));
    fetchGraph({ timeRange, ...rest });
  };
  useEffect(() => {
    if (values?.startDate !== "" && values?.endDate !== "") {
      fetchGraph({ startDate, endDate, ...rest });
      setTimeDisable(true);
    }
  }, [
    pk,
    type,
    values?.filter,
    values?.roomType,
    values?.startDate,
    values?.endDate,
  ]);
  useEffect(() => {
    fetchGraph({ ...rest, timeRange });
  }, [pk, type, values?.filter, values?.roomType, values?.timeRange]);
  useEffect(() => {
    if (type === "districts") {
      dispatch(fetchSchools(pk));
    }
    if (type === "schools") {
      dispatch(fetchSchoolCleaners({ schoolId: pk }));
    }
  }, [pk]);
  return (
    <Container fluid>
      <NavBar />
      <Row className="graph-header align-items-center">
        <Col md={1} xs={4}>
          <button className="arrow-back-btn" onClick={() => navigate(-1)}>
            <Stack direction="horizontal" className="align-items-center">
              <i className="fa-solid fa-arrow-left"></i>
              <p> Back </p>
            </Stack>
          </button>
        </Col>
        <Col md={11} xs={8}>
          <h4> {data?.name} </h4>
        </Col>
      </Row>
      <Row className="report-graph-search align-items-center">
        <Col
          xl={type === "cleaners" ? 6 : 8}
          md={type === "cleaners" ? 10 : 12}
          sm={12}
          xs={12}
          className="d-sm-flex"
        >
          <Form.Select
            aria-label="Default select example"
            className="me-3 search-items"
            onChange={onChangeHandler}
            name="timeRange"
            disabled={isTimeDis}
            value={values?.timeRange}
          >
            {GRAPH_RANGE_OPTIONS.map((item) => (
              <option key={item.value} value={item.value}>
                {item?.label}
              </option>
            ))}
          </Form.Select>
          <Form.Control
            className="me-2"
            aria-label="Start Date"
            placeholder="Start Date"
            value={values?.startDate}
            name="startDate"
            onChange={onChangeHandler}
            onFocus={(e) => (e.target.type = "date")}
            onBlur={(e) => !e.target.value && (e.target.type = "text")}
          />
          <Form.Control
            className="me-2"
            aria-label="End Date"
            name="endDate"
            value={values?.endDate}
            onChange={onChangeHandler}
            placeholder="End date"
            onFocus={(e) => (e.target.type = "date")}
            onBlur={(e) => !e.target.value && (e.target.type = "text")}
          />
          {values?.startDate && values?.endDate ? (
            <button
              className="btn-close me-2"
              onClick={resetDateHandler}
            ></button>
          ) : null}

          {type === "districts" ? (
            <Form.Select
              aria-label="Default select example"
              className="me-3 search-items"
              disabled={schoolsLoading}
              name="filter"
              onChange={onChangeHandler}
              value={values?.filter}
            >
              <option value={""}>All Buildings</option>
              {schools.map((item) => (
                <option key={item.id} value={item.id}>
                  {item?.name}
                </option>
              ))}
            </Form.Select>
          ) : type === "schools" ? (
            <Form.Select
              aria-label="Default select example"
              className="me-3 search-items"
              disabled={cleanerLoading}
              name="filter"
              onChange={onChangeHandler}
            >
              <option value={""}>All Cleaners</option>
              {cleanersData.map((item) => (
                <option key={item.id} value={item.id}>
                  {item?.name}
                </option>
              ))}
            </Form.Select>
          ) : null}
          <Form.Select
            aria-label="Default select example"
            className="me-3 search-items "
            onChange={onChangeHandler}
            disabled={roomTypesLoading}
            value={values?.roomType}
            name="roomType"
          >
            <option value="">Overall</option>
            {roomTypes?.map((item) => (
              <option key={item?.id} id={item?.id} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </Form.Select>
        </Col>

        <Col xl={2} md={12} sm={12} xs={12}>
          <p className="average-score">
            Average Score : <b> {data?.overallAvgScore || ""} </b>
          </p>
        </Col>
      </Row>

      <Row className="pt-2">
        {loading ? (
          <div className="loader-container">
            <Spinner variant="primary" />
          </div>
        ) : data?.scoreDateData?.length ? (
          <div className="graph-container">
            <ResponsiveContainer>
              <LineChart data={data.scoreDateData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date"
                   tickFormatter={(date) => moment(date).format("DD/MM/YYYY")}
                />
                <YAxis />
                <Tooltip formatter={(value) => [value, "Average Score"]}
                   labelFormatter={(date) => moment(date).format("DD/MM/YYYY")}
                />
                <Line
                  type="monotone"
                  dataKey="avgScore"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className="norecord-container">
            <p className="text-center"> No Record Found</p>
          </div>
        )}
      </Row>
    </Container>
  );
}

export default InspectReportGraph;
