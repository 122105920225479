import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Settings.scss";
import { updateUserPassword } from "services/admin";
import { Form, Button, Spinner, Col, Row } from "react-bootstrap";
import NavBar from "../../components/MainNavbar";
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch.js";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { updateProfile } from "services/admin";
import {
  sendInspectionEmail,
} from "services/inspectReport/inspectReportService";
const ErrorMessage = ({ message }) => {
  return <p className="text-danger mt-1">{message}</p>;
};

const Settings = () => {
  const initialState = {
    old_password: "",
    new_password1: "",
    new_password2: "",
  };
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const [touched, setTouched] = useState(initialState);
  const [submitting, setSubmitting] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [modal, setModal] = useState(false);
  const { loading, error } = useSelector(
    ({ admin }) => admin.updateUserPassword
  );
  const { data: userData } = useSelector(({ auth }) => auth);
  const { loading: inspectNotificationLoading } = useSelector(
    ({ admin }) => admin.updateUser
  );
  const { id, receiveDailyInspectionReports } = userData?.user;
  const [enabled, setEnabled] = useState(receiveDailyInspectionReports);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleBlur = (e) => {
    const { name, id } = e.target;
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));

    validateField(name, id);
  };

  const validateField = (fieldName, label) => {
    const fieldValue = values[fieldName];
    let errorMessage = "";
    if (!fieldValue) {
      errorMessage = `${label} is required`;
    } else if (fieldName === "new_password1") {
      const regexPassword = /^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/;
      if (fieldValue.length < 8) {
        errorMessage = `${label} should be at least 8 characters long`;
      } else if (regexPassword.test(fieldValue)) {
        errorMessage = `${label} should include atleast one upper case letter, one lower case letter and one special letter`;
      }
    } else if (
      fieldName === "new_password2" &&
      fieldValue !== values.new_password1
    ) {
      errorMessage = "Confirm Password should match New Password";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  };
  const onCancel = () => {
    setEnabled(!enabled);
    setModal(!modal);
  };
  const handleToggle = () => {
    setEnabled(!enabled);
    setModal(true);
  };
  const dispatch = useDispatch();
  const resetHandler = () => {
    setValues(initialState);
    setTouched(initialState);
    setErrors(initialState);
  };
  const handleLoginSubmit = (e) => {
    e.preventDefault();
    setTouched({
      old_password: true,
      new_password1: true,
      new_password2: true,
    });
    validateField("old_password", "Current Password");
    validateField("new_password1", "New Password");
    validateField("new_password2", "Confirm Password");
    setSubmitting(true);
  };
  const onConfirm = () => {
    try {
      dispatch(
        updateProfile(
          id,
          {
            receiveDailyInspectionReports: enabled,
          },
          () => setModal(false)
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const hasError =
      errors?.old_password || errors?.new_password1 || errors?.new_password2;

    if (!hasError && submitting) {
      dispatch(updateUserPassword(values));
      setSubmitting(false);
    } else {
      setSubmitting(false);
    }
  }, [errors, submitting]);
  const sendEmailHandler = () => {
    sendInspectionEmail(setProcessing);
  };

  return (
    <div className="container-fluid">
      <NavBar />
      <div className="setting-con">
        <Row className="mt-4">
          <Col xxl={4} xl={5} lg={5} md={7}>
            <div className="password-card">
              <h4 className="text-center mb-3">Update Your Password</h4>
              <div className="text-justify">
                <p>
                  In order to <b> protect your account</b>, make sure your
                  password:
                </p>
                <p> Password cannot be similar to the email or username </p>
                <p>Password cannot be too common (i.e., password123)</p>
                <p>Password must have a minimum of 8 characters</p>
                <p>
                  Password must have atleast one upper case and lower case
                  letter{" "}
                </p>
                <p>Password must have a special character </p>
              </div>
              {error?.oldPassword?.length > 0 && (
                <ErrorMessage message="Current Password is incorrect" />
              )}
              <Form onSubmit={handleLoginSubmit}>
                <Form.Group controlId="Current Password">
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Current Password"
                    name="old_password"
                    value={values?.old_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.old_password && errors.old_password && (
                    <ErrorMessage message={errors.old_password} />
                  )}
                </Form.Group>
                <Form.Group controlId="New Password">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="New Password"
                    name="new_password1"
                    value={values?.new_password1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched?.new_password1 && errors?.new_password1 && (
                    <ErrorMessage message={errors?.new_password1} />
                  )}
                </Form.Group>
                <Form.Group controlId="Confirm Password">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter Confirm Password"
                    name="new_password2"
                    value={values?.new_password2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched?.new_password2 && errors?.new_password2 && (
                    <ErrorMessage message={errors?.new_password2} />
                  )}
                </Form.Group>

                {loading ? (
                  <div className="me-3">
                    <Spinner variant="primary" />
                  </div>
                ) : (
                  <>
                    <Button
                      variant="secondary"
                      className="me-2"
                      onClick={resetHandler}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                      Change Password
                    </Button>
                  </>
                )}
              </Form>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={5}
            md={5}
            className="offset-xxl-2 offset-xl-1 "
          >
            <div className="daily-report-con">
              <h4 className="mb-3"> Daily Inspection Reports</h4>
              <div className="report-switch-con">
                <div className="title">
                  <h6> Receive Notification</h6>
                </div>
                <div>
                  <ToggleSwitch enabled={enabled} handleToggle={handleToggle} />
                </div>
              </div>
            </div>
            <div className="daily-report-con">
              <h4 className="mb-3">Send out emails after inspections</h4>
              <div className="report-switch-con">
                {processing ? (
                  <Spinner variant="primary" />
                ) : (
                  <Button variant="primary" onClick={sendEmailHandler}>
                    Send Email
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <ConfirmationModal
        show={modal}
        message={`Are you sure you want to ${
          receiveDailyInspectionReports ? "disable" : "enable"
        } daily inspection report notifications?`}
        loading={inspectNotificationLoading}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </div>
  );
};

export default Settings;
