import InnerLoading from "components/InnerLoading";
import Dropdown from 'react-bootstrap/Dropdown';
import {
    INSPECTION_ITEM_PASS_OR_FAIL_TYPE,
    INSPECTION_ITEM_NUMERICAL_TYPE
} from "utils/constants";

export default function RoomItemsForm({
    roomName = '',
    questions = [],
    isUpdatingForm = false,
    isLoadingQuestions = false,
    handleQuestionInputChange,
    handleBackClick,
    handleSaveRoomClick,
    handleRemoveQuestionClick,
    handleAddQuestionClick,
}) {

    return (
        <>
            <nav className="navbar navbar-light position-relative z-3 justify-content-between navbar-container" style={{zIndex: 100}}>
                <div className="container-fluid">
                    <a href="#"
                        className="d-flex align-items-center text-decoration-none me-3 me-sm-4 mt-sm-0 mt-2 mb-3 mb-sm-0 order-1"
                        onClick={handleBackClick}
                    >
                        <i className="fa-solid fa-arrow-left me-2"></i>
                        Back
                    </a>
                    <div className="d-flex me-auto mb-4 mb-sm-0 order-sm-2 order-3 mw-100 align-items-center" style={{ width: '400px' }}>
                        <div className="d-flex align-items-center">
                            <h3 className="mb-0 me-3">{roomName}</h3>
                        </div>
                    </div>
                    <Dropdown  className="ms-2 order-2 order-sm-3 mt-sm-0 mt-2 mb-sm-0 mb-3">
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-outline-primary btn-small ms-2 order-2 order-sm-3 mt-sm-0 mt-2 mb-sm-0 mb-3">
                            <i className="fa-solid fa-plus me-1"></i> Add Question
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.ItemText className="text-secondary">Answer type:</Dropdown.ItemText>
                            <Dropdown.Item onClick={() => handleAddQuestionClick(INSPECTION_ITEM_PASS_OR_FAIL_TYPE)} as="button">Pass or Fail Answer</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddQuestionClick(INSPECTION_ITEM_NUMERICAL_TYPE)} as="button">Numerical Answer</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </nav>
            <div className="container-fluid pt-3 app-main-content overflow-auto">
                <div className="row">
                    <div className="position-relative">
                        <InnerLoading show={isLoadingQuestions} />
                        {questions.length === 0 && !isLoadingQuestions && (
                            <p className="d-flex align-items-center justify-content-center mt-4 p4">No questions to show</p>
                        )}
                    </div>
                    {questions.length > 0 && (
                        <div className="col-12">
                            <ol className="nav flex-column numbered-list">
                                {questions.map((question, idx) => (
                                    <li key={`roomQuestions${idx}`} className="d-flex align-items-center col-12 col-lg-10 nav-item mb-4">
                                        <input 
                                            className="form-control mb-0 mx-2"
                                            value={question.item} 
                                            type="text"
                                            onChange={handleQuestionInputChange(idx)}
                                            placeholder="Enter question"
                                        />
                                        <p className="py-2 mb-0 text-center"><small className="text-secondary">{question.itemTypeName}</small></p>
                                        <button
                                            className="mx-2 btn btn-outline-primary btn-small d-flex align-items-center shadow-none"
                                            onClick={handleRemoveQuestionClick(idx)}
                                        >
                                            <i className="fa-sharp fa-solid fa-trash-can"></i>
                                        </button>
                                    </li>
                                ))}
                            </ol>
                        </div>
                    )}
                </div>
            </div>
            <div className="container-fluid border-top py-3">
                <div className="row">
                    <div className="col-12 justify-content-end d-flex">
                        <button disabled={isUpdatingForm} type="button" className="btn btn-primary ms-2" onClick={(e) => {
                            handleSaveRoomClick(e)
                        }} >Save Changes</button>
                    </div>
                </div>
            </div>
        </>
    )
}