import { Axios } from "../../api/axios";
import { getHeader, toastMsg } from "../../utils/utility";
import { getSimplifiedError } from "../../utils/error";
import {
  resetBlockFlags,
  setBlockLoadingFlag,
  addAdminSchoolFailure,
  addAdminSchoolSuccess,
  addInspectorSchoolFailure,
  addInspectorSchoolSuccess,
  addMapFailure,
  addMapSuccess,
  addSdsFailure,
  addSdsSuccess,
  createCleanupRoomTypeFailure,
  createCleanupRoomTypeSuccess,
  createCleanupSuccess,
  createSchoolFailure,
  createSchoolSuccess,
  deleteSchoolFailure,
  deleteSchoolSuccess,
  deleteSdsFailure,
  deleteSdsSuccess,
  fetchReportFailure,
  fetchReportSuccess,
  fetchRoomSpecsFailure,
  fetchRoomSpecsSuccess,
  fetchSchoolFailure,
  fetchSchoolsCleaningDetailsFailure,
  fetchSchoolsCleaningDetailsSuccess,
  fetchSchoolSuccess,
  getCleanerInspectionFailure,
  getCleanerInspectionSuccess,
  getCleaningAreasFailure,
  getCleaningAreasSuccess,
  getInspectionResultFailure,
  getInspectionResultSuccess,
  getMapFailure,
  getMapSuccess,
  getSchoolFailure,
  getSchoolOrderFailure,
  getSchoolOrderSuccess,
  getSchoolSuccess,
  getSdsFailure,
  getSdsSuccess,
  removeAdminSchoolFailure,
  removeAdminSchoolSuccess,
  removeCleanerFailure,
  removeCleanerSuccess,
  removeInspectorSchoolFailure,
  removeInspectorSchoolSuccess,
  setSchoolOrderFailure,
  setSchoolOrderSuccess,
  updateCleanupRoomTypeFailure,
  updateCleanupRoomTypeSuccess,
  updateSchoolFailure,
  updateSchoolSuccess,
  removeMapSuccess,
  removeMapFailure,
  updateSchool,
  deleteSchoolStart,
  editMapSuccess,
  fetchBuildingSuccess,
  fetchBuildingFailure,
} from "../../features/school/schoolSlice";

import {
  setBlockSuccess,
  setBlockFailure,
  startBlockFlags,
} from "features/school/schoolSlice";
import { ERRORS, NOTIFICATION } from "constants/constants";

export const fetchSchools = (districtId) => async (dispatch) => {
  dispatch(resetBlockFlags({ blockType: "schools" }));
  dispatch(setBlockLoadingFlag({ blockType: "schools", loading: true }));
  try {
    const response = await Axios.get(
      `/api/v1/school/?district=${districtId}`,

      getHeader()
    );
    dispatch(fetchSchoolSuccess(response));
  } catch (error) {
    dispatch(fetchSchoolFailure(getSimplifiedError(error)));
  }
};

export const getSpecificSchool = (schoolId) => async (dispatch) => {
  try {
    dispatch(resetBlockFlags({ blockType: "getSpecificSchool" }));
    dispatch(
      setBlockLoadingFlag({ blockType: "getSpecificSchool", loading: true })
    );
    const response = await Axios.get(
      `/api/v1/school/${schoolId}/?v=${new Date().getTime()}`
    );

    dispatch(getSchoolSuccess(response));
  } catch (error) {
    dispatch(getSchoolFailure(getSimplifiedError(error)));
  }
};

export const createSchool =
  ({ name, image, district, contactEmail }, cb) =>
  async (dispatch) => {
    dispatch(resetBlockFlags({ blockType: "createSchool" }));
    dispatch(setBlockLoadingFlag({ blockType: "createSchool", loading: true }));
    try {
      const response = await Axios.post(
        `/api/v1/school/`,
        {
          name,
          image,
          contactEmail,
          district,
        },
        getHeader()
      );
      cb();
      dispatch(createSchoolSuccess(response));
      toastMsg("Building successfully created");
    } catch ({ response }) {
      dispatch(createSchoolFailure(response?.data?.error || ERRORS.common));
      toastMsg(response?.data?.error || ERRORS.common, NOTIFICATION?.error);
    }
  };

export const createCleanup = (data) => async (dispatch) => {
  try {
    const response = await Axios.post(
      `/api/v1/equipment/`,
      {
        dust_cleaning: data.dust_cleaning,
        dust_cleaning_size: data.dust_cleaning_size,
        floor_burnishing: data.floor_burnishing,
        floor_burnishing_size: data.floor_burnishing_size,
        floor_mopping: data.floor_mopping,
        floor_mopping_size: data.floor_mopping_size,
        misting_table: data.misting_table,
        cleaning_table: data.cleaning_table,
        school: data.school,
      },
      getHeader()
    );

    dispatch(createCleanupSuccess(response));
  } catch (error) {
    dispatch(createSchoolFailure(getSimplifiedError(error)));
  }
};

export const createCleanupRoomType = (payload) => async (dispatch) => {
  try {
    const response = await Axios.post(
      `/api/v1/equipment-needed-by-room-type/`,
      payload,
      getHeader()
    );

    dispatch(createCleanupRoomTypeSuccess(response));
  } catch (error) {
    dispatch(createCleanupRoomTypeFailure(getSimplifiedError(error)));
  }
};

export const fetchCleaningDetails = (schoolId) => async (dispatch) => {
  try {
    const response = await Axios.get(
      `/api/v1/equipment-needed-by-room-type/?school=${schoolId}`
    );

    dispatch(fetchSchoolsCleaningDetailsSuccess(response));
  } catch (error) {
    dispatch(fetchSchoolsCleaningDetailsFailure(getSimplifiedError(error)));
  }
};

export const editCleanupData = (data) => async (dispatch) => {
  try {
    await Axios.patch(
      `/api/v1/equipment/${data.cleanupId}/`,
      {
        dust_cleaning: data.dust_cleaning,
        dust_cleaning_size: data.dust_cleaning_size,
        floor_burnishing: data.floor_burnishing,
        floor_burnishing_size: data.floor_burnishing_size,
        floor_mopping: data.floor_mopping,
        floor_mopping_size: data.floor_mopping_size,
        misting_table: data.misting_table,
        cleaning_table: data.cleaning_table,
      },
      getHeader()
    );

    // TODO: update later
    // dispatch(updateClean(response));
  } catch (error) {
    // dispatch(createCleanupFailure(getSimplifiedError(error)));
  }
};

export const editCleanupDataRoomType = (data) => async (dispatch) => {
  try {
    const { id, payload } = data;
    const { school } = payload;
    const response = await Axios.patch(
      `/api/v1/equipment-needed-by-room-type/${id}/?school=${school}`,
      payload,
      getHeader()
    );

    dispatch(updateCleanupRoomTypeSuccess(response));
  } catch (error) {
    dispatch(updateCleanupRoomTypeFailure(getSimplifiedError(error)));
  }
};

export const editSchool =
  ({ school, data }, cb) =>
  async (dispatch) => {
    try {
      dispatch(updateSchool());
      const response = await Axios.patch(
        `/api/v1/school/${school}/`,
        data,
        getHeader()
      );
      if (!response) {
        return;
      }
      toastMsg("Building updated successfully");
      dispatch(fetchSchools(data.district));
      dispatch(updateSchoolSuccess(response));
      cb();
    } catch (error) {
      dispatch(updateSchoolFailure(getSimplifiedError(error)));
      toastMsg("Building could not be updated");
    }
  };

export const fetchReports = (schoolId) => async (dispatch) => {
  try {
    const response = await Axios.get(
      `/api/v1/school/${schoolId}/report/`,
      getHeader()
    );

    dispatch(fetchReportSuccess(response));
  } catch (error) {
    dispatch(fetchReportFailure(getSimplifiedError(error)));
  }
};
export const fetchRoomSpecs = (schoolId) => async (dispatch) => {
  try {
    const response = await Axios.get(
      `/api/v1/school/${schoolId}/room-specs/`,
      getHeader()
    );

    dispatch(fetchRoomSpecsSuccess(response));
  } catch (error) {
    dispatch(fetchRoomSpecsFailure(getSimplifiedError(error)));
  }
};
export const deleteSchool = (payload, cb) => async (dispatch) => {
  try {
    dispatch(deleteSchoolStart());
    await Axios.delete(`/api/v1/school/${payload.id}/`, getHeader());
    toastMsg("Building successfully deleted");
    dispatch(deleteSchoolSuccess(payload.id));
    cb(false);
  } catch (error) {
    dispatch(deleteSchoolFailure(getSimplifiedError(error)));
    toastMsg("Building could not be deleted", NOTIFICATION.error);
  }
};

export const removeCleaner =
  ({ schoolId, cleanerId }) =>
  async (dispatch) => {
    dispatch(resetBlockFlags({ blockType: "removeCleaner" }));
    dispatch(
      setBlockLoadingFlag({ blockType: "removeCleaner", loading: true })
    );
    try {
      await Axios.delete(`/api/v1/delete/cleaner/school/`, {
        data: {
          school: schoolId,
          cleaner: cleanerId,
        },
      });
      dispatch(removeCleanerSuccess({ cleanerId, schoolId }));
    } catch (error) {
      dispatch(removeCleanerFailure(getSimplifiedError(error)));
    }
  };

export const addAdminSchool =
  ({ schoolId, buildingAdmins }) =>
  async (dispatch) => {
    dispatch(resetBlockFlags({ blockType: "addAdminSchool" }));
    try {
      const response = await Axios.post(
        `/api/v1/school/${schoolId}/add-admin/`,
        {
          buildingAdmins,
        }
      );

      dispatch(addAdminSchoolSuccess(response));
    } catch (error) {
      dispatch(addAdminSchoolFailure(getSimplifiedError(error)));
    }
  };
export const removeAdminSchool =
  ({ schoolId, buildingAdmins }) =>
  async (dispatch) => {
    dispatch(resetBlockFlags({ blockType: "removeAdminSchool" }));
    try {
      const response = await Axios.post(
        `/api/v1/school/${schoolId}/remove-admin/`,
        {
          buildingAdmins,
        }
      );
      dispatch(removeAdminSchoolSuccess(response));
    } catch (error) {
      dispatch(removeAdminSchoolFailure(getSimplifiedError(error)));
    }
  };
export const addInspectorSchool =
  ({ schoolId, inspectors }) =>
  async (dispatch) => {
    dispatch(resetBlockFlags({ blockType: "addInspectorSchool" }));
    try {
      const response = await Axios.post(
        `/api/v1/school/${schoolId}/add-inspector/`,
        {
          inspectors,
        }
      );

      dispatch(addInspectorSchoolSuccess(response));
    } catch (error) {
      dispatch(addInspectorSchoolFailure(getSimplifiedError(error)));
    }
  };
export const removeInspectorSchool =
  ({ schoolId, inspectors }) =>
  async (dispatch) => {
    dispatch(resetBlockFlags({ blockType: "removeInspectorSchool" }));
    try {
      const response = await Axios.post(
        `/api/v1/school/${schoolId}/remove-inspector/`,
        {
          inspectors,
        }
      );
      dispatch(removeInspectorSchoolSuccess(response));
    } catch (error) {
      dispatch(removeInspectorSchoolFailure(getSimplifiedError(error)));
    }
  };
export const getInspectionResult = (schoolId) => async (dispatch) => {
  dispatch(resetBlockFlags({ blockType: "getInspectionResult" }));
  dispatch(
    setBlockLoadingFlag({ blockType: "getInspectionResult", loading: true })
  );
  try {
    /*
    const response = await Axios.get(
      `/api/v1/inspection-bulk/?school=${schoolId}`
    );
    */
    const response = [];

    dispatch(getInspectionResultSuccess(response));
  } catch (error) {
    dispatch(getInspectionResultFailure(getSimplifiedError(error)));
  } finally {
    dispatch(
      setBlockLoadingFlag({ blockType: "getInspectionResult", loading: false })
    );
  }
};
export const getCleanerInspections =
  ({ schoolId, cleanerId }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(
        `/api/v1/inspection/?school=${schoolId}&cleaned_by=${cleanerId}`
      );
      dispatch(getCleanerInspectionSuccess(response));
    } catch (error) {
      dispatch(getCleanerInspectionFailure(getSimplifiedError(error)));
    }
  };
export const getCleaningAreas =
  ({ schoolId, cleanerId }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(
        `/api/v1/school/${schoolId}/cleaning-areas/?cleaner=${cleanerId}`
      );

      dispatch(getCleaningAreasSuccess(response));
    } catch (error) {
      dispatch(getCleaningAreasFailure(getSimplifiedError(error)));
    }
  };
export const getSds = (schoolId) => async (dispatch) => {
  try {
    const response = await Axios.get(`/api/v1/sds-pdf/?school=${schoolId}`);

    dispatch(getSdsSuccess(response));
  } catch (error) {
    dispatch(getSdsFailure(getSimplifiedError(error)));
  }
};
export const addSds = (payload) => async (dispatch) => {
  try {
    const response = await Axios.post(`/api/v1/sds-pdf/`, payload);

    dispatch(addSdsSuccess(response));
  } catch (error) {
    dispatch(addSdsFailure(getSimplifiedError(error)));
  }
};
export const deleteSds =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = await Axios.delete(`/api/v1/sds-pdf/${id}/`);

      dispatch(deleteSdsSuccess(response));
    } catch (error) {
      dispatch(deleteSdsFailure(getSimplifiedError(error)));
    }
  };

export const addMapApi = (payload, id) => async (dispatch) => {
  try {
    dispatch(resetBlockFlags({ blockType: "addMap" }));
    dispatch(setBlockLoadingFlag({ blockType: "addMap", loading: true }));
    let response;
    if (id) {
      response = await Axios.put(`/api/v1/school-map-image/${id}/`, {
        ...payload,
        id,
      });
      dispatch(editMapSuccess(response));
      toastMsg(`Map updated'successfully`, NOTIFICATION.success);
    } else {
      response = await Axios.post(`/api/v1/school-map-image/`, payload);
      dispatch(addMapSuccess(response));
      toastMsg(`Map uploaded successfully`, NOTIFICATION.success);
    }
  } catch (error) {
    dispatch(addMapFailure(getSimplifiedError(error)));
    toastMsg(
      `Map could not be ${id ? "updated" : "uploaded"}"`,
      NOTIFICATION.error
    );
  }
};
export const removeMap = (id, cb) => async (dispatch) => {
  dispatch(resetBlockFlags({ blockType: "removeMap" }));
  dispatch(setBlockLoadingFlag({ blockType: "removeMap", loading: true }));
  try {
    await Axios.delete(`/api/v1/school-map-image/${id}/`);
    dispatch(removeMapSuccess(id));
    toastMsg("Map deleted successfully", NOTIFICATION.success);
    cb();
  } catch (error) {
    dispatch(removeMapFailure(getSimplifiedError(error)));
    toastMsg("Map cannot be deleted ", NOTIFICATION.error);
  }
};
export const getMap = (schoolId) => async (dispatch) => {
  dispatch(resetBlockFlags({ blockType: "map" }));
  dispatch(setBlockLoadingFlag({ blockType: "map", loading: true }));
  try {
    const response = await Axios.get(
      `/api/v1/school-map-image/?school_building=${schoolId}`
    );
    dispatch(getMapSuccess(response));
  } catch (error) {
    dispatch(getMapFailure(getSimplifiedError(error)));
  }
};
export const getSchoolApi = () => async (dispatch) => {
  try {
    const response = await Axios.get(`api/v1/school/school-order/`);

    dispatch(getSchoolOrderSuccess(response));
  } catch (error) {
    dispatch(getSchoolOrderFailure(getSimplifiedError(error)));
  }
};
export const setSchoolApi = (payload) => async (dispatch) => {
  try {
    const response = await Axios.post(`api/v1/school/school-order/`, {
      school_ids: payload,
    });

    dispatch(setSchoolOrderSuccess(response));
  } catch (error) {
    dispatch(setSchoolOrderFailure(getSimplifiedError(error)));
  }
};

/*
export const editCleaner =
  ({ id, data }) =>
  async (dispatch) => {
    dispatch(resetBlockFlags({blockType: 'editCleaner'}));
    dispatch(setBlockLoadingFlag({blockType: 'editCleaner', loading: true}));
    try {
      const response = await Axios.patch(`/api/v1/cleaner/${id}/`, data);
      dispatch(editCleanerSuccess({...response, schoolId: data.school}));
    } catch (error) {
      dispatch(editCleanerFailure(getSimplifiedError(error)));
    }
  };
  */

export const resetBlockSchool = (payload) => async (dispatch) => {
  //   dispatch(resetBlockSchoolSuccess(response));
};
export const fetchRooms =
  ({ schoolId }) =>
  async (dispatch) => {
    dispatch(startBlockFlags({ blockType: "rooms" }));
    try {
      const response = await Axios.get(
        `/api/v1/room/?school=${schoolId}`,
        getHeader()
      );
      dispatch(setBlockSuccess({ blockType: "rooms", data: response }));
    } catch (error) {
      dispatch(
        setBlockFailure({
          blockType: "rooms",
          error: getSimplifiedError(error),
        })
      );
    }
  };
export const fetchBuildings = (districtId) => async (dispatch) => {
  dispatch(resetBlockFlags({ blockType: "buildings" }));
  dispatch(setBlockLoadingFlag({ blockType: "buildings", loading: true }));
  try {
    let url = `/api/v1/simple/school/building/list/`;
    if(districtId){
      url = `${url}?district=${districtId}`;
    }
    const response = await Axios.get(url,getHeader());
    dispatch(fetchBuildingSuccess(response));
  } catch (error) {
    dispatch(fetchBuildingFailure(getSimplifiedError(error)));
  }
};
