import { createSlice } from "@reduxjs/toolkit";
import { NOTIFICATION } from "constants/constants";
import { BaseServiceReducer } from "utils/slices";
import { toastMsg } from "utils/utility";

const block = {
  loading: false,
  error: "",
  success: false,
};

const initialState = {
  schools: { ...block, data: [] },
  rooms:{ ...block, data: [] },
  getSpecificSchool: { ...block, data: null },
  createSchool: { ...block },
  reports: { ...block, data: [] },
  deleteSchool: { ...block},
  editSchool: { ...block },
  addCleaner: { ...block },
  addExistingCleaner: {...block},
  createCleanupRoomType: { ...block, data: [] },
  createCleanup: { ...block, data: {} },
  updateRoomType: { ...block, data: [] },
  removeCleaner: { ...block },
  editCleaner: { ...block },
  addAdminSchool: { ...block },
  removeAdminSchool: { ...block },
  addInspectorSchool: { ...block },
  removeInspectorSchool: { ...block },
  room_specs: { ...block, data: [] },
  schoolCleaningDetails: { ...block, data: {} },
  getInspectionResult: { ...block, data: [] },
  getCleaningAreas: { ...block, data: [] },
  getCleanerInspection: { ...block, data: [] },
  sds: { ...block, data: [] },
  map: { ...block, data: null },
  addMap: {...block},
  removeMap: {...block},
  addSds: { ...block },
  deleteSds: { ...block },
  replaceData: [],
  buildings:{...block,data:[]}
};
const sliceName = 'school'
const serviceReducer = BaseServiceReducer(sliceName, initialState);
const schoolSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...serviceReducer,
    resetSchoolsBlock(state, action) {
      state.schools = {...state.schools, ...block}
    },
    resetAddCleaner(state, action) {
      state.addCleaner = {...block};
    },
    setSchoolsLoading(state, action) {
      state.schools = {...state.schools, loading: action.payload}
    },
    addCleanerSuccess: ({getSpecificSchool: school, addCleaner}, action) => {
      const {id} = action.payload;
      const schoolData = school.data 
      school.data = {
        ...school.data,
        cleaners: [
          ...schoolData.cleaners,
          id,
        ],
        cleanersDetail: [
          ...schoolData.cleanersDetail,
          {...action.payload},
        ],
      };
      addCleaner.loading = false;
      addCleaner.success = true;
    },
    addCleanerFailure: ({ addCleaner }, action) => {
      addCleaner.loading = false;
      addCleaner.success = false;
      addCleaner.error = action.payload;
    },
    editCleanerSuccess: ({schools, editCleaner}, action) => {
      const {id, schoolId} = action.payload;

      const editCleanerSchoolIndex = schools.data.findIndex(
        (s) => s.id === schoolId
      );
      const editableSchool = schools.data[editCleanerSchoolIndex];
      const editCleanerIndex = editableSchool.cleanersDetail.findIndex(
        (c) => c.id === id 
      );
      editableSchool.cleanersDetail[editCleanerIndex] = {...action.payload};
      editCleaner.loading = false;
      editCleaner.success = true;
    },
    editCleanerFailure: ({ editCleaner }, action) => {
      editCleaner.error = action.payload;
      editCleaner.loading = false;
      editCleaner.success = false;
    },
    fetchSchoolSuccess: ({ schools }, action) => {
      schools.loading = false;
      schools.success = true;
      schools.data = action.payload;
    },
    fetchSchoolFailure: ({ schools }, action) => {
      schools.loading = false;
      schools.success = false;
      schools.error = action.payload;
    },

    getSchoolSuccess: ({ getSpecificSchool, schools }, action) => {
      getSpecificSchool.loading = false;
      getSpecificSchool.success = true;
      getSpecificSchool.error = "";
      getSpecificSchool.data = action.payload
    },
    getSchoolFailure: ({ getSpecificSchool }, action) => {
      getSpecificSchool.loading = false;
      getSpecificSchool.success = false;
      getSpecificSchool.error = action.payload;
    },

    createSchoolSuccess: ({ createSchool, schools }, action) => {
      schools.data = [...schools.data, action.payload];
      createSchool.success = true;
      createSchool.loading = false;
      createSchool.error="";
    },
    createSchoolFailure: ({ createSchool }, action) => {
      createSchool.success = false;
      createSchool.error = action.payload;
      createSchool.loading = false;
    },

    createCleanupSuccess: ({ createCleanup }, action) => {
      createCleanup.loading = false;
      createCleanup.success = true;
      createCleanup.data = action.payload;
    },
    createCleanupFailure: ({ createCleanup }, action) => {
      createCleanup.loading = false;
      createCleanup.success = false;
      createCleanup.error = action.payload;
    },
    createCleanupRoomTypeSuccess: ({ createCleanupRoomType }, action) => {
      createCleanupRoomType.loading = false;
      createCleanupRoomType.success = true;
      createCleanupRoomType.data = action.payload;
    },
    createCleanupRoomTypeFailure: ({ createCleanupRoomType }, action) => {
      createCleanupRoomType.loading = false;
      createCleanupRoomType.success = false;
      createCleanupRoomType.error = action.payload;
    },
    updateSchool: ({editSchool}) => {
      editSchool.error = '';
      editSchool.loading = true;
      editSchool.success = false;
    },
    updateSchoolSuccess: ({ schools, editSchool }, action) => {
      schools.data = schools.data.map((i) =>
        action.payload.id === i.id ? action.payload : i
      );
      editSchool.loading = false;
      editSchool.success = true;
    },
    updateSchoolFailure: ({ editSchool }, action) => {
      editSchool.loading = false;
      editSchool.success = false;
      editSchool.error = action.payload;
    },

    fetchRoomSpecsSuccess: ({ room_specs }, action) => {
      room_specs.loading = false;
      room_specs.success = true;
      room_specs.data = action.payload;
    },
    fetchRoomSpecsFailure: ({ room_specs }, action) => {
      room_specs.loading = false;
      room_specs.success = false;
      room_specs.error = action.payload;
    },

    updateCleanupRoomTypeSuccess: ({ updateRoomType }, action) => {
      updateRoomType.loading = false;
      updateRoomType.success = true;
      updateRoomType.data = action.payload;
    },
    updateCleanupRoomTypeFailure: ({ updateRoomType }, action) => {
      updateRoomType.loading = false;
      updateRoomType.success = false;
      updateRoomType.error = action.payload;
    },

    fetchReportSuccess: ({ reports }, action) => {
      reports.loading = false;
      reports.success = true;
      reports.data = action.payload;
    },
    fetchReportFailure: ({ reports }, action) => {
      reports.loading = false;
      reports.success = false;
      reports.error = action.payload;
    },

    fetchSchoolsCleaningDetailsSuccess: ({ schoolCleaningDetails }, action) => {
      schoolCleaningDetails.loading = false;
      schoolCleaningDetails.success = true;
      schoolCleaningDetails.data = action.payload;
    },
    fetchSchoolsCleaningDetailsFailure: ({ schoolCleaningDetails }, action) => {
      schoolCleaningDetails.loading = false;
      schoolCleaningDetails.success = false;
      schoolCleaningDetails.error = action.payload;
    },
 
    deleteSchoolStart: ({deleteSchool}) => {
      deleteSchool.error = '';
      deleteSchool.loading = true;
      deleteSchool.success = false;
    },
    deleteSchoolSuccess: ({ deleteSchool, schools }, action) => {
      schools.data = schools.data.filter((sc) => sc.id !== action.payload);
      deleteSchool.loading = false;
      deleteSchool.success = true;
    },
    deleteSchoolFailure: ({ deleteSchool }, action) => {
      deleteSchool.loading = false;
      deleteSchool.success = false;
      deleteSchool.error = action.payload;
    },
    removeCleanerSuccess: ({ schools, removeCleaner }, action) => {
      const {schoolId, cleanerId} = action.payload;

      const removeCleanerSchoolIndex = schools.data.findIndex(
        (s) => s.id === schoolId
      );
      const cleanerSchool = schools.data[removeCleanerSchoolIndex];
      cleanerSchool.cleaners = cleanerSchool.cleaners.filter(id => id !== cleanerId);
      cleanerSchool.cleanersDetail = cleanerSchool.cleanersDetail.filter(({id}) => id !== cleanerId);

      removeCleaner.success = true;
      removeCleaner.loading = action.payload;
    },
    removeCleanerFailure: ({ removeCleaner }, action) => {
      removeCleaner.loading = false;
      removeCleaner.success = false;
      removeCleaner.error = action.payload;
    },

    addAdminSchoolSuccess: ({ schools, addAdminSchool }, action) => {
      const schoolIndex = schools.data.findIndex(
        (s) => s.id === action.data.id
      );
      schools.data[schoolIndex] = action.payload;
      addAdminSchool.loading = false;
      addAdminSchool.success = true;
    },
    addAdminSchoolFailure: ({ addAdminSchool }, action) => {
      addAdminSchool.loading = false;
      addAdminSchool.success = false;
      addAdminSchool.error = action.payload;
    },

    removeAdminSchoolSuccess: ({ schools, removeAdminSchool }, action) => {
      const removeAdminSchoolIndex = schools.data.findIndex(
        (s) => s.id === action.payload.id
      );
      schools.data[removeAdminSchoolIndex] = action.payload;
      removeAdminSchool.loading = false;
      removeAdminSchool.success = true;
    },
    removeAdminSchoolFailure: ({ removeAdminSchool }, action) => {
      removeAdminSchool.loading = false;
      removeAdminSchool.success = false;
      removeAdminSchool.error = action.payload;
    },

    addInspectorSchoolSuccess: ({ schools, addInspectorSchool }, action) => {
      const index = schools.data.findIndex((s) => s.id === action.payload.id);
      schools.data[index] = action.payload;
      addInspectorSchool.loading = false;
      addInspectorSchool.success = true;
    },
    addInspectorSchoolFailure: ({ addInspectorSchool }, action) => {
      addInspectorSchool.loading = false;
      addInspectorSchool.success = false;
      addInspectorSchool.error = action.payload;
    },

    removeInspectorSchoolSuccess: (
      { schools, removeInspectorSchool },
      action
    ) => {
      const foundedIndex = schools.data.findIndex(
        (s) => s.id === action.payload.id
      );
      schools.data[foundedIndex] = action.payload;
      removeInspectorSchool.loading = false;
      removeInspectorSchool.success = true;
    },
    removeInspectorSchoolFailure: ({ removeInspectorSchool }, action) => {
      removeInspectorSchool.loading = false;
      removeInspectorSchool.success = false;
      removeInspectorSchool.error = action.payload;
    },

    getInspectionResultSuccess: ({ getInspectionResult }, action) => {
      getInspectionResult.loading = false;
      getInspectionResult.success = true;
      getInspectionResult.data = action.payload;
    },
    getInspectionResultFailure: ({ getInspectionResult }, action) => {
      getInspectionResult.loading = false;
      getInspectionResult.success = false;
      getInspectionResult.error = action.payload;
    },

    getCleanerInspectionSuccess: ({ getCleanerInspection }, action) => {
      getCleanerInspection.loading = false;
      getCleanerInspection.success = true;
      getCleanerInspection.data = action.payload;
    },
    getCleanerInspectionFailure: ({ getCleanerInspection }, action) => {
      getCleanerInspection.loading = false;
      getCleanerInspection.success = false;
      getCleanerInspection.error = action.payload;
    },

    getCleaningAreasSuccess: ({ getCleaningAreas }, action) => {
      getCleaningAreas.loading = false;
      getCleaningAreas.success = true;
      getCleaningAreas.data = action.payload;
    },
    getCleaningAreasFailure: ({ getCleaningAreas }, action) => {
      getCleaningAreas.loading = false;
      getCleaningAreas.success = false;
      getCleaningAreas.error = action.payload;
    },

    getSdsSuccess: ({ sds }, action) => {
      sds.loading = false;
      sds.success = true;
      sds.data = action.payload;
    },
    getSdsFailure: ({ sds }, action) => {
      sds.loading = false;
      sds.success = false;
      sds.error = action.payload;
    },

    addSdsSuccess: ({ sds, addSds }, action) => {
      addSds.loading = false;
      addSds.success = true;
      sds.data.push(action.payload);
    },
    addSdsFailure: ({ addSds }, action) => {
      addSds.loading = false;
      addSds.success = false;
      addSds.error = action.payload;
    },

    deleteSdsSuccess: ({ sds, deleteSds }, action) => {
      deleteSds.loading = false;
      deleteSds.success = true;
      sds.data.filter((s) => s.id !== action.payload);
    },
    deleteSdsFailure: ({ deleteSds }, action) => {
      deleteSds.loading = false;
      deleteSds.success = false;
      deleteSds.error = action.payload;
    },

    getSchoolOrderSuccess: ({ map }, action) => {
      map.loading = false;
      map.success = true;
      map.data = action.payload;
    },
    getSchoolOrderFailure: ({ map }, action) => {
      map.loading = false;
      map.success = false;
      map.error = action.payload;
    },

    setSchoolOrderSuccess: ({ map }, action) => {
      map.loading = false;
      map.success = true;
      map.data = action.payload;
    },
    setSchoolOrderFailure: ({ map }, action) => {
      map.loading = false;
      map.success = false;
      map.error = action.payload;
    },

    addMapSuccess: ({ map, addMap }, action) => {
      addMap.success = true;
      addMap.loading = false;
      map.data = [action.payload,...map.data,] ;
    },
    editMapSuccess: ({ map, addMap }, action) => {
   
      addMap.success = true;
      addMap.loading = false;
      const fData=map.data.filter((item)=>item?.id!==action?.payload?.id)
      map.data =[action.payload,...fData] ;
    },
    addMapFailure: ({ addMap }, action) => {
      addMap.loading = false;
      addMap.success = false;
      addMap.error = action.payload;
    },
    removeMapSuccess: ({ map, removeMap }, action) => {
      removeMap.success = true;
      removeMap.loading = false;
      map.data = map.data.filter((item)=>item?.id!==action?.payload)
    },
    removeMapFailure: ({ removeMap }, action) => {
      removeMap.loading = false;
      removeMap.success = false;
      removeMap.error = action.payload;
    },

    getMapSuccess: ({ map }, action) => {
        map.loading = false;
        map.success = true;
        map.data = action.payload;
    },
    getMapFailure: ({ map }, action) => {
      map.loading = false;
      map.success = false;
      map.error = action.payload;
      toastMsg("Map could not be loaded",NOTIFICATION.error)
    },
    resetBlockSchoolSuccess: (state, action) => {
      //TODO:  update later
      state[action.payload.blockType] = {
        ...state[action.payload.blockType],
        ...initialState[action.payload.blockType],
      };
    },
    resetFlagsSchoolSuccess: (state, action) => {
      //  TODO: update later
      state[action.payload.blockType] = {
        ...state[action.payload.blockType],
        ...block,
      };
    },
    fetchBuildingSuccess: ({ buildings }, action) => {
      buildings.loading = false;
      buildings.success = true;
      buildings.data = action.payload;
    },
    fetchBuildingFailure: ({ buildings }, action) => {
      buildings.loading = false;
      buildings.success = false;
      buildings.error = action.payload;
    },
  },
});

export const {
  setSchoolsLoading,
  resetSchoolsBlock,
  resetBlock,
  setBlockSuccess,
  setBlockFailure,
  addCleanerFailure,
  startBlockFlags,
  addCleanerSuccess,
  editCleanerFailure,
  resetBlockFlags,
  setBlockLoadingFlag, 
  resetAddCleaner,
  editCleanerSuccess,
  resetBlockSchoolSuccess,
  addAdminSchoolFailure,
  addAdminSchoolSuccess,
  addInspectorSchoolFailure,
  addInspectorSchoolSuccess,
  addMapFailure,
  addMapSuccess,
  addSdsFailure,
  addSdsSuccess,
  createCleanupSuccess,
  createCleanupFailure,
  createCleanupRoomTypeFailure,
  createCleanupRoomTypeSuccess,
  createSchoolFailure,
  createSchoolSuccess,
  deleteSchoolFailure,
  deleteSchoolSuccess,
  deleteSdsFailure,
  deleteSdsSuccess,
  fetchReportFailure,
  fetchReportSuccess,
  fetchRoomSpecsFailure,
  fetchRoomSpecsSuccess,
  fetchSchoolFailure,
  fetchSchoolSuccess,
  fetchSchoolsCleaningDetailsFailure,
  fetchSchoolsCleaningDetailsSuccess,
  getCleanerInspectionFailure,
  getCleanerInspectionSuccess,
  getCleaningAreasFailure,
  getCleaningAreasSuccess,
  getInspectionResultFailure,
  getInspectionResultSuccess,
  getMapFailure,
  getMapSuccess,
  getSchoolFailure,
  getSchoolOrderFailure,
  getSchoolOrderSuccess,
  getSchoolSuccess,
  getSdsFailure,
  getSdsSuccess,
  removeAdminSchoolFailure,
  removeAdminSchoolSuccess,
  removeCleanerFailure,
  removeCleanerSuccess,
  removeInspectorSchoolFailure,
  removeInspectorSchoolSuccess,
  resetFlagsSchoolSuccess,
  setSchoolOrderFailure,
  setSchoolOrderSuccess,
  updateCleanupRoomTypeFailure,
  updateCleanupRoomTypeSuccess,
  updateSchoolFailure,
  updateSchoolSuccess,
  removeMapSuccess,
  removeMapFailure,
  updateSchool,
  deleteSchool,
  deleteSchoolStart,
  editMapSuccess,
  fetchBuildingFailure,
  fetchBuildingSuccess,
} = schoolSlice.actions;
export default schoolSlice.reducer;
