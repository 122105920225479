import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Axios } from "../../../api/axios";

import leftArrowIcon from "../../../components/Dashboard/assets/left-white-arrow.png";
import { getRandomRooms } from "../../../services/section";
import useWindowSize from "../../../utils/useWindowSize";
import ArrowLeft from "../assets/arrow-left";
import SelectCleanerModal from "./Inspector/SelectCleanerModal";
import { toast } from "react-toastify";
import InspectionDetails from "components/Dashboard/OverviewComponents/InspectionDetails";
import "./Styles.scss";
import {startInspection, stopInspection} from "features/inspection/inspectionSlice"

import BaseModal from "components/BaseModal";

export default function SelectedBuilding({
  isInspector,
  isSuperAdmin,
  isBuildingAdmin,
  isDistrictAdmin,
  image,
  name,
  grade = "NA",
  school = {},
  selectedTab,
  setSelectedTab,
  setSelectedSchool,
  openSelectedSection,
  setOpenSelectedSection,
}) {
  const dispatch = useDispatch();

  const {
    inspection: { isInspecting },
  } = useSelector(({ inspection }) => inspection);

  const [selectCleanerModal, setSelectCleanerModal] = useState(false);
  const [openFinishInspection, setOpenFinishInspection] = useState(false);
  const [openInspectionResults, setOpenInspectionResults] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [inspection, setInspection] = useState();

  const toggleSelectCleanerModal = () => setSelectCleanerModal((prev) => !prev);

  const handleStartInspection =  async () => {
      dispatch(startInspection());
  };

  const handleSendEmail = async () => {
    setSendEmail(true);
    const res = await Axios.get(`/api/v1/inspection/send-email/?school=${schoolId}`);
    if (res) {
      toast.success("Email send successfully");
    }
    setSendEmail(false);
  };

  const handleBackButton = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(stopInspection());
  };

  const { cleanersDetail, id: schoolId } = school;

  return (
    <Fragment>
    { /*
      <SelectCleanerModal
        open={selectCleanerModal}
        onClose={toggleSelectCleanerModal}
        cleaners={cleanersDetail}
        schoolId={schoolId}
        setStartInspection={setStartInspection}
      />
      <BaseModal
        onConfirm={() => setOpenFinishInspection(false)}
        onClose={() => setOpenFinishInspection(false)}
        show={openFinishInspection}
        title="Finish Inspection"
        confirmButtonText="Finish"
        content={
          <p className="mb-4">Are you sure you want to finish this inspection?</p>
        }
      />
      <BaseModal
        onConfirm={handleSendEmail}
        onClose={() => setOpenInspectionResults(false)}
        show={openInspectionResults}
        confirmButtonText={sendEmail ? "Sending..." : "Send Email"}
        content={<InspectionDetails inspection={inspection} />}
      />
      */}
      <div className="main-content-top-banner" data-sidebar-layout-height-item>
        <div className="building-image">
          <div style={{ height: "180px" }}>
            {image && (
              <img src={image} alt="" className="image-responsive w-100 h-100 image" />
            )}
          </div>
          <div className="building-caption p10">
            <div className="caption-holder p10">
              <div className="building-heading-holder d-flex flex-wrap justify-content-between" style={{marginTop: '-8px'}}>
                {isInspecting ? (
                  <div className="d-flex align-items-center mt-2">
                    <a className="me-3 d-flex align-items-center text-decoration-none" href="#" onClick={handleBackButton}>
                      <i className="fa-solid fa-arrow-left" style={{color: "#fff", fontSize: '24px'}}></i>
                    </a>
                    <span className="fs-4">Inspection</span>
                    <span className="mx-2">-</span>
                    <b className="fs-2">{name}</b>
                  </div>
                ) : (
                  <div className="mt-2">
                    <b className="fs-2 me-2">{name}</b>
                    <span className="room-section-text d-md-none">
                      {school.totalSections} Sections | {school.totalRooms} Rooms
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
