import { Axios } from "../../api/axios";

import { getHeader, toastMsg } from "../../utils/utility";
import { getSimplifiedError } from "../../utils/error";
 import {fetchWorkLoadSuccess,fetchWorkLoadFailure,setBlockLoading, assignCleanerProcessing} from "features/workload/workloadSlice";
import { NOTIFICATION } from "constants/constants";
import { fetchCleaners } from "services/cleaner";

export const fetchWorkLoad = (buildingId="") => async (dispatch) => {
  try {
    dispatch(setBlockLoading({ blockType: "workloads" }));
    let url = `/api/v1/room/cleaner/list/`;
    if(buildingId){
      url=`${url}?school_building=${buildingId}`
    }
    const response = await Axios.get(url, getHeader());
    dispatch(fetchWorkLoadSuccess(response));
  } catch (error) {
    const msg=getSimplifiedError(error)
    dispatch(fetchWorkLoadFailure(msg));
    toastMsg(msg.join("")||"Some Thing went wrong",NOTIFICATION.error)
  }
};


export const assignCleanersMultipleRoom = (cleaner,rooms,buildingId,toggle,cb,dId="") => async (dispatch) => {
  try {
    dispatch(assignCleanerProcessing(true));
    let url = `/api/v1/add/cleaner/multiple/rooms/`;
    await Axios.post(url,{cleaner,rooms:rooms.map((item)=>Number(item))}, getHeader());
    dispatch(fetchWorkLoad(buildingId))
    dispatch(((fetchCleaners(buildingId,dId))))
    toastMsg("Cleaner assigned to selected rooms successfully",NOTIFICATION.success)
    cb();
  } catch (error) {
    const msg=getSimplifiedError(error)
    toastMsg(msg.join("")||"Some Thing went wrong",NOTIFICATION.error)
  }
  finally{
    dispatch(assignCleanerProcessing(false));
    toggle()
  }
};
