import React from "react";

import { displayFormattedDate } from "../../../utils/utility";
import arrowRight from "../assets/arrow-right.svg";

export default function InspectionCard({
  inspectionsGroup,
  selectedGroup,
  handleClick,
}) {

  const {created, inspectedByName} = inspectionsGroup;
  return (
    <div
      className={`d-flex justify-content-between section-list-item ${selectedGroup &&
        created === selectedGroup.created ? "active-item" : ""
      }`}
      onClick={handleClick(inspectionsGroup)}
    >
      <div className="section-list-header d-flex justify-content-between flex-column">
        <h4><b>{displayFormattedDate(created)}</b></h4>
        <div className="inspection-name">by {inspectedByName}</div>
      </div>
    </div>
  );
}
