import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchPendingInvites } from "../services/admin";
import { resendUserInvite } from "../services/auth";

import "./InviteUserModal.scss";

import BaseModal from "components/BaseModal";

function PendingInviteModal({ showModal, handleClose }) {
  const dispatch = useDispatch();

  const { data } = useSelector(({ admin }) => admin.invites);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchPendingInvites());
  }, [showModal,dispatch]);

  const resendInvite = (payload) => {
    const data ={email:payload?.email,name:payload?.email,role:payload?.role?.value,districtId:payload?.district?.[0],schools:payload?.schools}
    dispatch(resendUserInvite(data,setLoading));
  };

  return (
    <BaseModal
      onConfirm={handleClose}
      onClose={handleClose}
      show={showModal}
      title="Pending Invites"
      size="lg"
      formLoading={isLoading}
      content={
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              data.map((row) => (
                <tr key={row.email}>
                  <td>{row.name}</td>
                  <td>{row.email}</td>
                  <td>{row.role.label}</td>
                  <td>
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={!isLoading ? () => resendInvite(row) : null}
                      disabled={isLoading}
                    >
                      {isLoading ? "Sending…" : "Resend"}
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <p>No Pending Invites</p>
              </tr>
            )}
          </tbody>
        </Table>
      }
    />
  );
}

export default PendingInviteModal;
