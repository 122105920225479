import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
const initialState = {
  isAuthenticated: false,
  data: null,
  loginError: null,
  loginSuccess: false,
  magicError: null,
  magicSuccess: false,
  forgotPasswordError: null,
  forgotPasswordSuccess: false,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.data = action.payload;
      state.loginSuccess = true;
      state.loginError = null;
    },
    loginFailure: (state, action) => {
      state.isAuthenticated = false;
      state.loginError = action.payload;
      state.data = null;
      state.loginSuccess = false;
    },
    loginMagicRequestSuccess: (state, action) => {
      state.data = action.payload;
      state.magicError = null;
      state.loginError = null;
      state.magicSuccess = true;
    },
    loginMagicRequestFailure: (state, action) => {
      state.magicError = action.payload;
      state.data = null;
      state.magicSuccess = false;
    },
    loginMagicSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.data = action.payload;
      state.loginSuccess = true;
      state.magicSuccess = true;
      state.magicError = null;
      state.loginError = null;
    },
    loginMagicFailure: (state, action) => {
      state.isAuthenticated = false;
      state.magicError = action.payload;
      // state.loginError = action.payload;
      state.data = null;
      state.magicSuccess = false;
      state.loginSuccess = false;
    },
    forgotPasswordSuccess: (state, action) => {
      state.forgotPasswordError = null;
      state.forgotPasswordSuccess = true;
    },
    forgotPasswordFailure: (state, action) => {
      state.forgotPasswordSuccess = false;
      state.forgotPasswordError = action.payload;
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.loginSuccess = false;
      state.magicSuccess = false;
      state.data = null;
      storage.removeItem("persist:root");
    },
    getUserProfileSuccess: (state, action) => {
      state.data.user = action.payload;
      state.loading = false;
      state.loginSuccess = true;
    },
    getUserProfileFailure: (state, action) => {
      state.loading = false;
      state.loginError = action.payload;
    },
    resetAuthStatus: (state) => {
      state.isAuthenticated = false;
      state.loginSuccess = false;
      state.loginError = null;
      state.magicSuccess = false;
      state.magicError = null;
      state.forgotPasswordSuccess = false;
      state.forgotPasswordError = null;
      state.data = null;
    },
  },
});

export const {
  loginSuccess,
  loginFailure,
  singupFailure,
  singupSuccess,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  getUserProfileSuccess,
  getUserProfileFailure,
  logoutSuccess,
  loginMagicFailure,
  loginMagicRequestFailure,
  loginMagicRequestSuccess,
  loginMagicSuccess,
  resetAuthStatus,
} = authSlice.actions;
export default authSlice.reducer;
