import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import InspectionCard from "./InspectionCard";

import { fetchRooms, getInspectionResult } from "services/school";

import InnerLoading from "components/InnerLoading";
import SidebarLayout from "components/SidebarLayout";
import { fetchInspections } from "services/inspection";
import {
  resetBlockFlags,
  selectInspectionsGroup,
  setFilters,
  setRooms,
  startInspection,
} from "features/inspection/inspectionSlice";
import { InspectionRoomsList } from "./Inspector/InspectionRoomsList";
import {
  createInspectionsGroup,
  getDateTime,
  getModelDate,
} from "utils/inspectionsGroup";
import { fetchSchoolCleaners } from "services/cleaner";
import { textIncludes } from "utils/utility";

function getRoomInspection(inspections, roomId) {
    return inspections.find(({ room }) => roomId === room);
  }
export default function Inspection({
  school,
  schoolId,
  isSuperAdmin,
  isInspector,
  isDistrictAdmin,
  onOpenDeleteInspection,
  groupInspections,
  setGroups,
  groups,
  todaysGroup,
}) {
  const dispatch = useDispatch();
  const sidebarLayoutRef = useRef();

  const user = useSelector(({ auth }) => auth.data?.user);
  const {
    rooms: {
      data: roomsData = [],
      success: roomsSuccess,
      loading: roomsLoading,
      error: roomsError,
    },
  } = useSelector(({ school }) => school);
  const {
    selectedInspectionsGroup: { data: selectedGroup },
    inspections: {
      data: inspectionsData,
      success: inspectionsSuccess,
      loading: inspectionsLoading,
      error,
    },
    filters: { cleaners: cleanersFilter, order: groupsOrder, search },
    inspectionsGroupRooms: { data: inspectionsGroupRooms},
    deleteInspection: {success: deleteInspectionSuccess},
    updateInspection: {success: updateInspectionSuccess},
    createInspection: {success: createInspectionSuccess},
  } = useSelector(({ inspection }) => inspection);
  const {
    schoolCleaners: {
      data: cleanersData,
      success: cleanerSuccess,
      loading: cleanerLoading,
    },
  } = useSelector(({ cleaner }) => cleaner);

  const [filteredRooms, setFilteredRooms] = useState([]);

  useEffect(() => {
    if (!inspectionsSuccess) {
      dispatch(fetchInspections({ schoolId }));
    }
    if (!roomsSuccess) {
      dispatch(fetchRooms({ schoolId }));
    }
    if (!cleanerSuccess) {
      dispatch(fetchSchoolCleaners({ schoolId }));
    }
  }, []);

  /*
  useEffect(() => {
    //If cleaners were added on the editor tab, then refresh the inspections. 
    if (!inspectionsLoading) {
      dispatch(fetchInspections({ schoolId }))
    } 
    if (!roomsLoading) {
      dispatch(fetchRooms({ schoolId }));
    }
  }, [addCleanerSuccess])
  */
  /*
useEffect(() => {

  if (randomRoomsSuccess && !inspectedRoom)  {
    const nextRoom = getNextRoom(randomRooms);
    dispatch(startInspection(nextRoom));
  } else if (randomRooms && inspectedRoom) {
    let targetRoom = randomRooms.find(({id}) => id === inspectedRoom.id);
    let targetSectionName = targetRoom ? targetRoom.sectionName : "";
    sortedRooms = sortedRooms.sort().sort((roomA, roomB) => {
      var roomASectionName = roomA.sectionName;
      //If we start by selecting a room, but their section first;
      if (inspectedRoom && (roomASectionName === targetSectionName)) {
        return -1;
      }
      return 0;
    })
  }

}, [roomsSuccess]);
  */

  /*
  useEffect(() => {
    setSelectedInspection({});
  }, [schoolId]);
  */
  function sortGroups(groups, order) {
    let sortFn;
    if (order === "recent") {
      sortFn = (a, b) => getModelDate(b) - getModelDate(a);
    } else {
      sortFn = (a, b) => getModelDate(a) - getModelDate(b);
    }
    let sorted = [...groups].sort(sortFn);
    return sorted;
  }

  //Construct the groups arrays and show and select the first group
  useEffect(() => {
    if (!inspectionsSuccess) {
      return;
    }
    let groups = inspectionsData.reduce((groups, inspection) => {
      var group = createInspectionsGroup(inspection);
      if (groups.find((g) => getModelDate(group) == getModelDate(g))) {
        return groups;
      }
      return [...groups, group];
    }, []);
    groups = sortGroups(groups, groupsOrder);
    setGroups(groups);
    if (groups.length && !selectedGroup) {
      dispatch(selectInspectionsGroup(groups[0]));
    }
    
  }, [inspectionsSuccess]);

  useEffect(() => {
    if (!inspectionsSuccess) {
      return;
    }
    let inspectionRooms = roomsData.map((room) => {
      let inspection = getRoomInspection(groupInspections, room.id);
      if (inspection) {
        //Use inspection cleaner id instead of room;
        return { ...room, cleaner: inspection.cleaner };
      }
      return room;
    });
    dispatch(setRooms(inspectionRooms));
  }, [inspectionsSuccess, 
      roomsSuccess, 
      selectedGroup,
      groupInspections,
      deleteInspectionSuccess,
      updateInspectionSuccess,
      createInspectionSuccess,
    ]);

  //Remove inspection group when there are no more inspections unless
  //is todays groups 
  useEffect(() => {
    if (deleteInspectionSuccess) {
      let newGroups = groups.filter(({created}) => {
        return selectedGroup.created !== created || selectedGroup.isTodays; 
      })
      setGroups(newGroups)
      dispatch(resetBlockFlags({blockType: 'deleteInspection'}));
    }
    
  }, [deleteInspectionSuccess])

  //Refresh inspections list filtered by cleaner
  const filterRef = useRef(cleanersFilter);
  useEffect(() => {
    if (filterRef.current === cleanersFilter) {
      return;
    }
    filterRef.current = cleanersFilter;
    Promise.resolve(
      dispatch(fetchInspections({ schoolId, cleanerId: cleanersFilter }))
    ).then(() => {
      dispatch(setFilters({ search: "", order: "recent" }));
    });
  }, [cleanersFilter]);


  useEffect(() => {
    if (!search.trim()) {
      return setFilteredRooms(inspectionsGroupRooms);
    }
    let cleaners = cleanersData.filter(({ name }) =>
      textIncludes(name, search)
    );
    let rooms = inspectionsGroupRooms.filter(({ id, name }) => {
      return (
        cleaners.some((cleaner) => cleaner.id === id) ||
        textIncludes(name, search)
      );
    });
    setFilteredRooms(rooms);
  }, [inspectionsGroupRooms, search]);

  const handleInspectionClick = (group) => () => {
    dispatch(selectInspectionsGroup(group))
    sidebarLayoutRef.current.toggleShowDetails();
  };

  const startNewInspectionGroup = () => {
    let name = user.firstName
      ? `${user.firstName} ${user.lastName}`.trim()
      : user.username;
    const group = createInspectionsGroup({
      inspectedByName: name,
      inspectedBy: user.id,
    });
    setGroups([group, ...groups]);
    dispatch(selectInspectionsGroup(group));
    sidebarLayoutRef.current.toggleShowDetails();
  };

  const handleStartInspectionClick = (room) => (e) => {
    dispatch(startInspection(room));
  };

  const handleSort = ({ target: { value } }) => {
    dispatch(setFilters({ order: value }));
    let sorted = sortGroups(groups, value);
    setGroups(sorted);
  };
  const handleCleanersFilter = ({ target: { value } }) => {
    dispatch(setFilters({ cleaners: value }));
  };
  const handleSearchInputChange = ({ target: { value } }) => {
    dispatch(setFilters({ search: value }));
  };
  function RoomListItemRightSideComponent({ room }) {
    const inspection = getRoomInspection(groupInspections, room.id);
    if (!inspection) {
      return (
        <b className="text-primary h4 mb-0">
          Inspect<i className="fa-solid fa-angle-right ms-2"></i>
        </b>
      );
    }
    let scoreText = `${inspection.score}/${inspection.totalItems}`;
    return (
      <div className="d-flex align-items-center">
        {inspection.inspectedBy === user.id && (
          <button
            className="ms-2 me-3 btn btn-outline-primary btn-small d-flex align-items-center shadow-none"
            onClick={onOpenDeleteInspection(inspection)}
            title="Delete this inspection"
          >
            <i className="fa-sharp fa-solid fa-trash"></i>
          </button>
        )}
        <div className="d-flex flex-column justify-content-center align-items-center me-3">
          <span className="d-block mb-0">Total Points</span>
          <b className="h4 mb-0">{scoreText}</b>
        </div>
        <i className="fa-solid fa-angle-right" style={{ fontSize: "32px" }}></i>
      </div>
    );
  }
   useEffect(() => {
    if (todaysGroup && Object.keys(todaysGroup).length > 0) {
      const containsObject = groups.filter(item => item.isTodays === true);
      if (containsObject.length === 0) {
        setGroups([todaysGroup, ...groups]);
      }
    }
  }, [todaysGroup, groups]);
  return (
    <>
      <SidebarLayout
        ref={sidebarLayoutRef}
        mainContentClassName="pt-4 pt-md-0"
        sidebarClassName="pt-3 pt-md-0"
        sidebar={
          <div className="section-list-holder inspection-sidbar position-relative">
            {(isDistrictAdmin || isInspector || isSuperAdmin) && (
              <div className="row mb-3">
                <div className="col-lg-6 col-12 mb-3 mb-lg-0">
                  <select
                    className="form-select"
                    name="order"
                    value={groupsOrder}
                    onChange={handleSort}
                    disabled={
                      inspectionsLoading || groups.length === 0
                    }
                  >
                    <option value="recent">Most Recent</option>
                    <option value="oldest">Oldest</option>
                  </select>
                </div>
                <div className="col-lg-6 col-12">
                  <select
                    className="form-select"
                    name="cleaner"
                    value={cleanersFilter}
                    onChange={handleCleanersFilter}
                    disabled={
                      inspectionsLoading ||
                      cleanerLoading ||
                      cleanersData.length === 0
                    }
                  >
                    {!cleanerLoading && cleanersData.length === 0 && (
                      <option value="">No cleaners to select</option>
                    )}
                    {cleanerLoading && (
                      <option value="">Loading Cleaners...</option>
                    )}
                    {!cleanerLoading && cleanersData.length > 0 && (
                      <option value="">All Cleaners</option>
                    )}
                    {cleanersData.map((cleaner) => {
                      return (
                        <option
                          key={`cleanersFilter${cleaner.id}`}
                          id={`cleanersFilter${cleaner.id}`}
                          value={cleaner.id}
                        >
                          {cleaner.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
            {!todaysGroup &&
              (isDistrictAdmin || isInspector || isSuperAdmin) &&
              !inspectionsLoading && (
                <div className="w-100 d-flex justify-content-center">
                  <button
                    className="btn btn-primary mb-4 mt-1"
                    onClick={startNewInspectionGroup}
                  >
                    New Inspection
                  </button>
                </div>
              )}
            <div className="position-relative">
              <InnerLoading show={inspectionsLoading} />
              {groups.length === 0 && !inspectionsLoading && (
                <p className="d-flex align-items-center justify-content-center p4">
                  No inspections to show
                </p>
              )}
              {groups.length > 0 &&
                groups.map((group) => (
                  <InspectionCard
                    key={`inspectionsGroup${getModelDate(group)}`}
                    inspectionsGroup={group}
                    selectedGroup={selectedGroup}
                    handleClick={handleInspectionClick}
                  />
                ))}
            </div>
          </div>
        }
        mainContent={
          <>
            <div className="w-100 position-relative mb-3">
              <input
                value={search}
                type="text"
                className="form-control mb-0 h-100 py-2 w-100"
                name="name"
                placeholder="Filter by cleaner or room"
                onChange={handleSearchInputChange}
              />
            </div>
            <InspectionRoomsList
              search={search}
              rooms={filteredRooms}
              loading={roomsLoading || inspectionsLoading}
              schoolId={schoolId}
              handleListItemClick={handleStartInspectionClick}
              ListItemRightSideComponent={RoomListItemRightSideComponent}
            />
          </>
        }
      />
    </>
  );
}
